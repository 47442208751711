import {
  PROJECT, ENDPOINTS, VULN, SCANS,
} from '@/config/';

const dummyData = [...Array(PROJECT.PERPAGE)].map((_, i) => ({
  id: i,
  name: '#########',
  status: '',
  team: '#########',
  summary: {
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
  },
  score: 0,
  trend: 0,
  trendPercent: 0,
  lastScan: '##########',
  labels: [],
  createdDate: '##########',
  languages: [],
  businessCriticality: 0,
  scannerType: 'sast',
}));

const projectVulnsDynamicViewDummyData = [...Array(PROJECT.PERPAGE)].map((_, i) => ({
  id: i,
  name: '1',
  count: 10,
  maxSeverity: 1,
  vulns: [],
  scannerType: 'sast',
  severity: 2,
  tool: {
    isCustom: false,
    manual: false,
  },
}));

const endpointDummyData = [...Array(ENDPOINTS.PERPAGE)].map((_, i) => ({
  id: i,
  name: '########',
  summary: {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    total: 0,
  },
  projects: [
    {
      key: 1,
      sast: 0,
      dast: 0,
      sca: 0,
    },
  ],
  children: [
    {
      name: 'sast',
      summary: {
        critical: 0,
        high: 0,
        low: 0,
        medium: 0,
        total: 0,
      },
    }, {
      name: 'dast',
      summary: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
        total: 0,
      },
    }, {
      name: 'sca',
      summary: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
        total: 0,
      },
    },
  ],
}));

const endpointsDetailDummyData = {
  endpoint: '############',
  label: '############',
  matchingRule: '######',
  table: [
    {
      name: 'sast',
      critical: 0,
      high: 0,
      medium: 0,
      low: 0,
      total: 0,
    }, {
      name: 'dast',
      critical: 0,
      high: 0,
      medium: 0,
      low: 0,
      total: 0,
    }, {
      name: 'sca',
      critical: 0,
      high: 0,
      medium: 0,
      low: 0,
      total: 0,
    },
  ],
  cweList: [...Array(5)].map((_, i) => ({
    cwe_id: i,
    vuln_count: i,
    dast: [
      {
        id: '#######',
        name: '##############',
        path: '#######',
        severity: 1,
        status: '#######',
        Project: {
          name: '#######',
        },
      },
    ],
    sast: [
      {
        id: '#######',
        name: '##############',
        path: '#######',
        severity: 1,
        status: '#######',
        Project: {
          name: '#######',
        },
      },
    ],
    sca: [
      {
        id: '#######',
        name: '##############',
        path: '#######',
        severity: 1,
        status: '#######',
        Project: {
          name: '#######',
        },
      },
    ],
  })),
};

const filesDummyData = [...Array(PROJECT.PERPAGE)].map((_, i) => ({
  key: i,
  fileName: '####################',
  fileType: '#########',
  fileSize: '##',
  uploadDate: 0,
  image: '###',
}));

const vulnDummyData = [...Array(VULN.PERPAGE)].map((_, i) => ({
  key: i,
  id: i.toString(),
  branch: '##',
  committer: '#####',
  cvss: 0,
  cweId: '##',
  cweName: '###########',
  environment: 'feature',
  epss: '',
  falsePositive: false,
  firstSeen: '#########',
  manual: false,
  metaData: '#########',
  name: '#########',
  overdue: {
    overdue: false,
    rule: 0,
  },
  issueAssigned: '#########',
  issueStatus: '#########',
  ipAddresses: [],
  path: '#########',
  scanner: 'checkmarx',
  scanParams: {},
  severity: 1,
  status: 'new',
  scannerType: 'sast',
  woe: '#########',
}));

const scanDummyData = [...Array(SCANS.PERPAGE)].map((_, i) => ({
  key: i,
  id: i,
  scanDate: '##########',
  scanner: 'checkmarx',
  projectName: '########',
  status: '######',
  recurrence: '######',
  critical: '##',
  high: '##',
  medium: '##',
  low: '##',
  info: '##',
  metaData: '###',
  branch: '###',
  scanParams: {
    pr: {
      ok: false,
    },
  },
  action: 0,
}));

const managementDummyData = [...Array(PROJECT.PERPAGE)].map((_, i) => ({
  id: i,
  name: '#########',
  products: [{
    id: i,
    name: '#########',
  }],
  scanner: [{
    name: 'scan',
    type: 'dast',
  }],
  team: '#########',
  tools: [{
    name: 'none',
    type: 'sast',
  }],
  labels: [{
    name: '#########',
  }],
  languages: [],
}));

const managementMetricsDummyData = {
  total_projects: 0,
  with_no_scanners: 0,
  with_no_issue_manager: 0,
  with_no_sla: 0,
  with_no_business_criticality: 0,
  alm_instances: [{
    tool: 'github',
  }],
  total_repos: 0,
  obsolete_repos: 0,
  onboarded_count: 0,
};

const asvsChaptersDummyData = [...Array(PROJECT.PERPAGE)].map((_, i) => ({
  id: `V${i + 1}`,
  name: '#########',
  valid: 0,
  applicable: 0,
}));

const asvsSectionDummyData = [...Array(5)].map((_, i) => ({
  id: `V${i + 1}`,
  name: '#########',
}));

export {
  dummyData,
  projectVulnsDynamicViewDummyData,
  endpointDummyData,
  endpointsDetailDummyData,
  filesDummyData,
  vulnDummyData,
  scanDummyData,
  managementDummyData,
  managementMetricsDummyData,
  asvsChaptersDummyData,
  asvsSectionDummyData,
};
