import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  get(params) {
    return Api().get(API_URL.USERS, { params });
  },
  getAllUsers(params) {
    return Api().get(`${API_URL.USERS}`, { params });
  },
  getRoles(params) {
    return Api().get(API_URL.ROLES, { params });
  },
  createCustomRole(data) {
    const params = data.query;
    return Api().post(API_URL.ROLES, params);
  },
  deleteCustomRole(params) {
    return Api().delete(`${API_URL.ROLES}/${params}`);
  },
  getUserRoleResources(params) {
    return Api().get(`${API_URL.ROLES}/${params}/resources`);
  },
  updateUserRoleResources(data) {
    const params = data.query;
    return Api().patch(`${API_URL.ROLES}/${data.id}/resources/${data.resourceId}`, params);
  },
  updateCustomRole(data) {
    const params = data.query;
    return Api().patch(`${API_URL.ROLES}/${data.id}`, params);
  },
  delegateCustomRole(data) {
    const params = data.query;
    return Api().patch(`${API_URL.ROLES}/${data.id}/delegate`, params);
  },
  getValidateRoleName(params) {
    return Api().get(`${API_URL.ROLES}/validate?name=${params.name}`);
  },
  getUserDetails(params) {
    return Api().get(`${API_URL.USERS}/${params}`);
  },
  validate(params) {
    return Api().post(API_URL.USERS_VALIDATE, params);
  },
  post(params) {
    return Api().post(API_URL.USERS, params);
  },
  update(params) {
    return Api().patch(`${API_URL.USERS}/${params.id}`, params);
  },
  updatePassword(params) {
    return Api().patch(`${API_URL.USER_PASSWORD}`, params);
  },
  remove(params) {
    return Api().delete(`${API_URL.USERS}/${params.id}`);
  },
  updateStatus(params) {
    return Api().patch(`${API_URL.USERS}/status`, params);
  },
  updateUserLoginType(data) {
    const params = data.query;
    return Api().patch(`${API_URL.USERS}/login_type/${data.type}/${data.id}`, params);
  },
  getUserTeams(data) {
    const params = data.query;
    return Api().get(`${API_URL.USERS}/${data.id}/teams`, { params });
  },
  getUserProjects(data) {
    const params = data.query;
    return Api().get(`${API_URL.USERS}/${data.id}/projects`, { params });
  },
  getUserProducts(data) {
    const params = data.query;
    return Api().get(`${API_URL.USERS}/${data.id}/products`, { params });
  },
  updateUserScope(data) {
    const params = data.query;
    return Api().patch(`${API_URL.USERS}/${data.id}/user_scope`, params);
  },
};
