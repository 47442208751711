import {
  PRODUCT, SCANS, VULN, ENDPOINTS,
} from '@/config/';

const dummyData = [...Array(PRODUCT.PERPAGE)].map((_, i) => ({
  id: i,
  name: '#########',
  owner: '#########',
  projects_count: 1,
  projects: [
    {
      id: i,
      name: '#########',
      createdAt: '#########',
      summary: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
      },
      owner: '#########',
    }, {
      id: i,
      name: '#########',
      createdAt: '#########',
      summary: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
      },
      owner: '#########',
    }, {
      id: i,
      name: '#########',
      createdAt: '#########',
      summary: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
      },
      owner: '#########',
    },
  ],
}));

const dummySummary = {
  overall_score: 0,
  product: {
    findings: {
      critical: 0,
      high: 0,
      low: 0,
      medium: 0,
    },
    risk_score: 0,
  },
  asvs: {
    count: 0,
    names: [],
  },
  sc: {
    total: 0,
    fail: 0,
  },
  ti_observed_in_the_wild_metric: {
    total: 0,
  },
  ti_known_exploit_metrics: {
    total: 0,
  },
};

const productVulnsDynamicViewDummyData = [...Array(PRODUCT.PERPAGE)].map((_, i) => ({
  id: i,
  name: '1',
  count: 10,
  maxSeverity: 1,
  vulns: [],
  scannerType: 'sast',
  severity: 2,
  tool: {
    isCustom: false,
    manual: false,
  },
}));

const scanDummyData = [...Array(SCANS.PERPAGE)].map((_, i) => ({
  key: i,
  id: i,
  scanDate: '##########',
  status: 'completed',
  scanner: 'checkmarx',
  project: '########',
  projectId: 0,
  recurrence: '######',
  critical: '##',
  high: '##',
  medium: '##',
  low: '##',
  info: '##',
  metaData: '###',
  branch: '###',
  scanParams: {
    pr: {
      ok: false,
    },
  },
  action: 0,
}));

const fileDummyData = [...Array(VULN.PERPAGE)].map((_, i) => ({
  key: i,
  fileName: '####################',
  fileType: '#########',
  fileSize: '##',
  uploadDate: 0,
}));

const endpointDummyData = [...Array(ENDPOINTS.PERPAGE)].map((_, i) => ({
  id: i,
  name: '########',
  summary: {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    total: 0,
  },
  projects: [
    {
      key: 1,
      sast: 0,
      dast: 0,
      sca: 0,
    },
  ],
  children: [
    {
      name: 'sast',
      summary: {
        critical: 0,
        high: 0,
        low: 0,
        medium: 0,
        total: 0,
      },
    }, {
      name: 'dast',
      summary: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
        total: 0,
      },
    }, {
      name: 'sca',
      summary: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
        total: 0,
      },
    },
  ],
}));

const endpointsDetailDummyData = {
  endpoint: '############',
  label: '############',
  matchingRule: '######',
  table: [
    {
      name: 'sast',
      critical: 0,
      high: 0,
      medium: 0,
      low: 0,
      total: 0,
    }, {
      name: 'dast',
      critical: 0,
      high: 0,
      medium: 0,
      low: 0,
      total: 0,
    }, {
      name: 'sca',
      critical: 0,
      high: 0,
      medium: 0,
      low: 0,
      total: 0,
    },
  ],
  cweList: [...Array(5)].map((_, i) => ({
    cwe_id: i,
    vuln_count: i,
    dast: [
      {
        id: '#######',
        name: '##############',
        path: '#######',
        severity: 1,
        status: '#######',
        Project: {
          name: '#######',
        },
      },
    ],
    sast: [
      {
        id: '#######',
        name: '##############',
        path: '#######',
        severity: 1,
        status: '#######',
        Project: {
          name: '#######',
        },
      },
    ],
    sca: [
      {
        id: '#######',
        name: '##############',
        path: '#######',
        severity: 1,
        status: '#######',
        Project: {
          name: '#######',
        },
      },
    ],
  })),
};

const vulnDummyData = [...Array(VULN.PERPAGE)].map((_, i) => ({
  key: i,
  id: i.toString(),
  branch: '##',
  committer: '#####',
  cvss: 0,
  cweId: '##',
  cweName: '##################',
  environment: 'feature',
  epss: '',
  falsePositive: '##################',
  firstSeen: '##',
  labels: [{
    id: '##################',
    name: '##################',
    color: '##################',
  }],
  manual: false,
  metaData: '####################',
  name: '####################',
  overdue: {
    overdue: false,
    rule: 0,
  },
  path: '##################',
  project: '#########',
  projectId: '#########',
  projects: [{
    project_id: '1',
    project_name: '#########',
  }],
  scannerType: 'sast',
  scanner: 'checkmarx',
  severity: 1,
  status: 'open',
  details: '##',
  woe: '##',
}));

const dummyProductUserData = [...Array(15)].map((_, i) => ({
  id: i,
  username: '#########',
  accessType: 'team',
  role: 'developer',
  action: i,
}));

export {
  dummyData,
  dummySummary,
  productVulnsDynamicViewDummyData,
  scanDummyData,
  fileDummyData,
  endpointDummyData,
  endpointsDetailDummyData,
  vulnDummyData,
  dummyProductUserData,
};
