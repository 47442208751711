import { defineStore } from 'pinia';

import ProjectDashboardService from '@/services/ProjectDashboard';

import ErrorHandling from '@/mixins/ErrorHandling';

import { COLOR } from '@/config/';

import dayjs from 'dayjs';

import i18n from '@/i18n';

import { dummyKeyMetrics, dummySummary, dummyArray } from './_dummyData';

const useProjectDashboardStore = defineStore({
  id: 'ProjectDashboard',
  state: () => ({
    // ## Project branch comparison. -----------------------------------------------------------------
    projectBranchComparison: {
      data: [],
      branches: [],
    },
    projectBranchComparisonPending: false,
    projectBranchComparisonDone: false,

    // ## Project bubble chart. ----------------------------------------------------------------------
    projectBubbleChart: [],
    projectBubbleChartPending: null,
    projectBubbleChartDone: null,

    // ## Project owasp top ten. ---------------------------------------------------------------------
    projectOwaspTopTen: [],
    projectOwaspTopTenPending: false,
    projectOwaspTopTenDone: false,

    // ## Project radar chart. ----------------------------------------------------------------------
    projectRadarChart: [],
    projectRadarChartPending: null,
    projectRadarChartDone: null,

    // ## Project key metrics. -----------------------------------------------------------------------
    projectKeyMetrics: dummyKeyMetrics,
    projectKeyMetricsPending: false,
    projectKeyMetricsDone: null,

    // ## Project summary. ---------------------------------------------------------------------------
    projectSummary: dummySummary,
    projectSummaryPending: null,
    projectSummaryDone: null,

    // ## Project summary Table. ---------------------------------------------------------------------
    projectSummaryTable: dummyArray,
    projectSummaryTablePending: false,
    projectSummaryTableDone: false,

    // ## Project suppressed vulns. ------------------------------------------------------------------
    projectSuppressedVulns: {
      data: [],
      types: [],
    },
    projectSuppressedVulnsPending: false,
    projectSuppressedVulnsDone: false,

    // ## Project tolls comparison. ------------------------------------------------------------------
    projectToolsComparison: {
      data: [],
      scanners: [],
    },
    projectToolsComparisonPending: false,
    projectToolsComparisonDone: false,

    // ## Project severity trend. --------------------------------------------------------------------
    projectSeverityTrend: {
      data: [],
      dates: [],
    },
    projectSeverityTrendPending: false,
    projectSeverityTrendDone: false,

    // ## Project risk score trend. ------------------------------------------------------------------
    projectRiskScoreTrend: {
      data: [],
      dates: [],
    },
    projectRiskScoreTrendPending: false,
    projectRiskScoreTrendDone: false,

    // ## Project security kpi trend. ----------------------------------------------------------------
    projectSecurityKPITrend: {
      data: [],
      dates: [],
    },
    projectSecurityKPITrendPending: false,
    projectSecurityKPITrendDone: false,

    // ## Project burndown trend chart. --------------------------------------------------------------
    projectBurndownTrend: {
      data: [],
      dates: [],
    },
    projectBurndownTrendPending: false,
    projectBurndownTrendDone: false,

    // ## Project issue trend. -----------------------------------------------------------------------
    projectIssueTrend: {
      data: [],
      dates: [],
    },
    projectIssueTrendPending: false,
    projectIssueTrendDone: false,

    // ## Project woe by severity. -------------------------------------------------------------------
    projectWoeBySeverity: {
      data: [],
      days: [],
    },
    projectWoeBySeverityPending: false,
    projectWoeBySeverityDone: false,

    // ## Project woe top ten. -----------------------------------------------------------------------
    projectWoeTopTen: [],
    projectWoeTopTenPending: false,
    projectWoeTopTenDone: false,

    // ## Project new closed trend. -----------------------------------------------------------------------
    projectNewClosedTrend: {
      data: [],
      weeks: [],
    },
    projectNewClosedTrendPending: false,
    projectNewClosedTrendDone: false,

    // ## Project environment breakdown chart. -----------------------------------------------------------------------
    projectEnvironmentBreakdown: {
      data: [],
      types: [],
    },
    projectEnvironmentBreakdownPending: false,
    projectEnvironmentBreakdownDone: false,
  }),
  actions: {
    // ## Get project branch comparison. -------------------------------------------------------------
    async getProjectBranchComparison(data) {
      this.projectBranchComparisonPending = true;

      try {
        const res = await ProjectDashboardService.getBranchComparison(data);
        this.setGetProjectBranchComparison(res.data.chart_data);
        this.projectBranchComparisonDone = !this.projectBranchComparisonDone;
        this.projectBranchComparisonPending = false;
      } catch (error) {
        this.projectBranchComparisonPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectBranchComparison(payload) {
      this.projectBranchComparison = {
        data: [],
        branches: [],
      };
      if (!payload || !payload.branches) {
        return;
      }
      const setColor = (type) => {
        if (type === 'low') {
          return COLOR.LOW;
        }
        if (type === 'medium') {
          return COLOR.MEDIUM;
        }
        if (type === 'high') {
          return COLOR.HIGH;
        }
        if (type === 'fp_count') {
          return COLOR.FP;
        }
        if (type === 'mitigated_count') {
          return COLOR.MITIGATED;
        }
        if (type === 'wf_count') {
          return COLOR.WONTFIX;
        }
        if (type === 'closed_count') {
          return COLOR.CLOSED;
        }
        return COLOR.CRITICAL;
      };
      const setName = (type) => {
        if (type === 'fp_count') {
          return 'falsePositive';
        }
        if (type === 'mitigated_count') {
          return 'mitigated';
        }
        if (type === 'wf_count') {
          return 'wontfix';
        }
        if (type === 'closed_count') {
          return 'closed';
        }
        return type;
      };
      this.projectBranchComparison.branches = payload.branches.map((a) => a.name);
      const keys = ['low', 'medium', 'high', 'critical',
        'fp_count', 'mitigated_count', 'wf_count', 'closed_count'];
      keys.forEach((s) => {
        if (payload.branches.filter((a) => a.summary[s]).length > 0) {
          this.projectBranchComparison.data.push({
            name: setName(s),
            value: payload.branches.map((a) => a.summary[s]),
            color: setColor(s),
          });
        }
      });
    },

    // ## Get project bubble chart. ------------------------------------------------------------------
    async getProjectBubbleChart(data) {
      this.projectBubbleChartPending = true;

      try {
        const res = await ProjectDashboardService.getBubbleChart(data);
        this.setGetProjectBubbleChart(res.data.chart_data);
        this.projectBubbleChartDone = !this.projectBubbleChartDone;
        this.projectBubbleChartPending = false;
      } catch (error) {
        this.projectBubbleChartPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectBubbleChart(payload) {
      this.projectBubbleChart = [];
      const type = payload.chart_type;
      let data = null;
      if (type === 'cwe') {
        data = payload.cwe_list;
      } else if (type === 'endpoint') {
        data = payload.endpoint_list;
      } else {
        data = payload.path_list;
      }
      if (!data) {
        return;
      }
      data.forEach((s, i) => {
        if (type === 'cwe') {
          this.projectBubbleChart.push({
            id: s.cwe.cwe_id,
            idTitle: 'CWE ID',
            type: 'cwe',
            name: (s.cwe.name !== 'unknown' ? s.cwe.name : 'Uncategorized'),
            value: s.count,
            countByTool: s.count_by_tool,
          });
        } else if (type === 'endpoint') {
          this.projectBubbleChart.push({
            id: i,
            type: 'endpoint',
            name: s.endpoint,
            value: s.count,
          });
        } else {
          this.projectBubbleChart.push({
            id: i,
            type: 'path',
            name: s.path,
            value: s.count,
          });
        }
      });
    },

    // ## Project owasp top ten. ---------------------------------------------------------------------
    async getProjectOwaspTopTen(data) {
      this.projectOwaspTopTenPending = true;

      try {
        const res = await ProjectDashboardService.getOwaspTopTen(data);
        this.setGetProjectOwaspTopTen(res.data.chart_data);
        this.projectOwaspTopTenDone = !this.projectOwaspTopTenDone;
        this.projectOwaspTopTenPending = false;
      } catch (error) {
        this.projectOwaspTopTenPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectOwaspTopTen(payload) {
      this.projectOwaspTopTen = [];
      if (payload.categories.filter((s) => s.count > 0).length < 1) {
        return;
      }
      payload.categories.forEach((s) => {
        this.projectOwaspTopTen.push({
          name: s.name,
          category: s.category,
          value: s.count,
          display: s.display_name,
        });
      });
    },

    // ## Get project radar chart. ------------------------------------------------------------------
    async getProjectRadarChart(data) {
      this.projectRadarChartPending = true;

      try {
        const res = await ProjectDashboardService.getRadarChart(data);
        this.setGetProjectRadarChart(res.data.chart_data);
        this.projectRadarChartDone = !this.projectRadarChartDone;
        this.projectRadarChartPending = false;
      } catch (error) {
        this.projectRadarChartPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectRadarChart(payload) {
      this.projectRadarChart = [];
      this.projectRadarChart = payload.data.map((s) => ({
        id: s.id,
        name: `${s.name}`,
        score: s.percentage,
        applicable: s.applicable,
        valid: s.valid,
      })).sort((a, b) => {
        const NumberA = Number(a.id.split('V')[1]);
        const NumberB = Number(b.id.split('V')[1]);
        if (NumberB < NumberA) return -1;
        if (NumberB > NumberA) return 1;
        return 0;
      });
    },

    // ## Get project key metrics. -------------------------------------------------------------------
    async getProjectKeyMetrics(data) {
      this.projectKeyMetricsPending = true;

      try {
        const res = await ProjectDashboardService.getKeyMetrics(data);
        this.setGetProjectKeyMetrics(res.data.chart_data);
        this.projectKeyMetricsDone = !this.projectKeyMetricsDone;
        this.projectKeyMetricsPending = false;
      } catch (error) {
        this.projectKeyMetricsPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectKeyMetrics(payload) {
      const data = [];
      Object.keys(payload).forEach((s) => {
        if (s !== 'tool_type') {
          data.push({
            name: (s === 'vwi' ? 'issues' : s),
            critical: payload[s].findings.critical,
            high: payload[s].findings.high,
            low: payload[s].findings.low,
            medium: payload[s].findings.medium,
            total: (s === 'vwi' || s === 'sla' ? payload[s].total : payload[s].avg),
          });
        }
      });
      const sortedData = [];
      data.forEach((s) => {
        if (s.name === 'issues') sortedData[0] = s;
        if (s.name === 'sla') sortedData[1] = s;
        if (s.name === 'woe') sortedData[2] = s;
        if (s.name === 'mtf') sortedData[3] = s;
      });
      this.projectKeyMetrics = sortedData;
    },

    // ## Get project summary. -----------------------------------------------------------------------
    async getProjectSummary(data) {
      this.projectSummaryPending = true;

      try {
        const res = await ProjectDashboardService.getSummary(data);
        this.projectSummary = res.data.chart_data;
        this.projectSummaryDone = !this.projectSummaryDone;
        this.projectSummaryPending = false;
      } catch (error) {
        this.projectSummaryPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get project summary table. -----------------------------------------------------------------
    async getProjectSummaryTable(data) {
      this.projectSummaryTablePending = true;

      try {
        const res = await ProjectDashboardService.getSummaryTable(data);
        this.setGetProjectSummaryTable(res.data.chart_data);
        this.projectSummaryTableDone = !this.projectSummaryTableDone;
        this.projectSummaryTablePending = false;
      } catch (error) {
        this.projectSummaryTablePending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectSummaryTable(payload) {
      const data = [];
      Object.keys(payload.rows).forEach((s) => {
        data.push({
          name: s === 'ra' ? 'riskAccepted' : s,
          critical: payload.rows[s].findings.critical,
          high: payload.rows[s].findings.high,
          low: payload.rows[s].findings.low,
          medium: payload.rows[s].findings.medium,
          total: payload.rows[s].findings.critical +
            payload.rows[s].findings.high +
            payload.rows[s].findings.low +
            payload.rows[s].findings.medium,
        });
      });
      this.projectSummaryTable = data;
    },

    // ## Get project suppressed vulns. --------------------------------------------------------------
    async getProjectSuppressedVulns(data) {
      this.projectSuppressedVulnsPending = true;

      try {
        const res = await ProjectDashboardService.getProjectSuppressedVulns(data);
        this.setGetProjectSuppressedVulns(res.data.chart_data);
        this.projectSuppressedVulnsDone = !this.projectSuppressedVulnsDone;
        this.projectSuppressedVulnsPending = false;
      } catch (error) {
        this.projectSuppressedVulnsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Set get project suppressed vulns. ----------------------------------------------------------
    setGetProjectSuppressedVulns(payload) {
      this.projectSuppressedVulns = {
        data: [],
        types: [],
      };
      if (payload.bars.filter((s) => s.total > 0).length < 1) {
        return;
      }
      const setColor = (type) => {
        if (type === 'low') {
          return COLOR.LOW;
        }
        if (type === 'medium') {
          return COLOR.MEDIUM;
        }
        if (type === 'high') {
          return COLOR.HIGH;
        }
        return COLOR.CRITICAL;
      };
      const setTypes = (type) => {
        if (type === 'fp') {
          return 'falsePositive';
        }
        if (type === 'wf') {
          return 'wontfix';
        }
        return type;
      };
      this.projectSuppressedVulns.types = payload.bars.map((a) => setTypes(a.stack_name));
      const keys = ['low', 'medium', 'high', 'critical'];
      keys.forEach((s) => {
        this.projectSuppressedVulns.data.push({
          name: s,
          value: payload.bars.map((a) => a.findings[s]),
          color: setColor(s),
        });
      });
    },

    // ## Get project tool comparison. ---------------------------------------------------------------
    async getProjectToolComparison(data) {
      this.projectToolsComparisonPending = true;

      try {
        const res = await ProjectDashboardService.getToolComparison(data);
        this.setGetProjectToolComparison(res.data.chart_data);
        this.projectToolsComparisonDone = !this.projectToolsComparisonDone;
        this.projectToolsComparisonPending = false;
      } catch (error) {
        this.projectToolsComparisonPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectToolComparison(payload) {
      this.projectToolsComparison = {
        data: [],
        scanners: [],
      };
      if (!payload || !payload.scanners) {
        return;
      }
      const setColor = (type) => {
        if (type === 'low') {
          return COLOR.LOW;
        }
        if (type === 'medium') {
          return COLOR.MEDIUM;
        }
        if (type === 'high') {
          return COLOR.HIGH;
        }
        if (type === 'fp_count') {
          return COLOR.FP;
        }
        if (type === 'mitigated_count') {
          return COLOR.MITIGATED;
        }
        if (type === 'wf_count') {
          return COLOR.WONTFIX;
        }
        if (type === 'closed_count') {
          return COLOR.CLOSED;
        }
        return COLOR.CRITICAL;
      };
      const setName = (type) => {
        if (type === 'fp_count') {
          return 'falsePositive';
        }
        if (type === 'mitigated_count') {
          return 'mitigated';
        }
        if (type === 'wf_count') {
          return 'wontfix';
        }
        if (type === 'closed_count') {
          return 'closed';
        }
        return type;
      };
      this.projectToolsComparison.scanners = payload.scanners.map((a) => a.name);
      const keys = ['low', 'medium', 'high', 'critical',
        'fp_count', 'mitigated_count', 'wf_count', 'closed_count'];
      keys.forEach((s) => {
        if (payload.scanners.filter((a) => a.summary[s]).length > 0) {
          this.projectToolsComparison.data.push({
            name: setName(s),
            value: payload.scanners.map((a) => a.summary[s]),
            color: setColor(s),
          });
        }
      });
    },

    // ## Project severity trend request. ------------------------------------------------------------
    async getProjectSeverityTrend(data) {
      this.projectSeverityTrendPending = true;

      try {
        const res = await ProjectDashboardService.getSevertiyTrend(data);
        this.setGetProjectSeverityTrend(res.data.chart_data);
        this.projectSeverityTrendDone = !this.projectSeverityTrendDone;
        this.projectSeverityTrendPending = false;
      } catch (error) {
        this.projectSeverityTrendPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectSeverityTrend(payload) {
      this.projectSeverityTrend = {
        data: [],
        dates: [],
      };
      if (payload.scan_trend.filter((s) => s.score > 0).length < 1) {
        return;
      }
      const formatDate = (date) => (
        dayjs(date).format('DD MMM YYYY')
      );
      const setValue = (a, s) => {
        if (s === 'low') {
          return a.summary.low;
        }
        if (s === 'medium') {
          return a.summary.medium;
        }
        if (s === 'high') {
          return a.summary.high;
        }
        return a.summary.critical;
      };
      const setColor = (type) => {
        if (type === 'low') {
          return COLOR.LOW;
        }
        if (type === 'medium') {
          return COLOR.MEDIUM;
        }
        if (type === 'high') {
          return COLOR.HIGH;
        }
        return COLOR.CRITICAL;
      };
      this.projectSeverityTrend.dates = payload.scan_trend.map((a) => formatDate(a.scan_date));
      const keys = ['critical', 'high', 'medium', 'low'];
      keys.forEach((s) => {
        this.projectSeverityTrend.data.push({
          name: s,
          value: payload.scan_trend.map((a) => setValue(a, s)),
          color: setColor(s),
        });
      });
    },

    // ## Project risk score trend request. ----------------------------------------------------------
    async getProjectRiskScoreTrend(data) {
      this.projectRiskScoreTrendPending = true;

      try {
        const res = await ProjectDashboardService.getRiskScoreTrend(data);
        this.setGetProjectRiskScoreTrend(res.data.chart_data);
        this.projectRiskScoreTrendDone = !this.projectRiskScoreTrendDone;
        this.projectRiskScoreTrendPending = false;
      } catch (error) {
        this.projectRiskScoreTrendPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectRiskScoreTrend(payload) {
      this.projectRiskScoreTrend = {
        data: [],
        dates: [],
      };
      if (payload.scan_trend.filter((s) => s.score > 0).length < 1) {
        return;
      }
      const formatDate = (date) => (
        dayjs(date).format('DD MMM YYYY')
      );
      this.projectRiskScoreTrend.dates = payload.scan_trend.map((a) => formatDate(a.scan_date));
      const keys = ['avgRiskScore'];
      keys.forEach((s) => {
        this.projectRiskScoreTrend.data.push({
          name: s,
          value: payload.scan_trend.map((a) => a.score),
          color: COLOR.DANGER,
        });
      });
    },

    // ## Project security KPI trend request. --------------------------------------------------------
    async getProjectSecurityKPITrend(data) {
      this.projectSecurityKPITrendPending = true;

      try {
        const res = await ProjectDashboardService.getProjectSecurityKPITrend(data);
        this.setGetProjectSecurityKPITrend(res.data.chart_data);
        this.projectSecurityKPITrendDone = !this.projectSecurityKPITrendDone;
        this.projectSecurityKPITrendPending = false;
      } catch (error) {
        this.projectSecurityKPITrendPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectSecurityKPITrend(payload) {
      this.projectSecurityKPITrend = {
        data: [],
        dates: [],
      };
      if (
        payload.kpi_metric_trend.filter((s) => s.first_action > 0 ||
          s.first_response > 0 || s.go_live_delay > 0 ||
          s.remediation > 0 || s.resolution > 0).length < 1
      ) {
        return;
      }
      const formatDate = (date) => (
        dayjs(date).format('DD MMM YYYY')
      );
      const setType = (type) => {
        if (type === 'first_response') {
          return 'Time to first response (d)';
        }
        if (type === 'first_action') {
          return 'Time to first action (d)';
        }
        if (type === 'go_live_delay') {
          return 'Go-live delay (d)';
        }
        if (type === 'remediation') {
          return 'Time to remediate (d)';
        }
        if (type === 'date') {
          return 'date';
        }
        return 'Time to resolution (d)';
      };
      const setColor = (type) => {
        if (type === 'first_response') {
          return '#1790ff';
        }
        if (type === 'first_action') {
          return '#e05633';
        }
        if (type === 'go_live_delay') {
          return '#f8b82e';
        }
        if (type === 'remediation') {
          return '#418b4b';
        }
        if (type === 'date') {
          return 'date';
        }
        return '#f8e23b';
      };
      this.projectSecurityKPITrend.dates = payload.kpi_metric_trend.map((s) => formatDate(s.date));
      const keys = [];
      Object.entries(payload.kpi_metric_trend[0]).forEach((s) => {
        if (s[0] !== 'date') {
          keys.push(s[0]);
        }
      });
      keys.forEach((s) => {
        this.projectSecurityKPITrend.data.push({
          name: setType(s),
          value: payload.kpi_metric_trend.map((a) => Math.ceil(a[s])),
          color: setColor(s),
        });
      });
    },

    // ## Get project burndown trend chart. ----------------------------------------------------------
    async getProjectBurndownTrend(data) {
      this.projectBurndownTrendPending = false;

      try {
        const res = await ProjectDashboardService.getProjectBurndownTrend(data);
        this.setGetProjectBurndownTrend(res.data.chart_data);
        this.projectBurndownTrendDone = !this.projectBurndownTrendDone;
        this.projectBurndownTrendPending = false;
      } catch (error) {
        this.projectBurndownTrendPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectBurndownTrend(payload) {
      this.projectBurndownTrend = {
        data: [],
        dates: [],
      };
      if (
        payload.vulnerability_trend
          .filter((s) => s.total_count > 0 || s.total_ra_count || s.total_closed_count).length < 1
      ) {
        return;
      }
      const formatDate = (date) => (
        dayjs(date).format('DD MMM YYYY')
      );
      this.projectBurndownTrend.dates =
        payload.vulnerability_trend.map((a) => formatDate(a.scan_date));
      const setValue = (type) => {
        if (type === 'riskAccepted') {
          return payload.vulnerability_trend.map((a) => (type === 'riskAccepted' && a.total_ra_count));
        }
        if (type === 'closed') {
          return payload.vulnerability_trend.map((a) => (type === 'closed' && a.total_closed_count));
        }
        if (type === 'open') {
          return payload.vulnerability_trend.map((a) => (type === 'open' && a.total_count));
        }
      };
      const setColor = (type) => {
        if (type === 'riskAccepted') {
          return COLOR.LOW;
        }
        if (type === 'closed') {
          return COLOR.GREEN;
        }
        if (type === 'open') {
          return COLOR.CRITICAL;
        }
      };
      ['riskAccepted', 'open', 'closed'].forEach((s) => {
        this.projectBurndownTrend.data.push({
          name: s,
          color: setColor(s),
          value: setValue(s),
        });
      });
    },

    // ## Get Project Issue trend request. -----------------------------------------------------------
    async getProjectIssueTrend(data) {
      this.projectIssueTrendPending = true;

      try {
        const res = await ProjectDashboardService.getProjectIssueTrend(data);
        this.setGetProjectIssueTrend(res.data.chart_data);
        this.projectIssueTrendDone = !this.projectIssueTrendDone;
        this.projectIssueTrendPending = false;
      } catch (error) {
        this.projectIssueTrendPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectIssueTrend(payload) {
      this.projectIssueTrend = {
        data: [],
        dates: [],
      };
      if (payload.filter((s) => s.closed_count > 0 || s.in_progress_count > 0 || s.opened_count > 0).length < 1) {
        return;
      }
      const formatDate = (date) => (
        dayjs(date).format('DD MMM YYYY')
      );
      const setValue = (a, s) => {
        if (s === 'open') {
          return a.opened_count;
        }
        if (s === 'closed') {
          return a.closed_count;
        }
        return a.in_progress_count;
      };
      const setColor = (type) => {
        if (type === 'open') {
          return COLOR.DANGER;
        }
        if (type === 'closed') {
          return COLOR.GREEN;
        }
        return COLOR.MEDIUM;
      };
      this.projectIssueTrend.dates = payload.map((a) => formatDate(a.date));
      const keys = ['closed', 'inprogress', 'open'];
      keys.forEach((s) => {
        this.projectIssueTrend.data.push({
          name: s,
          value: payload.map((a) => setValue(a, s)),
          color: setColor(s),
        });
      });
    },

    // ## Project woe by severity. -------------------------------------------------------------------
    async getProjectWoeBySeverity(data) {
      this.projectWoeBySeverityPending = true;

      try {
        const res = await ProjectDashboardService.getProjectWoeBySeverity(data);
        this.setGetProjectWoeBySeverity(res.data.chart_data);
        this.projectWoeBySeverityDone = !this.projectWoeBySeverityDone;
        this.projectWoeBySeverityPending = false;
      } catch (error) {
        this.projectWoeBySeverityPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectWoeBySeverity(payload) {
      this.projectWoeBySeverity = {
        data: [],
        days: [],
      };
      if (payload.bars.filter((s) => s.count > 0).length < 1) {
        return;
      }
      const setColor = (type) => {
        if (type === 'low') {
          return COLOR.LOW;
        }
        if (type === 'medium') {
          return COLOR.MEDIUM;
        }
        if (type === 'high') {
          return COLOR.HIGH;
        }
        return COLOR.CRITICAL;
      };
      this.projectWoeBySeverity.days = payload.bars.map((a) => `${a.stack_name} Days`);
      const keys = ['low', 'medium', 'high', 'critical'];
      keys.forEach((s) => {
        this.projectWoeBySeverity.data.push({
          name: s,
          value: payload.bars.map((a) => a.findings[s]),
          color: setColor(s),
        });
      });
    },

    // ## Project woe by severity. -------------------------------------------------------------------
    async getProjectWoeTopTen(data) {
      this.projectWoeTopTenPending = true;

      try {
        const res = await ProjectDashboardService.getProjectWoeTopTen(data);
        this.setGetProjectWoeTopTen(res.data.chart_data);
        this.projectWoeTopTenDone = !this.projectWoeTopTenDone;
        this.projectWoeTopTenPending = false;
      } catch (error) {
        this.projectWoeTopTenPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectWoeTopTen(payload) {
      this.projectWoeTopTen = [];
      payload.Vulns.forEach((s) => {
        this.projectWoeTopTen.push({
          // eslint-disable-next-line no-underscore-dangle
          id: s._id,
          name: s.name,
          severity: s.severity,
          woe: s.woe,
        });
      });
      this.projectWoeTopTen.splice(9, 10);
    },

    // ## Get Project new closed trend. --------------------------------------------------------------------------------
    async getProjectNewClosedTrend(data) {
      this.projectNewClosedTrendPending = true;

      try {
        const res = await ProjectDashboardService.getProjectNewClosedTrend(data);
        this.setGetProjectNewClosedTrend(res.data.chart_data.weeks);
        this.projectNewClosedTrendDone = !this.projectNewClosedTrendDone;
        this.projectNewClosedTrendPending = false;
      } catch (error) {
        this.projectNewClosedTrendPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectNewClosedTrend(payload) {
      this.projectNewClosedTrend = {
        data: [],
        weeks: [],
      };
      if (!payload || payload.filter((s) => s.total_open > 0 || s.total_closed > 0).length < 1) {
        return;
      }
      const setValue = (a, s) => {
        if (s === 'total_open') {
          return a.total_open;
        }
        return a.total_closed;
      };
      const setColor = (type) => {
        if (type === 'total_open') {
          return COLOR.DANGER;
        }
        return COLOR.GREEN;
      };
      this.projectNewClosedTrend.weeks = payload.map((a) => `Week ${a.week} (${dayjs(a.start_date).format('DD MMM')})`);
      const keys = ['total_open', 'total_closed'];
      keys.forEach((s) => {
        this.projectNewClosedTrend.data.push({
          name: s === 'total_open' ? 'totalNew' : 'totalClosed',
          value: payload.map((a) => setValue(a, s)),
          color: setColor(s),
        });
      });
    },

    // ## Get Project new closed trend. --------------------------------------------------------------------------------
    async getProjectEnvironmentBreakdown(data) {
      this.projectEnvironmentBreakdownPending = true;

      try {
        const res = await ProjectDashboardService.getProjectEnvironmentBreakdown(data);
        this.setGetProjectEnvironmentBreakdown(res.data.chart_data);
        this.projectEnvironmentBreakdownDone = !this.projectEnvironmentBreakdownDone;
        this.projectEnvironmentBreakdownPending = false;
      } catch (error) {
        this.projectEnvironmentBreakdownPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectEnvironmentBreakdown(payload) {
      this.projectEnvironmentBreakdown = {
        data: [],
        types: [],
      };
      if (payload.bars.filter((s) => s.count > 0).length < 1) {
        return;
      }
      const setColor = (type) => {
        if (type === 'low') {
          return COLOR.LOW;
        }
        if (type === 'medium') {
          return COLOR.MEDIUM;
        }
        if (type === 'high') {
          return COLOR.HIGH;
        }
        return COLOR.CRITICAL;
      };
      this.projectEnvironmentBreakdown.types = payload.bars.map((a) => i18n.global.t(a.stack_name === 'development' ? 'featuredevelopment' : a.stack_name));
      const keys = ['low', 'medium', 'high', 'critical'];
      keys.forEach((s) => {
        this.projectEnvironmentBreakdown.data.push({
          name: s,
          value: payload.bars.map((a) => a.findings[s]),
          color: setColor(s),
        });
      });
    },
  },
});

export default useProjectDashboardStore;
