// ## Config Inc.
import { VULN } from '@/config/';

import dayjs from 'dayjs';

const dummyData = [...Array(VULN.PERPAGE)].map((_, i) => ({
  key: i,
  id: i.toString(),
  branch: '##',
  committer: '#####',
  cvss: 0,
  cweId: '##',
  cweName: '##################',
  environment: 'feature',
  epss: '',
  falsePositive: '##################',
  firstSeen: '##',
  labels: [{
    id: '##################',
    name: '##################',
    color: '##################',
  }],
  manual: false,
  metaData: '####################',
  overdue: {
    overdue: false,
    rule: 0,
  },
  infraGroupNames: ['#######', '#######'],
  name: '####################',
  path: '##################',
  project: '#########',
  projectId: '#########',
  projects: [{
    project_id: '1',
    project_name: '#########',
  }],
  scannerType: 'sast',
  scanner: 'checkmarx',
  severity: 1,
  status: 'open',
  details: '##',
  woe: '##',
}));

const vulnScansDummy = {
  scan_date: dayjs(),
  summary: {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
  },
  scanparams: {
    branch: '####',
    project: {
      id: '####',
      name: '####',
      actions: {
        issue: {
          tool: null,
        },
        repo: {
          tool: null,
        },
        notification: {
          tool: null,
        },
      },
    },
    tool: {
      id: '###',
      name: 'gosec',
    },
  },
};

const allVulnsDynamicViewDummyData = [...Array(VULN.PERPAGE)].map((_, i) => ({
  id: i,
  name: '1',
  count: 10,
  maxSeverity: 1,
  vulns: [],
  scannerType: 'sast',
  severity: 2,
  tool: {
    isCustom: false,
    manual: false,
  },
}));

const vulnDetailsDummy = {
  name: '###########',
  severity: 1,
  tool: {
    name: 'gosec',
    type: 'sast',
  },
  project: {
    id: '####',
    actions: {
      issue: {},
    },
    name: '####',
  },
  fp: {
    status: '',
  },
  standarts: {
    cwe: {
      cwe_id: 0,
    },
  },
};

const vulnCollaborationDummy = [...Array(4)].map((_, i) => ({
  id: i,
  assignee: '###################',
  firstSeen: '27 Sep 2023',
  lastSeen: '11 Oct 2023',
}));

export {
  dummyData,
  vulnScansDummy,
  allVulnsDynamicViewDummyData,
  vulnDetailsDummy,
  vulnCollaborationDummy,
};
