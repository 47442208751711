import { defineStore } from 'pinia';

import ProjectService from '@/services/Project';

import ErrorHandling from '@/mixins/ErrorHandling';

import { PROJECT, SCANS, SBOM } from '@/config/';

import dayjs from 'dayjs';

import {
  dummyData,
  scanDummyData,
  vulnDummyData,
  projectVulnsDynamicViewDummyData,
  filesDummyData,
  asvsChaptersDummyData,
  asvsSectionDummyData,
  endpointDummyData,
  endpointsDetailDummyData,
  managementDummyData,
  managementMetricsDummyData,
} from './_dummyData';

import { sbomDummy, sbomDetailDummy } from '../settings/_sbomDummy';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const setMetaDataQuery = (option) => {
  if (option && option.indexOf('nbl') > -1) {
    return 'ne:';
  }
  if (option && option.indexOf('bl') > -1) {
    return 'eq:';
  }
  return option;
};

const vulnParams = (data) => {
  const params = {
    id: data.id,
    type: data.type,
    query: {
      limit: data.options.perPage,
      start: (data.options.page - 1) * data.options.perPage,
      sort_by: data.options.sort,
      group1: data.options.group1,
      group2: data.options.group2,
      branch: data.options.branch,
      committer: data.options.committer,
      cluster: data.options.cluster,
      cloud_platform: data.options.platform,
      cvss: data.options.cvss,
      cvss_vector: data.options.cvssVector,
      cwe_name: data.options.cweName,
      cwe_no: data.options.cweId,
      cve: data.options.cveId,
      dns_name: data.options.dnsName,
      docker_image: data.options.dockerImage,
      display_fields: data.options.displayFields,
      due_date: data.options.dueDate,
      engagement: data.options.engagement,
      environment: data.options.environment,
      epss_percentile: data.options.epssPercentile,
      epss_percentage: data.options.epssPercentage,
      endpoint_id: data.options.endpointId,
      family: data.options.family,
      first_seen: data.options.firstSeenFrom,
      fp: data.options.falsePositive ? `${data.options.falsePositive.split(':')[0]}:${data.options.falsePositive.split(':')[1] === 'yes'}` : null,
      fp_mark_date: data.options.fpMarkDate,
      fixable: data.options.fixable ? `${data.options.fixable.split(':')[0]}:${data.options.fixable.split(':')[1] === 'yes'}` : null,
      fixed_version: data.options.fixedVersion,
      fixed_version_exist: data.options.hasFix,
      from: data.options.lastStatusUpdate,
      fqdn: data.options.fqdn,
      has_comment: data.options.hasNote ? `${data.options.hasNote.split(':')[0]}:${data.options.hasNote.split(':')[1] === 'yes'}` : undefined,
      has_internet_exposure: data.options.hasInternetExposure,
      path: (data.options.filePath ? data.options.filePath : data.options.ip),
      highlighted_vulns: data.options.highlightedVulnerabilities ? `${data.options.highlightedVulnerabilities.split(':')[0]}:${data.options.highlightedVulnerabilities.split(':')[1] === 'yes'}` : null,
      historical_status: data.options.historicalStatus,
      id: data.options.id,
      image: data.options.image,
      infra_group_ids: data.options.infraGroupNames,
      issue_assignee: data.options.issueAssignee,
      issue_closed_date: data.options.issueClosedDate,
      issue_created_date: data.options.issueCreatedDate,
      issue_id: data.options.issueId,
      is_master: data.options.isMaster ? `${data.options.isMaster.split(':')[0]}:${data.options.isMaster.split(':')[1] === 'yes'}` : undefined,
      issue_url: data.options.issueUrl,
      issue_status: data.options.issueStatus,
      issue_wip_date: data.options.issueWIPDate,
      known_exploit: data.options.knownExploit ? `${data.options.knownExploit.split(':')[0]}:${data.options.knownExploit.split(':')[1] === 'yes'}` : null,
      last_seen: data.options.lastSeen,
      meta_data: setMetaDataQuery(data.options.metaData),
      name: data.options.vulnerabilityName,
      name_space: data.options.nameSpace,
      manual: data.options.manual ? `${data.options.manual.split(':')[0]}:${data.options.manual.split(':')[1] === 'yes'}` : null,
      merge_status: data.options.mergeStatus,
      mit: data.options.mitigated ? `${data.options.mitigated.split(':')[0]}:${data.options.mitigated.split(':')[1] === 'yes'}` : null,
      nist: data.options.nist,
      net_bios_name: data.options.netBiosName,
      observed_in_the_wild: data.options.observedInTheWild ? `${data.options.observedInTheWild.split(':')[0]}:${data.options.observedInTheWild.split(':')[1] === 'yes'}` : null,
      os: data.options.os,
      overdue: data.options.overdue ? `${data.options.overdue.split(':')[0]}:${data.options.overdue.split(':')[1] === 'yes'}` : null,
      owasp2017: data.options.owasp2017Top10,
      owasp2021: data.options.owasp2021Top10,
      port: data.options.port,
      protocol: data.options.protocol,
      purl: data.options.packageUrl,
      pci: data.options.pci,
      plugin_id: data.options.pluginId,
      plugin_name: data.options.pluginName,
      ra: data.options.riskAccepted,
      region: data.options.region,
      risk_rating: data.options.riskRating,
      risk_factors: data.options.riskFactor,
      ra_mark_date: data.options.raMarkDate,
      service: data.options.service,
      severity: data.options.severity,
      status: data.options.status,
      text: data.options.textSearch,
      to: data.options.firstSeenTo,
      tool_name: data.options.scanner,
      tool_type: data.options.scannerType,
      tp: data.options.truePositive ? `${data.options.truePositive.split(':')[0]}:${data.options.truePositive.split(':')[1] === 'yes'}` : undefined,
      was_zero_day: data.options.wasZeroDay ? `${data.options.wasZeroDay.split(':')[0]}:${data.options.wasZeroDay.split(':')[1] === 'yes'}` : null,
      workload_name: data.options.workloadName,
      workload_namespace: data.options.workloadNamespace,
      workload_kind: data.options.workloadKind,
      woe: data.options.woe,
      wf: data.options.wontfix ? `${data.options.wontfix.split(':')[0]}:${data.options.wontfix.split(':')[1] === 'yes'}` : null,
    },
  };
  return params;
};

const useProjectStore = defineStore({
  id: 'Project',
  state: () => ({
    // ## Project details. ---------------------------------------------------------------------------
    projectDetails: {},
    projectDetailsPending: false,
    projectDetailsDone: false,

    // ## Project release status. ---------------------------------------------------------------------------
    projectReleaseStatus: {},
    projectReleaseStatusPending: false,
    projectReleaseStatusDone: false,

    // ## Create project. ----------------------------------------------------------------------------
    createProjectData: {},
    createProjectPending: false,
    createProjectDone: false,

    // ## Update project. ----------------------------------------------------------------------------
    updateProjectData: {},
    updateProjectPending: false,
    updateProjectDone: false,
    updateProjectFail: false,

    // ## Project list. ------------------------------------------------------------------------------
    projectList: [],
    projectListPending: false,
    projectListDone: false,

    // ## All projects. ------------------------------------------------------------------------------
    allProjects: dummyData,
    allProjectsPerPage: PROJECT.PERPAGE,
    allProjectsTotal: 0,
    allProjectsPageSize: 1,
    allProjectsPending: false,
    allProjectsDone: false,
    allProjectsFail: false,
    allProjectsFailMsg: null,

    // ## All project scans. -------------------------------------------------------------------------
    projectScansTable: scanDummyData,
    projectScansTablePerpage: SCANS.PERPAGE,
    projectScansTableTotal: 0,
    projectScansTablePending: false,
    projectScansTableDone: false,
    projectScansTableFail: false,
    projectScansTableFailMsg: null,

    // ## Project scans failed table. --------------------------------------------------------------------
    projectScansFailedTable: dummyData,
    projectScansFailedTablePerpage: SCANS.PERPAGE,
    projectScansFailedTableTotal: 0,
    projectScansFailedTablePending: false,
    projectScansFailedTableDone: false,
    projectScansFailedTableFail: false,
    projectScansFailedTableFailMsg: null,

    // ## Project vulns table. -----------------------------------------------------------------------
    projectVulnsTable: vulnDummyData,
    projectVulnsTableSettings: null,
    projectVulnsTableTotal: 0,
    projectVulnsTablePending: false,
    projectVulnsTableDone: false,
    projectVulnsTableFail: false,
    projectVulnsTableFailMsg: null,
    projectVulnsDynamicViewItems: {},

    // ## Project cloud vulns table. -----------------------------------------------------------------------
    projectCloudVulnsTable: vulnDummyData,
    projectCloudVulnsTableTotal: 0,
    projectCloudVulnsTablePending: false,
    projectCloudVulnsTableDone: false,
    projectCloudVulnsTableFail: false,
    projectCloudVulnsTableFailMsg: null,

    // ## Project cloud filter. -----------------------------------------------------------------------
    projectCloudFilter: {},
    projectCloudFilterPending: false,
    projectCloudFilterDone: false,
    projectCloudFilterFail: false,
    projectCloudFilterFailMsg: null,

    updateProjectCloudFilterPending: false,
    updateProjectCloudFilterDone: false,
    updateProjectCloudFilterFail: false,
    updateProjectCloudFilterFailMsg: null,

    // ## Project vulns dynamic view. ------------------------------------------------------------------------
    projectVulnsDynamicView: projectVulnsDynamicViewDummyData,
    projectVulnsDynamicViewTotal: 0,
    projectVulnsDynamicViewPending: false,
    projectVulnsDynamicViewDone: false,
    projectVulnsDynamicViewFail: false,
    projectVulnsDynamicViewFailMsg: null,

    // ## Import project vulns. ----------------------------------------------------------------------
    importProjectVulnsData: false,
    importProjectVulnsPending: false,
    importProjectVulnsDone: false,
    importProjectVulnsFail: false,
    importProjectVulnsFailMsg: null,

    // ## Get project vulns template. ----------------------------------------------------------------
    projectVulnsTemplate: false,
    projectVulnsTemplatePending: false,
    projectVulnsTemplateDone: false,

    // ## Create project vulns. ----------------------------------------------------------------------
    createProjectVulnsData: false,
    createProjectVulnsPending: false,
    createProjectVulnsDone: false,
    createProjectVulnsFail: false,
    createProjectVulnsFailMsg: null,

    // ## Validate project name. ---------------------------------------------------------------------
    validateProjectName: false,
    validateProjectNamePending: false,
    validateProjectNameDone: false,

    // ## Remove project. ----------------------------------------------------------------------------
    removeProjectPending: false,
    removeProjectDone: false,

    // ## Get project files. -------------------------------------------------------------------------
    projectFiles: filesDummyData,
    projectFilesPerpage: 10,
    projectFilesTotal: 0,
    projectFilesPending: false,
    projectFilesDone: false,
    projectFilesFail: false,
    projectFilesFailMsg: null,

    // ## Upload project files. ----------------------------------------------------------------------
    uploadProjectFilesData: null,
    uploadProjectFilesPending: false,
    uploadProjectFilesDone: false,

    // ## Delete project file. -----------------------------------------------------------------------
    deleteProjectFileData: null,
    deleteProjectFilePending: false,
    deleteProjectFileDone: false,

    // ## Get project images. ------------------------------------------------------------------------
    projectImages: filesDummyData,
    projectImagesPerpage: 10,
    projectImagesTotal: 0,
    projectImagesPending: false,
    projectImagesDone: false,
    projectImagesFail: false,
    projectImagesFailMsg: null,

    // ## Get project image details. -----------------------------------------------------------------
    projectImageDetails: {},
    projectImageDetailsPending: false,
    projectImageDetailsDone: false,
    projectImageDetailsFail: false,

    // ## Get project sbom table. --------------------------------------------------------------------
    projectSbomTable: sbomDummy,
    projectSbomTablePerpage: SBOM.PERPAGE,
    projectSbomTableTotal: 0,
    projectSbomTablePending: false,
    projectSbomTableDone: false,
    projectSbomTableFail: false,
    projectSbomTableFailMsg: null,

    // ## Get project sbom detail table. -------------------------------------------------------------
    projectSbomComponentTable: sbomDummy,
    projectSbomComponentTablePerpage: SBOM.PERPAGE,
    projectSbomComponentTableTotal: 0,
    projectSbomComponentLastUpdatedDate: null,
    projectSbomComponentNextScheduleDate: null,
    projectSbomComponentTablePending: false,
    projectSbomComponentTableDone: false,
    projectSbomComponentTableFail: false,
    projectSbomComponentTableFailMsg: null,

    // ## Project asvs chapters. --------------------------------------------------------------------
    projectAsvsChapters: asvsChaptersDummyData,
    projectAsvsChaptersPending: false,
    projectAsvsChaptersDone: false,
    projectAsvsChaptersFail: false,
    projectAsvsChaptersFailMsg: null,

    // ## Project asvs sections. --------------------------------------------------------------------
    projectAsvsSections: [],
    projectAsvsSectionsDummy: asvsSectionDummyData,
    projectAsvsSectionsPending: false,
    projectAsvsSectionsDone: false,

    // ## Project asvs requireds. --------------------------------------------------------------------
    projectAsvsRequireds: [],
    projectAsvsRequiredsPending: false,
    projectAsvsRequiredsDone: false,

    // ## Update project asvs settings. --------------------------------------------------------------
    updateProjectAsvsSettingsData: [],
    updateProjectAsvsSettingsPending: false,
    updateProjectAsvsSettingsDone: false,

    // ## Project branches. --------------------------------------------------------------------------
    projectBranches: [],
    projectBranchesPending: false,
    projectBranchesDone: false,

    // ## Endpoints list. ----------------------------------------------------------------------------
    projectEndpoints: [],
    projectEndpointsPerpage: 10,
    projectEndpointsTotal: null,
    projectEndpointsPending: false,
    projectEndpointsDone: false,

    // ## Add endpoint. ------------------------------------------------------------------------------
    addProjectEndpointPending: false,
    addProjectEndpointDone: false,
    addProjectEndpointFail: false,
    addProjectEndpointFailMsg: null,

    // ## Delete endpoint. ---------------------------------------------------------------------------
    deleteProjectEndpointPending: false,
    deleteProjectEndpointDone: false,
    deleteProjectEndpointFail: false,
    deleteProjectEndpointFailMsg: null,

    // ## Update edpoint. ----------------------------------------------------------------------------
    updateProjectEndpointPending: false,
    updateProjectEndpointDone: false,
    updateProjectEndpointFail: false,
    updateProjectEndpointFailMsg: null,

    // ## Project endpoints list. --------------------------------------------------------------------
    projectEndpointsList: endpointDummyData,
    projectEndpointsListPerpage: PROJECT.PERPAGE,
    projectEndpointsListTotal: 0,
    projectEndpointsListPending: false,
    projectEndpointsListDone: false,
    projectEndpointsListFail: false,
    projectEndpointsListFailMsg: null,

    // ## Project endpoints detail. ------------------------------------------------------------------
    projectEndpointsDetail: endpointsDetailDummyData,
    projectEndpointsDetailPerpage: PROJECT.PERPAGE,
    projectEndpointsDetailTotal: 0,
    projectEndpointsDetailPending: false,
    projectEndpointsDetailDone: false,

    compiledProjectRule: [],
    compiledProjectRuleRaw: [],

    // ## Project of projects. -----------------------------------------------------------------------
    productOfProjects: null,
    productOfProjectsPending: false,
    productOfProjectsDone: false,

    // ## Assets management. -----------------------------------------------------------------------
    assetsManagement: managementDummyData,
    assetsManagementTotal: 0,
    assetsManagementPageSize: 1,
    assetsManagementPending: false,
    assetsManagementDone: false,
    assetsManagementFail: false,
    assetsManagementFailMsg: null,

    // ## Update assets management. ----------------------------------------------------------------
    updateAssetsManagementPending: false,
    updateAssetsManagementDone: false,
    updateAssetsManagementFail: false,
    updateAssetssManagementFailMsg: null,

    // ## Delete assets management. ----------------------------------------------------------------
    deleteAssetsManagementPending: false,
    deleteAssetsManagementDone: false,
    deleteAssetsManagementFail: false,

    // ## Assets management metrics. -----------------------------------------------------------------------
    assetsManagementMetrics: managementMetricsDummyData,
    assetsManagementMetricsDone: false,
    assetsManagementMetricsPending: false,
    assetsManagementMetricsFail: false,
    assetsManagementMetricsFailMsg: null,

    validateMetaData: true,
    validateMetaDataPending: false,
    validateMetaDataDone: false,

    projectDashboardFilter: {},
  }),
  actions: {
    // ## Get project details. -----------------------------------------------------------------------
    async getProjectDetails(data) {
      this.projectDetailsPending = true;

      try {
        const res = await ProjectService.getProjectDetails(data);
        this.projectDetails = res.data.data;
        this.projectDetailsDone = !this.projectDetailsDone;
        this.projectDetailsPending = false;
      } catch (error) {
        this.projectDetailsPending = false;
        ErrorHandling(error);
      }
    },

    resetProjectDetails() {
      this.projectDetails = {};
    },

    // ## Get project release status. -----------------------------------------------------------------------
    async getProjectReleaseStatus(data) {
      this.projectReleaseStatusPending = true;

      try {
        const res = await ProjectService.getProjectReleaseStatus(data);
        this.projectReleaseStatus = res.data.data;
        this.projectReleaseStatusDone = !this.projectReleaseStatusDone;
        this.projectReleaseStatusPending = false;
      } catch (error) {
        this.projectReleaseStatusPending = false;
        ErrorHandling(error);
      }
    },

    // ## Create project. ----------------------------------------------------------------------------
    async createProject(data) {
      this.createProjectPending = true;

      try {
        const res = await ProjectService.createProject(data);
        this.createProjectData = res.data.data;
        this.createProjectDone = !this.createProjectDone;
        this.createProjectPending = false;
      } catch (error) {
        this.createProjectPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update project. ----------------------------------------------------------------------------
    async updateProject(data) {
      this.updateProjectPending = true;

      try {
        const res = await ProjectService.updateProject(data);
        this.updateProjectData = res.data.data;
        this.updateProjectDone = !this.updateProjectDone;
        this.updateProjectPending = false;
      } catch (error) {
        this.updateProjectFail = !this.updateProjectFail;
        this.updateProjectPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get project list. --------------------------------------------------------------------------
    async getProjectList(data) {
      this.projectListPending = true;

      try {
        const res = await ProjectService.getProjectList(data);
        this.projectList = res.data.data;
        this.projectListDone = !this.projectListDone;
        this.projectListPending = false;
      } catch (error) {
        this.projectListPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get all projects. --------------------------------------------------------------------------
    async getAllProjects(data) {
      this.allProjectsPending = true;

      try {
        const params = {
          limit: this.allProjectsPerPage,
          start: (data.page - 1) * this.allProjectsPerPage,
          sort_by: data.sort,
          asvs_level: data.businessCriticality,
          language: data.language,
          labels: data.label,
          label_ids: data.labelIds,
          infra_group_ids: data.infraGroupNames,
          playbook: data.securityCriteria,
          products: data.product,
          name: data.project,
          project_ids: data.projectIds,
          teams: data.team,
          team_ids: data.teamIds,
          tool_ype: data.scannerType,
          request_usage: data.request_usage,
        };
        const res = await ProjectService.get(params);
        this.allProjectsTotal = res.data.total;
        this.allProjectsPageSize = Math.ceil(this.allProjectsTotal / this.allProjectsPerPage);
        this.setGetAllProjects(res.data);
        this.allProjectsDone = !this.allProjectsDone;
        this.allProjectsPending = false;
      } catch (error) {
        this.allProjects = dummyData;
        this.allProjectsTotal = 0;
        this.allProjectsPending = false;
        this.allProjectsFail = !this.allProjectsFail;
        this.allProjectsFailMsg = ErrorHandling(error);
      }
    },

    setGetAllProjects(payload) {
      const setLast = (s) => {
        let data = {
          tool: null,
          date: null,
          manual: false,
          is_custom: 'internal',
        };
        if (s) {
          data = {
            tool: s.scan_tool,
            date: dayjs(s.scan_date).format('DD MMM YYYY HH:mm'),
            manual: s.manual,
            is_custom: s.is_custom,
          };
        }
        return data;
      };
      this.allProjects = payload.data.map((s) => ({
        id: s.id,
        name: s.name,
        status: s.playbook,
        team: s.owner ? s.owner.team.name : null,
        summary: s.summary,
        score: s.score,
        trend: s.trend,
        trendPercent: s.trend_percent,
        lastScan: setLast(s.last),
        labels: (s.labels ? s.labels.map((a) => ({
          name: a.name, type: a.type, color: `#${a.color}`, auto_label_keywords: a.auto_label_keywords,
        })) : null),
        createdDate: dayjs(s.created_at).format('DD MMM YYYY HH:mm'),
        languages: s.actions ? s.actions.repo.languages : null,
        defaultBranch: s.default_branch,
        businessCriticality: s.risk_profile_item.name,
        infraGroupNames: s.infra_group_names,
        products: s.product_names ? s.product_names : [],
      }));
    },

    // ## Get all scans. -----------------------------------------------------------------------------
    async getProjectScansTable(data) {
      this.projectScansTablePending = true;
      this.projectScansTable = scanDummyData;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.projectScansTablePerpage,
            start: (data.options.page - 1) * this.projectScansTablePerpage,
            sort_by: data.options.sort,
            analyzing: data.options.analyzing ? `${data.options.analyzing.split(':')[0]}:${data.options.analyzing.split(':')[1] === 'completed'}` : null,
            tool_name: data.options.scanner,
            branch: data.options.branch,
            engagement: data.options.engagement,
            id: data.options.id,
            manual: data.manual,
            discoveredBy: data.options.discoveredBy,
            meta_data: setMetaDataQuery(data.options.metaData),
            imported_by: data.options.importedBy,
            scan_type: data.options.scanType,
          },
        };
        const res = await ProjectService.getScans(params);
        this.projectScansTableTotal = res.data.total;
        this.setGetProjectScansTable(res.data);
        this.projectScansTableDone = !this.projectScansTableDone;
        this.projectScansTablePending = false;
      } catch (error) {
        this.projectScansTable = scanDummyData;
        this.projectScansTableTotal = 0;
        this.projectScansTablePending = false;
        this.projectScansTableFail = !this.projectScansTableFail;
        this.projectScansTableFailMsg = ErrorHandling(error);
      }
    },

    setGetProjectScansTable(payload) {
      this.projectScansTable = [];
      this.projectScansTable = payload.data.map((s) => ({
        key: s.id,
        id: s.id,
        branch: s.scanparams.branch ? s.scanparams.branch : '-',
        metaData: s.scanparams.meta_data,
        scanDate: dayjs(s.scan_date).format('DD MMM YYYY HH:mm'),
        scanner: s.scanparams.tool.name,
        prefix: s.scanparams.tool.is_custom ? 'custom' : 'internal',
        toolType: s.scanparams.tool.type,
        toolDisabled: s.scanparams.tool.disabled,
        scanType: s.scan_type ? s.scan_type.split(',') : null,
        projectName: s.scanparams.project.name,
        scanParams: s.scanparams,
        forkScan: s.scanparams.fork_scan,
        status: 'completed',
        manual: s.scanparams.manual,
        discoveredBy: (s.import_info ? s.import_info.discovered_by : null),
        engagement: s.import_info ? s.import_info.engagement : null,
        importedBy:
          (s.import_info && s.import_info.imported_by ? s.import_info.imported_by.username : null),
        dateDiscovered:
          (s.import_info ?
            dayjs(s.import_info.date_discovered).format('DD MMM YYYY HH:mm') : null),
        uploadedDate:
          (s.import_info ? dayjs(s.import_info.uploaded_at).format('DD MMM YYYY HH:mm') : null),
        duration: !s.duration ? '-' : dayjs.unix(s.duration / 1000).utc().format('HH:mm:ss'),
        critical: s.summary.critical ? s.summary.critical : 0,
        high: s.summary.high ? s.summary.high : 0,
        medium: s.summary.medium ? s.summary.medium : 0,
        low: s.summary.low ? s.summary.low : 0,
        info: s.summary.info ? s.summary.info : 0,
        action: s.id,
      }));
    },

    // ## Get project scans failed table. ----------------------------------------------------------------
    async getProjectScansFailedTable(data) {
      this.projectScansFailedTablePending = true;
      this.projectScansFailedTable = scanDummyData;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.projectScansFailedTablePerpage,
            start: (data.options.page - 1) * this.projectScansFailedTablePerpage,
            sort_by: data.options.sort,
            branch: data.options.branch,
            meta_data: setMetaDataQuery(data.options.metaData),
            teams: data.options.team,
            tool_name: data.options.scanner,
          },
        };
        const res = await ProjectService.getProjectFailed(params);
        this.projectScansFailedTableTotal = res.data.total;
        this.setGetProjectScansFailedTable(res.data.data);
        this.projectScansFailedTableDone = !this.projectScansFailedTableDone;
        this.projectScansFailedTablePending = false;
      } catch (error) {
        this.projectScansFailedTable = scanDummyData;
        this.projectScansFailedTableTotal = 0;
        this.projectScansFailedTablePending = false;
        this.projectScansFailedTableFail = !this.projectScansFailedTableFail;
        this.projectScansFailedTableFailMsg = ErrorHandling(error);
      }
    },

    setGetProjectScansFailedTable(payload) {
      this.projectScansFailedTable = [];
      payload.forEach((s) => {
        this.projectScansFailedTable.push({
          key: s.id,
          id: s.id,
          startedDate: dayjs(s.started_at).format('DD MMM YYYY HH:mm'),
          failedDate: dayjs(s.finished_at).format('DD MMM YYYY HH:mm'),
          scanner: s.scanparams.tool.name,
          prefix: s.scanparams.tool.is_custom ? 'custom' : 'internal',
          scanType: s.scan_type ? s.scan_type.split(',') : null,
          toolDisabled: s.scanparams.tool.disabled,
          project: s.project.name,
          projectId: s.project.id,
          branch: s.scanparams.branch ? s.scanparams.branch : '-',
          status: s.status,
          message: s.message === '' ? 'N/A' : s.message,
          manual: s.scanparams.manual,
          team: s.project.owner.team.name,
          metaData: s.scanparams.meta_data,
          forkScan: s.scanparams.fork_scan,
          scanParams: s.scanparams,
        });
      });
    },

    // ## Get project vulns. -------------------------------------------------------------------------
    async getProjectVulnsTable(data) {
      this.projectVulnsTablePending = true;
      this.projectVulnsTable = vulnDummyData;

      try {
        const res = await ProjectService.getVulns(vulnParams(data));
        this.setGetProjectVulnsTable({
          result: res.data.data,
          data,
        });
        this.projectVulnsTableSettings = res.data.custom_list_options;
        this.projectVulnsTableDone = !this.projectVulnsTableDone;
        this.projectVulnsTablePending = false;
      } catch (error) {
        this.projectVulnsTable = vulnDummyData;
        this.projectVulnsTablePending = false;
        this.projectVulnsTableFail = !this.projectVulnsTableFail;
        this.projectVulnsTableFailMsg = ErrorHandling(error);
      }
    },

    setGetProjectVulnsTable(payload) {
      let data = [];
      let vulnsTableData = [];
      if (payload.result.vulns) {
        vulnsTableData = payload.result.vulns;
      } else {
        vulnsTableData = payload.result;
      }
      data = vulnsTableData.map((s) => ({
        key: s.id,
        id: s.id,
        branch: s.scanparams.branch,
        committer: s.committer_name,
        cvss: s.cvss_v3.toFixed(1),
        cweId: s.cwe.cwe_id,
        cweName: s.cwe.name,
        dockerImage: (s.docker_image ? s.docker_image : '-'),
        dueDate: s.sla && dayjs(s.sla.due_date).format('DD MMM YYYY'),
        engagement: s.scanparams.engagement,
        environment: s.scanparams.environment,
        epss: s.ti.epss,
        falsePositive: s.is_fp,
        truePositive: s.is_tp,
        fpMarkDate: dayjs(s.fp_mark_date).format('DD MMM YYYY'),
        firstSeen: dayjs(s.first_seen).format('DD MMM YYYY'),
        hasComment: s.has_comment,
        hasFix: s.fixed_version_exist,
        fqdn: s.fqdn,
        manual: s.scanparams.manual,
        mergeStatus: s.merge_status,
        metaData: s.scanparams.meta_data,
        mitigated: s.is_mitigated,
        mscore: s.ti.m_score,
        name: s.name,
        observedInTheWild: s.ti.observedInTheWild,
        overdue: (s.sla ? s.sla : { overdue: false, rule: 0 }),
        owasp2017Top10: s.cwe.classification.owasp_2017.id ?
          s.cwe.classification.owasp_2017.name.replace('_', ' - ').replaceAll('_', ' ') : '-',
        owasp2021Top10: s.cwe.classification.owasp_2021.id ?
          s.cwe.classification.owasp_2021.name.replace('_', ' - ').replaceAll('_', ' ') : '-',
        knownExploit: s.ti.known_exploit,
        infraGroupNames: s.infra_group_names,
        issueAssigned: s.issue_assigned,
        issueClosedDate: dayjs(s.issue_closed_date).format('DD MMM YYYY'),
        issueCreatedDate: dayjs(s.issue_created_date).format('DD MMM YYYY'),
        isMaster: s.is_master,
        issueStatus: s.issue_status,
        issueLink: s.issue_link,
        issueWIPDate: dayjs(s.issue_wip_date).format('DD MMM YYYY'),
        lastSeen: dayjs(s.last_seen).format('DD MMM YYYY'),
        path: (s.path ? s.path : '-'),
        pci: s.cwe.classification.pci_dss.id ?
          s.cwe.classification.pci_dss.name.replace('_', ' - ').replaceAll('_', ' ') : '-',
        raMarkDate: dayjs(s.ra_mark_date).format('DD MMM YYYY'),
        ip: (s.path ? s.path : '-'),
        scanner: s.scanparams.tool.name,
        prefix: s.scanparams.tool.is_custom ? 'custom' : 'internal',
        scanParams: s.scanparams,
        severity: s.severity,
        scannerType: s.scanparams.tool.type,
        status: s.status,
        wasZeroDay: s.was_zero_day,
        woe: s.woe,
        wontfix: s.is_wf,
      }));
      if (payload.data.itemId || payload.data.itemId === '') {
        this.projectVulnsDynamicViewItems[payload.data.itemId] = data;
      } else {
        this.projectVulnsTable = [];
        this.projectVulnsTable = data;
      }
    },

    // ## Get project vulns total. -------------------------------------------------------------------------
    async getProjectVulnsTableTotal(data) {
      try {
        const res = await ProjectService.getVulnsTotal(vulnParams(data));
        this.projectVulnsTableTotal = res.data.total;
      } catch (error) {
        this.projectVulnsTableTotal = 0;
        ErrorHandling(error);
      }
    },

    // ## Get project cloud vulns. -------------------------------------------------------------------------
    async getProjectCloudVulnsTable(data) {
      this.projectCloudVulnsTablePending = true;
      this.projectCloudVulnsTable = vulnDummyData;

      try {
        const params = {
          id: data.id,
          type: data.type,
          prefix: data.prefix,
          query: {
            limit: data.options.perPage,
            start: (data.options.page - 1) * data.options.perPage,
            sort_by: data.options.sort,
            has_cisa_kev_exploit: data.options.cisaKevExploit ? `${data.options.cisaKevExploit.split(':')[0]}:${data.options.cisaKevExploit.split(':')[1] === 'yes'}` : null,
            cloud_platform: data.options.platform,
            is_closed: data.options.closed ? `${data.options.closed.split(':')[0]}:${data.options.closed.split(':')[1] === 'yes'}` : null,
            has_exploit: data.options.exploit ? `${data.options.exploit.split(':')[0]}:${data.options.exploit.split(':')[1] === 'yes'}` : null,
            image_name: data.options.imageName,
            name: data.options.name,
            region: data.options.region,
            severity: data.options.severity,
            open_to_internet: data.options.openToInternet ? `${data.options.openToInternet.split(':')[0]}:${data.options.openToInternet.split(':')[1] === 'yes'}` : null,
            operating_system: data.options.os,
            ip_addresses: data.options.ipAddresses,
          },
        };
        const res = await ProjectService.getVulns(params);
        this.setGetProjecCloudtVulnsTable(res.data);
        this.projectCloudVulnsTableTotal = res.data.total;
        this.projectCloudVulnsTableDone = !this.projectCloudVulnsTableDone;
        this.projectCloudVulnsTablePending = false;
      } catch (error) {
        this.projectCloudVulnsTable = vulnDummyData;
        this.projectCloudVulnsTablePending = false;
        this.projectCloudVulnsTableFail = !this.projectCloudVulnsTableFail;
        this.projectCloudVulnsTableFailMsg = ErrorHandling(error);
      }
    },

    setGetProjecCloudtVulnsTable(payload) {
      this.projectCloudVulnsTable = [];

      if (!payload.cloud_vulnerabilities) {
        return;
      }

      this.projectCloudVulnsTable = payload.cloud_vulnerabilities.map((s) => ({
        key: s.id,
        id: s.id,
        cloudId: s.vulnerability.cloud_id,
        name: s.vulnerability.name,
        locationPath: s.vulnerability.location_path,
        imageId: s.vulnerability.image_id,
        imageName: s.vulnerability.image_name,
        status: s.vulnerability.status,
        cveId: s.vulnerability.cve,
        severity: s.vulnerability.severity,
        currentVersion: s.vulnerability.current_version,
        fixedVersion: s.vulnerability.fixed_version,
        remediation: s.vulnerability.remediation,
        hasExploit: s.vulnerability.has_exploit,
        hasCisaKevExploit: s.vulnerability.has_cisa_kev_exploit,
        cveLink: s.vulnerability.cve_link,
        cloudToolUrl: s.vulnerability.cloud_tool_url,
        detectionMethod: s.vulnerability.detection_method,
        assetId: s.vulnerability.asset_id,
        assetName: s.vulnerability.asset_name,
        platform: s.vulnerability.cloud_platform,
        region: s.vulnerability.region,
        subscriptionId: s.vulnerability.subscription_id,
        firstDetected: dayjs(s.vulnerability.first_detected).format('DD MMM YYYY'),
        lastDetected: dayjs(s.vulnerability.last_detected).format('DD MMM YYYY'),
        os: s.vulnerability.operating_system,
        openToInternet: s.vulnerability.open_to_internet,
        ipAddresses: s.vulnerability.ip_addresses,
      }));
    },

    // ## Get project cloud filter. -------------------------------------------------------------------------
    async getProjectCloudFilter(data) {
      this.projectCloudFilterPending = true;

      try {
        const res = await ProjectService.getProjectCloudFilter(data);
        this.setGetProjectCloudFilter(res.data);
        this.projectCloudFilterDone = !this.projectCloudFilterDone;
        this.projectCloudFilterPending = false;
      } catch (error) {
        this.projectCloudFilterPending = false;
        this.projectCloudFilterFail = !this.projectCloudFilterFail;
        this.projectCloudFilterFailMsg = ErrorHandling(error);
      }
    },

    setGetProjectCloudFilter(payload) {
      this.projectCloudFilter = {
        image: payload.image,
        region: payload.region,
        platform: payload.platform,
        severity: payload.severity,
        os: payload.os,
        openToInternet: payload.openToInternet,
        ipAddress: payload.ipAddress,
        assetType: payload.assetType,
        days: payload.days,
        productComponentName: payload.productComponentName,
        affectedResource: payload.affectedResource,
        mitreAttackTactics: payload.mitreAttackTactics,
        status: payload.status,
        resourceType: payload.resourceType,
        accountId: payload.accountId,
        accountName: payload.accountName,
        containerImageId: payload.containerImageId,
        containerImageName: payload.containerImageName,
        containerName: payload.containerName,
        criticality: payload.criticality,
        ec2Ipv4: payload.ec2Ipv4,
        ec2Ipv6: payload.ec2Ipv6,
        ec2ProfileArn: payload.ec2ProfileArn,
        ec2Type: payload.ec2Type,
        networkDestinationIpv4: payload.networkDestinationIpv4,
        networkDestinationIpv6: payload.networkDestinationIpv6,
        networkSourcePort: payload.networkSourcePort,
        productName: payload.productName,
        processName: payload.processName,
        processPath: payload.processPath,
        resourceId: payload.resourceId,
        title: payload.title,
        type: payload.type,
      };
    },

    resetProjectCloudFilter() {
      this.projectCloudFilter = {};
    },

    async updateProjectCloudFilter(data) {
      this.updateProjectCloudFilterPending = true;

      try {
        await ProjectService.updateProjectCloudFilter(data);
        this.updateProjectCloudFilterDone = !this.updateProjectCloudFilterDone;
        this.updateProjectCloudFilterPending = false;
      } catch (error) {
        this.updateProjectCloudFilterPending = false;
        this.updateProjectCloudFilterFail = !this.updateProjectCloudFilterFail;
        this.updateProjectCloudFilterFailMsg = ErrorHandling(error);
      }
    },

    // ## Get project vulns path. --------------------------------------------------------------------
    async getProjectVulnsDynamicView(data) {
      this.projectVulnsDynamicViewPending = true;
      this.projectVulnsDynamicView = projectVulnsDynamicViewDummyData;

      try {
        const res = await ProjectService.getProjectVulnsDynamicView(vulnParams(data));
        this.projectVulnsDynamicViewTotal = res.data.total;
        this.projectVulnsTableSettings = res.data.custom_list_options;
        this.setGetProjectVulnsDynamicView(res.data.data);
        this.projectVulnsDynamicViewDone = !this.projectVulnsDynamicViewDone;
        this.projectVulnsDynamicViewPending = false;
      } catch (error) {
        this.projectVulnsDynamicView = projectVulnsDynamicViewDummyData;
        this.projectVulnsDynamicViewTotal = 0;
        this.projectVulnsDynamicViewPending = false;
        this.projectVulnsDynamicViewFail = !this.projectVulnsDynamicViewFail;
        this.projectVulnsDynamicViewFailMsg = ErrorHandling(error);
      }
    },

    setGetProjectVulnsDynamicView(payload) {
      this.projectVulnsDynamicView = payload.map((s) => ({
        id: btoa(s.name + s.id),
        name: s.name,
        count: s.count,
        nodeCount: s.node_count,
        maxSeverity: s.max_severity,
        summary: s.summary,
        tool: {
          isCustom: s.tool.is_custom,
          manual: s.tool.is_manual,
        },
        vulns: s.vulns.map((a) => ({
          id: btoa(a.id + a.name),
          name: a.name,
          cweId: a.cwe_id,
          count: a.count,
          maxSeverity: a.max_severity,
          summary: a.summary,
          tool: {
            isCustom: s.tool.is_custom,
            manual: s.tool.is_manual,
          },
        })),
      }));
    },

    // ## Import project vulns. ----------------------------------------------------------------------
    async importProjectVulns(data) {
      this.importProjectVulnsPending = true;

      try {
        const res = await ProjectService.importVulns(data);
        this.importProjectVulnsData = res.data.data;
        this.importProjectVulnsDone = !this.importProjectVulnsDone;
        this.importProjectVulnsPending = false;
      } catch (error) {
        this.importProjectVulnsFail = !this.importProjectVulnsFail;
        this.importProjectVulnsPending = false;
        this.importProjectVulnsFailMsg = ErrorHandling(error);
      }
    },

    // ## Get project vulns template. ----------------------------------------------------------------
    async getProjectVulnsTemplate(data) {
      this.projectVulnsTemplatePending = true;

      try {
        const res = await ProjectService.getVulnsTemplate(data);
        this.projectVulnsTemplate = res.data;
        this.projectVulnsTemplateDone = !this.projectVulnsTemplateDone;
        this.projectVulnsTemplatePending = false;
      } catch (error) {
        this.projectVulnsTemplatePending = false;
        ErrorHandling(error);
      }
    },

    // ## Add appsec vulns. --------------------------------------------------------------------------
    async createProjectVulns(data) {
      this.createProjectVulnsPending = true;

      try {
        const res = await ProjectService.createProjectVulns(data);
        this.createProjectVulnsData = res.data.data;
        this.createProjectVulnsDone = !this.createProjectVulnsDone;
        this.createProjectVulnsPending = false;
      } catch (error) {
        this.createProjectVulnsFail = !this.createProjectVulnsFail;
        this.createProjectVulnsPending = false;
        this.createProjectVulnsFailMsg = ErrorHandling(error);
      }
    },

    // ## Validate project name. ---------------------------------------------------------------------
    async getValidateProjectName(data) {
      this.validateProjectNamePending = true;

      try {
        const res = await ProjectService.validate(data);
        this.validateProjectName = res.data.result;
        this.validateProjectNameDone = !this.validateProjectNameDone;
        this.validateProjectNamePending = false;
      } catch (error) {
        this.validateProjectNamePending = false;
        ErrorHandling(error);
      }
    },

    // ## Remove project. ----------------------------------------------------------------------------
    async removeProject(data) {
      this.removeProjectPending = true;

      try {
        await ProjectService.remove(data);
        this.removeProjectDone = !this.removeProjectDone;
        this.removeProjectPending = false;
      } catch (error) {
        this.removeProjectPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get project filşe. -------------------------------------------------------------------------
    async getProjectFiles(data) {
      this.projectFilesPending = true;
      this.projectFiles = filesDummyData;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.allProjectsPerPage,
            start: (data.page - 1) * this.allProjectsPerPage,
            sort_by: data.sort,
          },
        };
        const res = await ProjectService.getFiles(params);
        this.projectFilesTotal = res.data.total;
        this.setGetProjectFiles(res.data);
        this.projectFilesDone = !this.projectFilesDone;
        this.projectFilesPending = false;
      } catch (error) {
        this.projectFiles = filesDummyData;
        this.projectFilesTotal = 0;
        this.projectFilesFail = !this.projectFilesFail;
        this.projectFilesPending = false;
        this.projectFilesFailMsg = ErrorHandling(error);
      }
    },

    setGetProjectFiles(payload) {
      // eslint-disable-next-line func-names
      const byteToSize = function (bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
      };
      this.projectFiles = [];
      if (!payload.data) {
        return;
      }
      this.projectFiles = payload.data.map((s) => ({
        id: s.id,
        key: s.id,
        fileName: s.name,
        fileType: s.extension,
        fileSize: byteToSize(s.size),
        uploadDate: dayjs(s.upload_date).format('DD MMM YYYY HH:mm'),
        mimeType: s.mime_type,
      }));
    },

    // ## Upload project file. -----------------------------------------------------------------------
    async uploadProjectFiles(data) {
      this.uploadProjectFilesPending = true;

      try {
        const res = await ProjectService.uploadFiles(data);
        this.uploadProjectFilesData = res.data.data;
        this.uploadProjectFilesDone = !this.uploadProjectFilesDone;
        this.uploadProjectFilesPending = false;
      } catch (error) {
        this.uploadProjectFilesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Upload project files. ----------------------------------------------------------------------
    async deleteProjectFile(data) {
      this.deleteProjectFilePending = true;

      try {
        const res = await ProjectService.deleteFile(data);
        this.deleteProjectFileData = res.data;
        this.deleteProjectFileDone = !this.deleteProjectFileDone;
        this.deleteProjectFilePending = false;
      } catch (error) {
        this.deleteProjectFilePending = false;
        ErrorHandling(error);
      }
    },

    // ## Get project images. ------------------------------------------------------------------------
    async getProjectImages(data) {
      this.projectImagesPending = true;
      this.projectImages = filesDummyData;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.projectImagesPerpage,
            start: (data.page - 1) * this.projectImagesPerpage,
            sort_by: data.sort,
          },
        };
        const res = await ProjectService.getImages(params);
        this.projectImagesTotal = res.data.count;
        this.setGetProjectImages(res.data);
        this.projectImagesDone = !this.projectImagesDone;
        this.projectImagesPending = false;
      } catch (error) {
        this.projectImages = filesDummyData;
        this.projectImagesTotal = 0;
        this.projectImagesFail = !this.projectImagesFail;
        this.projectImagesPending = false;
        this.projectImagesFailMsg = ErrorHandling(error);
      }
    },

    setGetProjectImages(payload) {
      this.projectImages = [];
      this.projectImages = payload.data.map((s, i) => ({
        key: i,
        image: s.details.image,
        branch: s.details.branch,
        hash: s.details.hash,
        critical: s.findings.critical,
        high: s.findings.high,
        medium: s.findings.medium,
        low: s.findings.low,
      }));
    },

    // ## Get project image details. -----------------------------------------------------------------
    async getProjectImageDetails(data) {
      this.projectImageDetailsPending = true;

      try {
        const res = await ProjectService.getImageDetails(data);
        this.projectImageDetails = res.data.data;
        this.projectImageDetailsDone = !this.projectImageDetailsDone;
        this.projectImageDetailsPending = false;
      } catch (error) {
        this.projectImageDetailsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get project sbom table. --------------------------------------------------------------------
    async getProjectSbomTable(data) {
      this.projectSbomTablePending = true;
      this.projectSbomTable = sbomDummy;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.projectSbomTablePerpage,
            start: (data.options.page - 1) * this.projectSbomTablePerpage,
            sort_by: data.options.sort,
            branch: data.options.branch,
            license: data.options.license,
            purl: data.options.packageUrl,
            scan_type: data.options.sbomSource,
            vulnerable: data.options.vulnerable ? `${data.options.vulnerable.split(':')[0]}:${data.options.vulnerable.split(':')[1] === 'yes'}` : null,
          },
        };
        const res = await ProjectService.getProjectSbomTable(params);
        this.projectSbomTableTotal = res.data.total;
        this.setGetProjectSbomTable(res.data.data);
        this.projectSbomTableDone = !this.projectSbomTableDone;
        this.projectSbomTablePending = false;
      } catch (error) {
        this.projectSbomTable = sbomDummy;
        this.projectSbomTableTotal = 0;
        this.projectSbomTableFail = !this.projectSbomTableFail;
        this.projectSbomTablePending = false;
        this.projectSbomTableFailMsg = ErrorHandling(error);
      }
    },

    setGetProjectSbomTable(payload) {
      this.projectSbomTable = [];
      if (!payload) {
        return;
      }
      this.projectSbomTable = payload.map((s, i) => ({
        key: i,
        id: i,
        lastUpdatedDate: dayjs(s.updated_at).format('DD MMM YYYY HH:mm'),
        sbomFormat: s.sbom_format,
        branch: s.project.branch,
        scanType: s.scan_type,
        inspectorTool: s.inspector,
        vulnerableComponents: s.vulnerable_component_count,
        totalComponents: s.component_count,
        projectId: s.project.id,
      }));
    },

    // ## Get project sbom detail table. -------------------------------------------------------------
    async getProjectSbomComponentTable(data) {
      this.projectSbomComponentTablePending = true;
      this.projectSbomComponentTable = sbomDetailDummy;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.projectSbomComponentTablePerpage,
            start: (data.options.page - 1) * this.projectSbomComponentTablePerpage,
            sort_by: data.options.sort,
            branch: data.options.branch,
            license: data.options.licenses,
            purl: data.options.packageUrl,
            risk_factor: data.options.licenseRisk,
            source_type: data.options.sbomSource,
            severity: data.options.severity,
            name: data.options.name,
            vulnerable: data.options.vulnerable,
          },
        };
        const res = await ProjectService.getProjectSbomComponentTable(params);
        this.projectSbomComponentTableTotal = res.data.total;
        this.projectSbomComponentLastUpdatedDate = dayjs(res.data.last_updated_date).format('DD MMM YYYY HH:mm');
        this.projectSbomComponentNextScheduleDate = dayjs(res.data.next_schedule_date).format('DD MMM YYYY HH:mm');
        this.setGetProjectSbomComponentTable(res.data);
        this.projectSbomComponentTableDone = !this.projectSbomComponentTableDone;
        this.projectSbomComponentTablePending = false;
      } catch (error) {
        this.projectSbomComponentTable = sbomDetailDummy;
        this.projectSbomComponentTableTotal = 0;
        this.projectSbomComponentTableFail = !this.projectSbomComponentTableFail;
        this.projectSbomComponentTablePending = false;
        this.projectSbomComponentTableFailMsg = ErrorHandling(error);
      }
    },

    setGetProjectSbomComponentTable(payload) {
      this.projectSbomComponentTable = [];
      if (!payload.data) {
        return;
      }
      this.projectSbomComponentTable = payload.data.map((s, i) => ({
        key: i,
        id: i,
        componentName: s.component_name,
        componentVersion: s.component_version,
        componentType: s.component_type,
        project: s.projects,
        branch: s.branches,
        licenses: s.licenses ? s.licenses.map((a) => (a.id === '' ? a.name : a.id)) : [],
        packageUrl: s.purl,
        licenseRisk: s.highest_license_risk_factor.severity,
        vulnerabilities: s.vulnerabilities,
        critical: s.summary.critical ? s.summary.critical : 0,
        high: s.summary.high ? s.summary.high : 0,
        medium: s.summary.medium ? s.summary.medium : 0,
        low: s.summary.low ? s.summary.low : 0,
        info: s.summary.info ? s.summary.info : 0,
      }));
    },

    // ## Get asvs chapters. -------------------------------------------------------------------------
    async getProjectAsvsChapters(data) {
      this.projectAsvsChaptersPending = true;

      try {
        const params = {
          id: data.id,
          query: {
            branch: data.branch,
          },
        };
        const res = await ProjectService.getProjectAsvsChapters(params);
        this.setGetProjectAsvsChapters(res.data);
        this.projectAsvsChaptersDone = !this.projectAsvsChaptersDone;
        this.projectAsvsChaptersPending = false;
      } catch (error) {
        this.projectAsvsChaptersFail = !this.projectAsvsChaptersFail;
        this.projectAsvsChaptersPending = false;
        this.projectAsvsChaptersFailMsg = ErrorHandling(error);
      }
    },

    setGetProjectAsvsChapters(payload) {
      this.projectAsvsChapters = [];
      if (!payload.data) {
        return;
      }
      this.projectAsvsChapters = payload.data.sort((a, b) => {
        const NumberA = Number(a.id.split('V')[1]);
        const NumberB = Number(b.id.split('V')[1]);
        if (NumberB < NumberA) return 1;
        if (NumberB > NumberA) return -1;
        return 0;
      });
    },

    // ## Get asvs sections. -------------------------------------------------------------------------
    async getProjectAsvsSections(data) {
      this.projectAsvsSectionsPending = true;

      try {
        const params = {
          id: data.id,
          chapters: data.chapters,
          query: {
            branch: data.branch,
          },
        };
        const res = await ProjectService.getProjectAsvsSections(params);
        this.setGetProjectAsvsSections({
          id: data.chapters,
          data: res.data.data,
        });
        this.projectAsvsSectionsDone = !this.projectAsvsSectionsDone;
        this.projectAsvsSectionsPending = false;
      } catch (error) {
        this.projectAsvsSections = false;
        ErrorHandling(error);
      }
    },

    setGetProjectAsvsSections(payload) {
      if (!payload.data) {
        return;
      }
      this.projectAsvsSections[payload.id] = payload.data;
      this.projectAsvsSections[payload.id].sort((a, b) => {
        const NumberA = Number(a.id.split('.')[1]);
        const NumberB = Number(b.id.split('.')[1]);
        if (NumberB < NumberA) return 1;
        if (NumberB > NumberA) return -1;
        return 0;
      });
    },

    // ## Get asvs requireds. ------------------------------------------------------------------------
    async getProjectAsvsRequireds(data) {
      this.projectAsvsRequiredsPending = true;

      try {
        const params = {
          id: data.id,
          sections: data.sections,
          query: {
            branch: data.branch,
          },
        };
        const res = await ProjectService.getProjectAsvsRequireds(params);
        this.setGetProjectAsvsRequireds({
          id: data.sections,
          data: res.data.data,
        });
        this.projectAsvsRequiredsDone = !this.projectAsvsRequiredsDone;
        this.projectAsvsRequiredsPending = false;
      } catch (error) {
        this.projectAsvsRequiredsPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectAsvsRequireds(payload) {
      if (!payload.data) {
        return;
      }
      this.projectAsvsRequireds[payload.id] = payload.data;
    },

    resetProjectAllAsvs() {
      this.projectAsvsChapters = asvsChaptersDummyData;
      this.projectAsvsSections = [];
      this.projectAsvsRequireds = [];
    },

    // ## Update asvs settings. ----------------------------------------------------------------------
    async updateProjectAsvsSettings(data) {
      this.updateProjectAsvsSettingsPending = true;

      try {
        const params = {
          id: data.id,
          query: {
            reqid: data.reqid,
            status: data.status,
            branch: data.branch,
          },
        };
        const res = await ProjectService.updateProjectAsvsSettings(params);
        this.updateProjectAsvsSettingsData = res.data.data;
        this.updateProjectAsvsSettingsDone = !this.updateProjectAsvsSettingsDone;
        this.updateProjectAsvsSettingsPending = false;
      } catch (error) {
        this.updateProjectAsvsSettingsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get project branhes. -----------------------------------------------------------------------
    async getProjectBranches(data) {
      this.projectBranchesPending = true;

      try {
        const res = await ProjectService.getProjectBranches(data);
        this.setGetProjectBranches(res.data.data);
        this.projectBranchesDone = !this.projectBranchesDone;
        this.projectBranchesPending = false;
      } catch (error) {
        this.projectBranchesPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectBranches(payload) {
      this.projectBranches = [];
      if (payload.length < 1) {
        return;
      }
      this.projectBranches = payload.map((s) => ({
        label: s,
        value: s,
      }));
    },

    // ## Get project name. --------------------------------------------------------------------------
    async getProjectEndpoints(data) {
      this.projectEndpointsPending = true;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.projectEndpointsPerpage,
            start: (data.page - 1) * this.projectEndpointsPerpage,
            sort: data.sort,
          },
        };
        const res = await ProjectService.getEndpoints(params);
        this.projectEndpointsTotal = res.data.total;
        this.setGetProjectEndpoints(res.data);
        this.projectEndpointsDone = !this.projectEndpointsDone;
        this.projectEndpointsPending = false;
      } catch (error) {
        this.projectEndpointsPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectEndpoints(payload) {
      this.projectEndpoints = [];
      this.projectEndpoints = payload.data.map((s) => ({
        add: false,
        edit: false,
        key: s.id,
        id: s.id,
        sourceDir: s.matching_rule,
        endpoint: s.endpoint,
        environment: s.label,
      }));
    },

    // ## Add endpoints. -----------------------------------------------------------------------------
    async addProjectEndpoint(data) {
      this.addProjectEndpointPending = true;

      try {
        await ProjectService.addEndpoint(data);
        this.addProjectEndpointDone = !this.addProjectEndpointDone;
        this.addProjectEndpointPending = false;
      } catch (error) {
        this.addProjectEndpointFail = !this.addProjectEndpointFail;
        this.addProjectEndpointPending = false;
        this.addProjectEndpointFailMsg = ErrorHandling(error);
      }
    },

    // ## Delete endpoint. ---------------------------------------------------------------------------
    async deleteProjectEndpoint(data) {
      this.deleteProjectEndpointPending = true;

      try {
        await ProjectService.deleteEndpoint(data);
        this.deleteProjectEndpointDone = !this.deleteProjectEndpointDone;
        this.deleteProjectEndpointPending = false;
      } catch (error) {
        this.deleteProjectEndpointFail = !this.deleteProjectEndpointFail;
        this.deleteProjectEndpointPending = false;
        this.deleteProjectEndpointFailMsg = ErrorHandling(error);
      }
    },

    // ## Update endpoint. ---------------------------------------------------------------------------
    async updateProjectEndpoint(data) {
      this.updateProjectEndpointPending = true;

      try {
        await ProjectService.updateEndpoint(data);
        this.updateProjectEndpointDone = !this.updateProjectEndpointDone;
        this.updateProjectEndpointPending = false;
      } catch (error) {
        this.updateProjectEndpointFail = !this.updateProjectEndpointFail;
        this.updateProjectEndpointPending = false;
        this.updateProjectEndpointFailMsg = ErrorHandling(error);
      }
    },

    // ## Get project endpoint list. -----------------------------------------------------------------
    async getProjectEndpointsList(data) {
      this.projectEndpointsListPending = true;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.projectEndpointsListPerpage,
            start: (data.options.page - 1) * this.projectEndpointsListPerpage,
            sort_by: data.options.sort,
            endpoint: data.options.endpoint,
            severity: data.options.severity,
          },
        };
        const res = await ProjectService.getEndpointsList(params);
        this.projectEndpointsListTotal = res.data.total;
        this.setGetProjectEndpointsList(res.data);
        this.projectEndpointsListDone = !this.projectEndpointsListDone;
        this.projectEndpointsListPending = false;
      } catch (error) {
        this.projectEndpointsList = endpointDummyData;
        this.projectEndpointsListTotal = 0;
        this.projectEndpointsListFail = !this.projectEndpointsListFail;
        this.projectEndpointsListPending = false;
        this.projectEndpointsListFailMsg = ErrorHandling(error);
      }
    },

    setGetProjectEndpointsList(payload) {
      this.projectEndpointsList = [];
      if (!payload.data) {
        return;
      }
      this.projectEndpointsList = payload.data.map((s) => ({
        id: s.id,
        name: s.endpoint,
        summary: {
          critical: s.sast.findings.critical + s.dast.findings.critical + s.sca.findings.critical,
          high: s.sast.findings.high + s.dast.findings.high + s.sca.findings.high,
          medium: s.sast.findings.medium + s.dast.findings.medium + s.sca.findings.medium,
          low: s.sast.findings.low + s.dast.findings.low + s.sca.findings.low,
          total: s.sast.total + s.dast.total + s.sca.total,
        },
        projects: [
          {
            key: 1,
            sast: s.sast.projects,
            dast: s.dast.projects,
            sca: s.sca.projects,
          },
        ],
        children: [
          {
            name: 'sast',
            summary: {
              critical: s.sast.findings.critical,
              high: s.sast.findings.high,
              low: s.sast.findings.low,
              medium: s.sast.findings.medium,
              total: s.sast.total,
            },
          }, {
            name: 'dast',
            summary: {
              critical: s.dast.findings.critical,
              high: s.dast.findings.high,
              medium: s.dast.findings.medium,
              low: s.dast.findings.low,
              total: s.dast.total,
            },
          }, {
            name: 'sca',
            summary: {
              critical: s.sca.findings.critical,
              high: s.sca.findings.high,
              medium: s.sca.findings.medium,
              low: s.sca.findings.low,
              total: s.sca.total,
            },
          },
        ],
      }));
    },

    // ## Endpoint details. --------------------------------------------------------------------------
    async getProjectEndpointsDetail(data) {
      this.projectEndpointsDetailPending = true;

      try {
        const params = {
          id: data.id,
          endpoint: data.endpoint,
          query: {
            limit: this.projectEndpointsDetailPerpage,
            start: (data.page - 1) * this.projectEndpointsDetailPerpage,
          },
        };
        const res = await ProjectService.getProjectEndpointsDetail(params);
        this.projectEndpointsDetailTotal = res.data.total;
        this.setGetProjectEndpointsDetail(res.data);
        this.projectEndpointsDetailDone = !this.projectEndpointsDetailDone;
        this.projectEndpointsDetailPending = false;
      } catch (error) {
        this.projectEndpointsDetail = endpointsDetailDummyData;
        this.projectEndpointsDetailTotal = 0;
        this.projectEndpointsDetailPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectEndpointsDetail(payload) {
      this.projectEndpointsDetail.endpoint = payload.data.endpoint;
      this.projectEndpointsDetail.label = payload.data.label;
      this.projectEndpointsDetail.matchingRule = payload.data.matching_rule;
      if (payload.data.cwe_list < 1) {
        this.projectEndpointsDetail.cweList = [];
        return;
      }
      this.projectEndpointsDetail.table = [];
      Object.keys(payload.data.table).forEach((s) => {
        if (s !== 'sca') {
          this.projectEndpointsDetail.table.push({
            name: s,
            critical: payload.table[s].findings.critical,
            high: payload.table[s].findings.high,
            low: payload.table[s].findings.low,
            medium: payload.table[s].findings.medium,
            total: payload.table[s].total,
          });
        }
      });
      this.projectEndpointsDetail.cweList = payload.data.cwe_list;
    },

    // ## Get product of projects. -------------------------------------------------------------------
    async getProductOfProjects(data) {
      this.productOfProjectsPending = true;

      try {
        const res = await ProjectService.getProductOfProjects(data);
        this.productOfProjects = res.data.data;
        this.productOfProjectsDone = !this.productOfProjectsDone;
        this.productOfProjectsPending = false;
      } catch (error) {
        this.productOfProjectsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get assets management. -------------------------------------------------------------------
    async getAssetsManagement(data) {
      this.assetsManagementPending = true;

      try {
        const params = {
          type: data.type,
          query: {
            limit: data.perPage,
            start: (data.page - 1) * data.perPage,
            sort_by: data.sort,
            repo_tool: data.alm,
            tool: data.alm,
            issue_tool: data.issueManager,
            business_criticality: data.businessCriticality,
            clone_disabled: data.disableCloneOperation ? `${data.disableCloneOperation.split(':')[0]}:${data.disableCloneOperation.split(':')[1] === 'yes'}` : undefined,
            notification_tool: data.notification,
            labels: data.label,
            language: data.language,
            projects: data.project,
            scanner_tool: data.scanner,
            teams: data.team,
            products: data.product,
            q: data.name,
            forked: data.forked ? `${data.forked.split(':')[0]}:${data.forked.split(':')[1] === 'yes'}` : undefined,
            added: data.onboarded ? `${data.onboarded.split(':')[0]}:${data.onboarded.split(':')[1] === 'yes'}` : undefined,
            obsolete: data.obsolete ? `${data.obsolete.split(':')[0]}:${data.obsolete.split(':')[1] === 'yes'}` : undefined,
          },
        };
        const res = await ProjectService.getAssetsManagement(params);
        this.setGetAssetsManagement(res.data, data);
        this.assetsManagementDone = !this.assetsManagementDone;
        this.assetsManagementPending = false;
      } catch (error) {
        this.assetsManagement = managementDummyData;
        this.assetsManagementTotal = 0;
        this.assetsManagementFail = !this.assetsManagementFail;
        this.assetsManagementPending = false;
        this.assetsManagementFailMsg = ErrorHandling(error);
      }
    },

    setGetAssetsManagement(payload, data) {
      this.assetsManagement = [];
      if (data.type === 'kondukto') {
        this.assetsManagementTotal = payload.count;
        this.assetsManagement = payload.data.map((s) => ({
          id: s.id,
          name: s.name,
          team: s.team.name,
          products: s.products,
          scanners: s.scanners,
          tools: [
            {
              name: (s.tools.find((a) => a.type === 'issue') ? s.tools.find((a) => a.type === 'issue').name : 'none'),
              type: (s.tools.find((a) => a.type === 'issue') ? 'issueManager' : 'issueManager'),
            }, {
              name: (s.tools.find((a) => a.type === 'repo') ? s.tools.find((a) => a.type === 'repo').name : 'none'),
              type: (s.tools.find((a) => a.type === 'repo') ? 'alm' : 'alm'),
            }, {
              name: (s.tools.find((a) => a.type === 'notification') ? s.tools.find((a) => a.type === 'notification').name : 'none'),
              type: (s.tools.find((a) => a.type === 'notification') ? 'alerts' : 'alerts'),
            },
          ],
          labels: s.labels,
          languages: s.languages,
          hasRepo: s.has_repo,
          hasIssue: s.has_issue,
          hasIssueAssigne: s.has_specific_issue_responsible,
          hasIssueResponsible: s.has_team_issue_responsible,
          validationScan: s.validation_scan,
          hasNotification: s.has_notification,
        }));
      } else {
        this.assetsManagementTotal = payload.total;
        this.assetsManagement = payload.data.map((s) => ({
          id: s.id,
          name: s.name,
          owner: s.owner,
          slug: s.slug,
          tool: s.tool,
          url: s.url,
          added: s.added,
          fork: s.fork,
          exists: s.exists,
          languages: s.languages,
          project: s.project,
          updatedOn: dayjs(s.updated_on_alm).format('DD MMM YYYY'),
        }));
      }
    },

    // ## Get assets management metrics. -------------------------------------------------------------------
    async getAssetsManagementMetrics(data) {
      this.assetsManagementMetricsPending = true;

      try {
        const params = {
          type: data.type,
          query: {
            limit: data.perPage,
            start: (data.page - 1) * data.perPage,
            sort_by: data.sort,
            repo_tool: data.alm,
            tool: data.alm,
            issue_tool: data.issueManager,
            notification_tool: data.notification,
            business_criticality: data.businessCriticality,
            clone_disabled: data.disableCloneOperation ? `${data.disableCloneOperation.split(':')[0]}:${data.disableCloneOperation.split(':')[1] === 'yes'}` : undefined,
            labels: data.label,
            language: data.language,
            projects: data.project,
            scanner_tool: data.scanner,
            teams: data.team,
            products: data.product,
            q: data.name,
            forked: data.forked ? `${data.forked.split(':')[0]}:${data.forked.split(':')[1] === 'yes'}` : undefined,
            added: data.onboarded ? `${data.onboarded.split(':')[0]}:${data.onboarded.split(':')[1] === 'yes'}` : undefined,
            obsolete: data.obsolete ? `${data.obsolete.split(':')[0]}:${data.obsolete.split(':')[1] === 'yes'}` : undefined,
          },
        };
        const res = await ProjectService.getAssetsManagementMetrics(params);
        this.assetsManagementMetrics = res.data.data;
        this.assetsManagementMetricsDone = !this.assetsManagementMetricsDone;
        this.assetsManagementMetricsPending = false;
      } catch (error) {
        this.assetsManagementMetrics = managementDummyData;
        this.assetsManagementMetricsFail = !this.assetsManagementMetricsFail;
        this.assetsManagementMetricsPending = false;
        this.assetsManagementMetricsFailMsg = ErrorHandling(error);
      }
    },

    // ## Update assets management. ----------------------------------------------------------------
    async updateAssetsManagement(data) {
      this.updateAssetsManagementPending = true;

      try {
        await ProjectService.updateAssetsManagement(data);
        this.updateAssetsManagementDone = !this.updateAssetsManagementDone;
        this.updateAssetsManagementPending = false;
      } catch (error) {
        this.updateAssetsManagementFail = !this.updateAssetsManagementFail;
        this.updateAssetsManagementPending = false;
        this.updateAssetsManagementFailMsg = ErrorHandling(error);
      }
    },

    // ## Delete projects management. ----------------------------------------------------------------
    async deleteAssetsManagement(data) {
      this.deleteAssetsManagementPending = true;

      try {
        await ProjectService.updateAssetsManagement(data);
        this.deleteAssetsManagementDone = !this.deleteAssetsManagementDone;
        this.deleteAssetsManagementPending = false;
      } catch (error) {
        this.deleteAssetsManagementFail = !this.deleteAssetsManagementFail;
        this.deleteAssetsManagementPending = false;
        ErrorHandling(error);
      }
    },

    // ## Validate meta data. ------------------------------------------------------------------------
    async getValidateMetaData(data) {
      this.validateMetaDataPending = true;

      try {
        const res = await ProjectService.validateMetaData(data);
        this.validateMetaData = res.data.result;
        this.validateMetaDataDone = !this.validateMetaDataDone;
        this.validateMetaDataPending = false;
      } catch (error) {
        this.validateMetaDataPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get project dashboard filter. --------------------------------------------------------------
    getProjectDashboardFilter() {
      const storage = localStorage['kondukto-project-dashboard'];
      if (storage && JSON.parse(storage)) {
        this.projectDashboardFilter = JSON.parse(storage);
      }
    },

    // ## Save project dashboard filter. -------------------------------------------------------------
    saveProjectDashboardFilter(payload) {
      const storage = localStorage['kondukto-project-dashboard'];
      let data = this.projectDashboardFilter;
      if (storage) {
        if (!data[payload.type]) {
          data[payload.type] = {};
        }
        data[payload.type] = this.projectDashboardFilter[payload.type];
        data[payload.type][payload.prefix] = payload.data;
      } else {
        data = {
          [payload.type]: {
            [payload.prefix]: payload.data,
          },
        };
      }
      localStorage.setItem('kondukto-project-dashboard', JSON.stringify(data));
    },

    // ## Remove project dashboard filter. -----------------------------------------------------------
    removeProjectDashboardFilter() {
      const storage = localStorage['kondukto-project-dashboard'];
      if (storage && JSON.parse(storage)) {
        const data = JSON.parse(storage);
        const dataKeys = Object.keys(data);
        if (dataKeys.filter((s) => s === 'global').length > 0) {
          dataKeys.forEach((s) => {
            if (s !== 'global') {
              delete data[s];
            }
          });
          localStorage.setItem('kondukto-project-dashboard', JSON.stringify(data));
        } else {
          this.projectDashboardFilter = {};
          localStorage.removeItem('kondukto-project-dashboard');
        }
      } else {
        this.projectDashboardFilter = {};
        localStorage.removeItem('kondukto-project-dashboard');
      }
    },
  },
});

export default useProjectStore;
