import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  getProjectDetails(params) {
    return Api().get(`${API_URL.PROJECTS}/${params.id}`, params);
  },
  getProjectReleaseStatus(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/release_status`, { params });
  },
  createProject(params) {
    return Api().post(API_URL.PROJECTS, params);
  },
  updateProject(params) {
    return Api().patch(`${API_URL.PROJECTS}/${params.id}`, params);
  },
  getProjectList(data) {
    if (data.query) {
      const params = data.query;
      return Api().get(`${API_URL.PROJECTS}`, { params });
    }
    if (data !== '') {
      return Api().get(`${API_URL.PROJECTS}?name=rgx:${data}`);
    }
    return Api().get(`${API_URL.PROJECTS}`);
  },
  get(params) {
    return Api().get(API_URL.PROJECTS, { params });
  },
  getScans(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/${API_URL.SCANS}`, { params });
  },
  getProjectFailed(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/failed_scans`, { params });
  },
  getProjectSbomTable(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/${API_URL.SBOM}/scans`, { params });
  },
  getProjectSbomComponentTable(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/${API_URL.SBOM}/components`, { params });
  },
  exportProjectSbom(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/${API_URL.SBOM}/export`, { params });
  },
  getProjectAsvsChapters(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/asvs`, { params });
  },
  getProjectAsvsSections(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/asvs/chapters/${data.chapters}`, { params });
  },
  getProjectAsvsRequireds(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/asvs/sections/${data.sections}`, { params });
  },
  updateProjectAsvsSettings(data) {
    const params = data.query;
    return Api().post(`${API_URL.PROJECTS}/${data.id}/asvs`, params);
  },
  assignVulnsToIssue(data) {
    return Api().post(`${API_URL.PROJECTS}/${data.projectId}/${API_URL.VULNS}/issues`, data.params);
  },
  getVulns(data) {
    const params = data.query;
    if (data.prefix) {
      return Api().get(`${API_URL.PROJECTS}/${data.id}/${API_URL.VULNS}/${data.type}/${data.prefix}`, { params });
    }
    return Api().get(`${API_URL.PROJECTS}/${data.id}/${API_URL.VULNS}/${data.type}`, { params });
  },
  getProjectCloudFilter(data) {
    return Api().get(`${API_URL.PROJECTS}/${data.id}/cloud/filter`);
  },
  updateProjectCloudFilter(data) {
    const params = data.query;
    return Api().patch(`${API_URL.PROJECTS}/${data.id}/cloud/filter`, params);
  },
  getVulnsTotal(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/` +
      `${API_URL.VULNS}/${data.type}/count`, { params });
  },
  getProjectVulnsDynamicView(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/${API_URL.VULNS}` +
      `/${data.type}/dynamic_view`, { params });
  },
  importVulns(data) {
    const params = data.query;
    return Api().post(`${API_URL.PROJECTS}/${data.id}/vulns/upload`, params);
  },
  getVulnsTemplate(params) {
    return Api().get(`${API_URL.PROJECTS}/${params.id}/vulns/template?type=${params.type}`);
  },
  createProjectVulns(data) {
    const params = data.query;
    return Api().post(`${API_URL.PROJECTS}/${data.id}/vulns/import`, params);
  },
  validate(params) {
    return Api().get(`${API_URL.PROJECTS_VALIDATE}?name=${params.name}`);
  },
  remove(params) {
    return Api().delete(`${API_URL.PROJECTS}/${params.id}?confirm_text=${params.confirmText}`);
  },
  done(params) {
    return Api().get(`${API_URL.PROJECTS}/${params.id}/done`);
  },
  getFiles(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/files`, { params });
  },
  uploadFiles(params) {
    return Api().post(`${API_URL.PROJECTS}/${params.id}/files/upload`, params.data);
  },
  deleteFile(params) {
    return Api().delete(`${API_URL.PROJECTS}/${params.projectId}/files/${params.id}`);
  },
  downloadFile(params) {
    return Api().get(`${API_URL.PROJECTS}/` +
      `${params.projectId}/files/${params.id}/download`, { responseType: 'blob' });
  },
  getImages(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/images`, { params });
  },
  getImageDetails(params) {
    return Api().get(`${API_URL.PROJECTS}/${params.id}/images/details?image=${params.image}`);
  },
  getProjectBranches(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/branches`, { params });
  },
  getEndpoints(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/endpoints`, { params });
  },
  addEndpoint(params) {
    return Api().post(`${API_URL.PROJECTS}/${params.id}/endpoints`, params.data);
  },
  exportEndpoint(data) {
    const { id, ...params } = data;
    return Api().get(`${API_URL.PROJECTS}/${id}/endpoints/export`, { params });
  },
  deleteEndpoint(params) {
    return Api().delete(`${API_URL.PROJECTS}/${params.id}/endpoints/${params.endpointId}`);
  },
  updateEndpoint(params) {
    return Api().patch(`${API_URL.PROJECTS}/${params.id}/` +
      `endpoints/${params.endpointId}`, params.data);
  },
  getEndpointsList(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/endpoints/findings`, { params });
  },
  getProjectEndpointsDetail(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/endpoints/${data.endpoint}`, { params });
  },
  getProductOfProjects(params) {
    return Api().get(`${API_URL.PROJECTS}/${params}/products`);
  },
  getAssetsManagement(data) {
    const params = data.query;
    return Api().get(`assets_management/${data.type}/assets`, { params });
  },
  getAssetsManagementMetrics(data) {
    const params = data.query;
    return Api().get(`assets_management/${data.type}/assets/metrics`, { params });
  },
  updateAssetsManagement(params) {
    return Api().patch(`${API_URL.PROJECTS}/management`, params);
  },
  validateMetaData(data) {
    const params = {
      image: data.image,
      branch: data.branch,
      tool_id: data.toolId,
      manual: data.manual,
      scanparams_id: data.scanParamsId,
      meta: data.meta,
    };
    if (data.id) {
      return Api().get(`${API_URL.PROJECTS}/${data.id}/scanparams/validate`, { params });
    }
    return Api().get('infra_management/scan_params/validate', { params });
  },
};
