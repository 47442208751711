import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  getAll(data) {
    const params = data.query;
    if (data.id) {
      return Api().get(`${API_URL.SCANS}/${data.id}/${data.type}`, { params });
    }
    if (data.prefix) {
      return Api().get(`${API_URL.VULNS}/${data.type}/${data.prefix}`, { params });
    }
    return Api().get(`${API_URL.VULNS}/${data.type}`, { params });
  },
  getAllVulnsDynamicView(data) {
    const params = data.query;
    return Api().get(`${API_URL.VULNS}/${data.type}/dynamic_view`, { params });
  },
  getVulnsCount(data) {
    const params = data.query;
    if (data.type === 'infra') {
      return Api().get(`${API_URL.VULNS}/infra/count`, { params });
    }
    if (data.type === 'sbom') {
      if (data.prefix === 'project') {
        return Api().get(`/projects/${data.id}/sbom/components/count`, { params });
      }
      return Api().get('sbom/components/count', { params });
    }
    if (data.type === 'vulnerability' && data.prefix === 'project') {
      return Api().get(`/projects/${data.id}/vulns/count`, { params });
    }
    return Api().get(`${API_URL.VULNS}/count`, { params });
  },
  getVulnsTotal(data) {
    const params = data.query;
    return Api().get(`${API_URL.VULNS}/${data.type}/count`, { params });
  },
  getMasterVulnsTable(params) {
    return Api().get(`${API_URL.VULNS}/merge`, { params });
  },
  getDuplicatesVulnsTable(params) {
    return Api().get(`${API_URL.VULNS}/merge/${params}`);
  },
  getVulnDetails(params) {
    return Api().get(`${API_URL.VULNS}/${params.id}?tool_type=${params.type}`);
  },
  getVulnIssueDetails(params) {
    return Api().post(`${API_URL.VULNS}/issues`, params);
  },
  exportVulns(data) {
    const params = data.query;
    if (data.type === 'scan' && data.prefix === 'global') {
      return Api().post('/scans/export', params);
    }
    if (data.type === 'scan' && data.prefix === 'project') {
      return Api().post(`${API_URL.PROJECTS}/${data.id}/scans/export`, params);
    }
    if (data.type === 'scan' && data.prefix === 'product') {
      return Api().post(`${API_URL.PRODUCTS}/${data.id}/scans/export`, params);
    }
    if (data.type !== 'scan' && data.prefix === 'product') {
      return Api().post(`${API_URL.PRODUCTS}/${data.id}/vulns/${data.type}/export`, params);
    }
    if (data.type !== 'scan' && data.prefix === 'project') {
      return Api().post(`${API_URL.PROJECTS}/${data.id}/vulns/${data.type}/export`, params);
    }
    return Api().post(`${API_URL.VULNS}/${data.type}/${API_URL.EXPORT}`, params);
  },
  exportVulnsStatus() {
    return Api().get(`${API_URL.VULNS}/${API_URL.EXPORT}/status`);
  },
  downloadExportVulns(params) {
    return Api().get(`${API_URL.VULNS}/${API_URL.EXPORT}/${params}/download`);
  },
  patchVuln(params) {
    return Api().patch(`${API_URL.VULNS}/fp`, params);
  },
  vulnTruePositive(params) {
    return Api().patch(`${API_URL.VULNS}/tp`, params);
  },
  cancelVulnFalsePositive(params) {
    return Api().delete(`/fp_requests/${params}/cancel`);
  },
  vulnAssignIssue(data) {
    if (data.projectId) {
      return Api().post(`${API_URL.PROJECTS}/${data.projectId}/` +
        `${API_URL.VULNS}/issues`, data.params);
    }
    return Api().post(`${API_URL.VULNS}/issues/assign`, data.params);
  },
  getVulnSimilarIssue(params) {
    return Api().get(`${API_URL.VULNS}/${params.id}/similar_issues?package=${params.q}`);
  },
  vulnAssignSimilarIssue(data) {
    return Api().post(`${API_URL.VULNS}/${data.id}/copy_issue`, data.params);
  },
  vulnIssueSearch(params) {
    return Api().post(`${API_URL.VULNS}/issues/search`, params);
  },
  vulnIssueLink(params) {
    return Api().post(`${API_URL.VULNS}/issues/link_to`, params);
  },
  uploadScreenshot(params) {
    return Api().post(`${API_URL.VULNS}/${params.id}/screenshot`, params.data);
  },
  deleteScreenshot(params) {
    return Api().delete(`${API_URL.VULNS}/${params.id}/screenshot/${params.uuid}`);
  },
  vulnRiskAccepted(params) {
    return Api().patch(`${API_URL.VULNS}/ra`, params);
  },
  cancelVulnRiskAccepted(params) {
    return Api().delete(`/ra_requests/${params}/cancel`);
  },
  getVulnRiskAcceptedDetail(params) {
    return Api().get(`${API_URL.VULNS}/${params}/ra_request`);
  },
  approveVulnRiskAccepted(params) {
    return Api().patch(`/ra_requests/${params}/approve`, params);
  },
  rejectVulnRiskAccepted(data) {
    const params = data.query;
    return Api().patch(`/ra_requests/${data.id}/reject`, params);
  },
  updateVulns(params) {
    return Api().patch(`${API_URL.VULNS}/${params.id}`, params);
  },
  updateVulnExpiredDate(data) {
    const params = data.query;
    return Api().patch(`/${data.type}_requests/${data.id}/expiry_date`, params);
  },
  vulnClose(data) {
    const params = data.query;
    return Api().patch(`${API_URL.VULNS}/status`, params);
  },
  updateVulnsSeverity(params) {
    return Api().patch(`${API_URL.VULNS}/severity`, params);
  },
  updateVulnsCvss(params) {
    return Api().patch(`${API_URL.VULNS}/cvss`, params);
  },
  addVulnComment(params) {
    return Api().patch(`${API_URL.VULNS}/${params.id}/comments`, { params });
  },
  getVulnTrainingDetails(params) {
    return Api().get(`${API_URL.VULNS}/${params.id}/training/${params.tool}`);
  },
  getVulnRecommendationDetails(params) {
    return Api().get(`${API_URL.VULNS}/${params.id}/recommendation/${params.tool}`);
  },
  getVulnCollaboration(params) {
    return Api().get(`${API_URL.VULNS}/${params.id}/collaborations`);
  },
  getVulnManagerIssueInfo(params) {
    return Api().post(`${API_URL.VULNS}/issues/group`, params);
  },
};
