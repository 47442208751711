const PreComponent = '<router-view/>';

export default {
  path: 'vulnerabilities',
  name: 'vulnerabilities',
  component: { template: PreComponent },
  redirect: {
    name: 'all-appsec-vulns',
    query: {
      page: 1,
      perPage: 15,
    },
  },
  children: [
    {
      path: 'view',
      name: 'vulnerabilities-vivew',
      component: () => import('@/views/layouts/VulnerabilityLayout.vue'),
      redirect: {
        name: 'all-appsec-vulns',
        query: {
          page: 1,
          perPage: 15,
        },
      },
      children: [
        {
          path: 'appsec',
          name: 'all-appsec-vulns',
          component: () => import('@/views/pages/member/Vulns/AppSec.vue'),
          meta: {
            title: 'AppSec Vulnerabilities',
            activeMenuKeys: ['vulnerabilities', 'appsec'],
            allowedRole: [0, 1, 2, 3, 5, 10],
          },
        }, {
          path: 'appsec/:group1/:group2',
          name: 'all-appsec-dynamic-view',
          component: () => import('@/views/pages/member/Vulns/DynamicView.vue'),
          meta: {
            title: 'AppSec Vulnerabilities',
            activeMenuKeys: ['vulnerabilities', 'appsec'],
            allowedRole: [0, 1, 2, 3, 5, 10],
          },
        }, {
          path: 'cloud/:type',
          name: 'all-cloud-vulns',
          component: () => import('@/views/pages/member/Vulns/Cloud.vue'),
          meta: {
            title: 'Cloud Vulnerabilities',
            activeMenuKeys: ['vulnerabilities', 'cloud'],
            allowedRole: [0, 1, 2, 3, 5, 10],
          },
        }, {
          path: 'infra',
          name: 'all-infra-vulns',
          component: () => import('@/views/pages/member/Vulns/Infra.vue'),
          meta: {
            title: 'Infrastructure Vulnerabilities',
            activeMenuKeys: ['vulnerabilities', 'infra'],
            allowedRole: [0, 1, 2, 3, 5, 10],
          },
        }, {
          path: 'infra/:group1/:group2',
          name: 'all-infra-dynamic-view',
          component: () => import('@/views/pages/member/Vulns/DynamicView.vue'),
          meta: {
            title: 'Infrastructure Vulnerabilities',
            activeMenuKeys: ['vulnerabilities', 'appsec'],
            allowedRole: [0, 1, 2, 3, 5, 10],
          },
        }, {
          path: 'duplicates-appsec',
          name: 'duplicates-appsec-vulns',
          component: () => import('@/views/pages/member/Vulns/Duplicates.vue'),
          meta: {
            title: 'Duplicate AppSec Vulnerabilities',
            activeMenuKeys: ['vulnerabilities', 'appsec-duplicate'],
            allowedRole: [1, 2, 3, 5],
          },
        }, {
          path: 'duplicates-infra',
          name: 'duplicates-infra-vulns',
          component: () => import('@/views/pages/member/Vulns/Duplicates.vue'),
          meta: {
            title: 'Duplicate Infra Vulnerabilities',
            activeMenuKeys: ['vulnerabilities', 'infra-duplicate'],
            allowedRole: [1, 2, 3, 5],
          },
        },
      ],
    }, {
      path: 'committer-benchmark',
      name: 'committer-benchmark',
      component: () => import('@/views/pages/member/Vulns/CommitterBenchmark.vue'),
      meta: {
        title: 'Committer Benchmark',
        activeMenuKeys: ['committer-benchmark'],
        allowedRole: [1, 2, 3, 5],
      },
    }, {
      path: 'scan/:id/appsec',
      name: 'scan-appsec-vulns',
      component: () => import('@/views/pages/member/Vulns/ScanAppSec.vue'),
      meta: {
        title: 'Scan Vulnerabilities',
        activeMenuKeys: ['vulnerabilities'],
        allowedRole: [0, 1, 2, 3, 5, 10],
      },
      beforeEnter: (to) => {
        if (!to.query.page || !to.query.perPage) {
          return {
            name: 'scan-appsec-vulns',
            params: { id: to.params.id },
            query: { page: 1, perPage: 15 },
          };
        }
      },
    }, {
      path: 'scan/:id/infra',
      name: 'scan-infra-vulns',
      component: () => import('@/views/pages/member/Vulns/ScanInfra.vue'),
      meta: {
        title: 'Scan Vulnerabilities',
        activeMenuKeys: ['vulnerabilities'],
        allowedRole: [0, 1, 2, 3, 5, 10],
      },
      beforeEnter: (to) => {
        if (!to.query.page || !to.query.perPage) {
          return {
            name: 'scan-infra-vulns',
            params: { id: to.params.id },
            query: { page: 1, perPage: 15 },
          };
        }
      },
    },
  ],
};
