import { defineStore } from 'pinia';

// eslint-disable-next-line import/no-cycle
import AuthService from '@/services/Auth';

import ErrorHandling from '@/mixins/ErrorHandling';

import { LOCAL_STORAGE } from '@/config/';

import jwtDecode from 'jwt-decode';

const decodedJwt = localStorage[LOCAL_STORAGE.TOKEN_NAME] ?
  jwtDecode(localStorage[LOCAL_STORAGE.TOKEN_NAME]) : null;

const setAuthPermissions = (data) => {
  const par = {};
  data.forEach((s) => {
    par[s.resource] = s.scopes;
  });
  return par;
};

const useAuthStore = defineStore({
  id: 'Auth',
  state: () => ({
    authToken: localStorage[LOCAL_STORAGE.TOKEN_NAME],
    authUsername: decodedJwt ? decodedJwt.username : null,
    authType: decodedJwt ? decodedJwt.auth_type : null,
    authRole: decodedJwt ? decodedJwt.role : null,
    authUserId: decodedJwt ? decodedJwt.id : null,
    authExp: decodedJwt ? decodedJwt.exp : null,
    authDefault: decodedJwt ? decodedJwt.default_creds : null,
    isDemohub: decodedJwt ? decodedJwt.demo_hub === 'true' : null,
    isMandiant: decodedJwt ? decodedJwt.mandiant === 'true' : false,
    isCnapp: decodedJwt ? decodedJwt.cnapp === 'true' : false,
    isCxSast: decodedJwt ? decodedJwt.checkmarx_sast === 'true' : false,
    isCustomImageReqistry: decodedJwt ? decodedJwt.custom_image_registry === 'true' : false,
    authEmail: decodedJwt ? decodedJwt.email : null,
    tenantType: decodedJwt ? decodedJwt.tenant_type : null,
    linceseType: decodedJwt ? decodedJwt.license_type : null,
    businessType: decodedJwt ? decodedJwt.business_type : null,
    authPermissions: decodedJwt ? setAuthPermissions(decodedJwt.resources) : {},

    // ## Login state. -------------------------------------------------------------------------------
    authLoginPending: false,
    authLoginDone: false,
    authLoginFail: false,

    // ## Logout state. ------------------------------------------------------------------------------
    authLogoutPending: false,
    authLogoutDone: false,

    // ## Failed state. ------------------------------------------------------------------------------
    authFailed: false,

    requestTimeout: false,

    // ## OAuth login params. ------------------------------------------------------------------------
    oAuthLoginParams: null,
    oAuthLoginParamsPending: false,
    oAuthLoginParamsDone: false,
    oAuthLoginParamsFail: false,
    oAuthLoginParamsFailMsg: null,

    // ## OAuth login callback. ----------------------------------------------------------------------
    oAuthLoginCallbackPending: false,
    oAuthLoginCallbackDone: false,
    oAuthLoginCallbackFail: false,
    oAuthLoginCallbackFailMsg: null,

    pendingRequests: [],
  }),
  actions: {
    // ## Login request. -----------------------------------------------------------------------------
    async authLogin(data) {
      this.authLoginPending = true;

      try {
        const res = await AuthService.login(data);
        this.setAuthLogin(res.data);
        this.authLoginDone = !this.authLoginDone;
        this.authLoginPending = false;
      } catch (error) {
        this.authLoginPending = false;
        this.authLoginFail = !this.authLoginFail;
        ErrorHandling(error);
      }
    },

    setAuthLogin(payload) {
      if (payload) {
        localStorage[LOCAL_STORAGE.TOKEN_NAME] = payload.token;
        const decodedJwts = jwtDecode(payload.token);
        this.authToken = decodedJwts.token;
        this.authUsername = decodedJwts.username;
        this.authType = decodedJwts.auth_type;
        this.authRole = decodedJwts.role;
        this.authUserId = decodedJwts.id;
        this.authExp = decodedJwts.exp;
        this.authDefault = decodedJwts.default_creds;
        this.isDemohub = decodedJwts.demo_hub === 'true';
        this.isMandiant = decodedJwts.mandiant === 'true';
        this.isCnapp = decodedJwts.cnapp === 'true';
        this.isCxSast = decodedJwts.checkmarx_sast === 'true';
        this.isCustomImageReqistry = decodedJwts ?
          decodedJwts.custom_image_registry === 'true' : false;
        this.authEmail = decodedJwts.email;
        this.tenantType = decodedJwts.tenant_type;
        this.linceseType = decodedJwts.license_type;
        this.businessType = decodedJwts.business_type;
        this.authPermissions = setAuthPermissions(decodedJwts.resources);
      } else {
        localStorage.removeItem(LOCAL_STORAGE.TOKEN_NAME);
        this.authToken = null;
        this.authUsername = null;
        this.authRole = null;
        this.authUserId = null;
        this.authExp = null;
        this.isDemohub = false;
        this.isMandiant = false;
        this.isCnapp = false;
        this.isCustomImageReqistry = false;
        this.authEmail = null;
        this.tenantType = null;
        this.linceseType = null;
        this.businessType = null;
        this.isCxSast = null;
        this.authPermissions = {};
      }
    },

    isReadPermission(payload) {
      if (!this.authPermissions[payload]) {
        return false;
      }
      return this.authPermissions[payload].some((s) => s === 'read');
    },

    isWritePermission(payload) {
      if (!this.authPermissions[payload]) {
        return false;
      }
      return this.authPermissions[payload].some((s) => s === 'write');
    },

    isDeletePermission(payload) {
      if (!this.authPermissions[payload]) {
        return false;
      }
      return this.authPermissions[payload].some((s) => s === 'delete');
    },

    isExportPermission(payload) {
      if (!this.authPermissions[payload]) {
        return false;
      }
      return this.authPermissions[payload].some((s) => s === 'export');
    },

    isApprovePermission(payload) {
      if (!this.authPermissions[payload]) {
        return false;
      }
      return this.authPermissions[payload].some((s) => s === 'approve');
    },

    isRejectPermission(payload) {
      if (!this.authPermissions[payload]) {
        return false;
      }
      return this.authPermissions[payload].some((s) => s === 'reject');
    },

    isSyncPermission(payload) {
      if (!this.authPermissions[payload]) {
        return false;
      }
      return this.authPermissions[payload].some((s) => s === 'sync');
    },

    isAddPermission(payload) {
      if (!this.authPermissions[payload]) {
        return false;
      }
      return this.authPermissions[payload].some((s) => s === 'add');
    },

    // ## Logout request. ----------------------------------------------------------------------------
    async authLogout() {
      this.authLogoutPending = true;

      try {
        await AuthService.logout();
        this.setAuthLogin(null);
        this.authLogoutPending = false;
        this.authLogoutDone = !this.authLogoutDone;
      } catch (error) {
        this.authLogoutPending = false;
        ErrorHandling(error);
      }
    },

    // ## Auth Failed. -------------------------------------------------------------------------------
    authTimeout() {
      this.authFailed = !this.authFailed;
      this.setAuthLogin(null);
    },

    // ## Auth Failed. -------------------------------------------------------------------------------
    setRequestTimeout() {
      this.requestTimeout = !this.requestTimeout;
    },

    // ## Reset Auth default. ------------------------------------------------------------------------
    resetAuthDefatultCreds() {
      this.authDefault = null;
    },

    // ## Reset Auth default. ------------------------------------------------------------------------
    addPendingRequest(token) {
      this.pendingRequests.push(token);
    },

    clearPendingRequest() {
      this.pendingRequests = [];
    },

    cancelPendingRequest() {
      this.pendingRequests.forEach((s) => {
        s.abort();
      });
      this.clearPendingRequest();
    },

    // ## Get oAuth login params. --------------------------------------------------------------------
    async getOAuthLoginParams(data) {
      this.oAuthLoginParamsPending = true;

      try {
        const res = await AuthService.getOAuthLoginParams(data);
        this.setGetOAuthLoginParams(res.data);
      } catch (error) {
        this.oAuthLoginParamsPending = false;
        this.oAuthLoginParamsFail = !this.oAuthLoginParamsFail;
        this.oAuthLoginParamsFailMsg = ErrorHandling(error);
      }
    },

    setGetOAuthLoginParams(payload) {
      this.oAuthLoginParams = payload;
      this.oAuthLoginParamsPending = false;
      this.oAuthLoginParamsDone = !this.oAuthLoginParamsDone;
    },

    // ## OAuth login callback. ----------------------------------------------------------------------
    async oAuthLoginCallback(data) {
      this.oAuthLoginCallbackPending = true;

      try {
        const res = await AuthService.oAuthLoginCallback(data);
        this.setOAuthLoginCallback(res.data);
      } catch (error) {
        this.oAuthLoginCallbackPending = false;
        this.oAuthLoginCallbackFail = !this.oAuthLoginCallbackFail;
        this.oAuthLoginCallbackFailMsg = ErrorHandling(error);
      }
    },

    setOAuthLoginCallback(payload) {
      this.setAuthLogin(payload);
      this.oAuthLoginCallbackPending = false;
      this.oAuthLoginCallbackDone = !this.oAuthLoginCallbackDone;
    },
  },
});

export default useAuthStore;
