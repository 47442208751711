import useAuthStore from '@/store/auth';

export default {
  path: 'roles',
  name: 'roles',
  component: () => import('@/views/layouts/RoleLayouts.vue'),
  meta: {
    breadcrumbName: 'roles',
  },
  redirect: {
    name: 'role-list',
  },
  children: [
    {
      path: 'list',
      name: 'role-list',
      component: () => import('@/views/pages/member/Role/List.vue'),
      meta: {
        title: 'Roles',
        activeMenuKeys: ['roles'],
        allowedRole: [1, 5],
      },
      beforeEnter: (to) => {
        const store = useAuthStore();
        if (store.linceseType !== 'enterprise') {
          return {
            name: 'dashboard',
          };
        }
        if (!to.query.page) {
          return {
            name: 'role-list',
            query: { page: 1, perPage: 15 },
          };
        }
      },
    },
  ],
};
