import { defineStore } from 'pinia';

import ProductService from '@/services/Product';

import {
  PRODUCT, VULN, SCANS, ENDPOINTS, SBOM,
} from '@/config/';

import ErrorHandling from '@/mixins/ErrorHandling';

import dayjs from 'dayjs';

import {
  dummyData,
  vulnDummyData,
  productVulnsDynamicViewDummyData,
  scanDummyData,
  endpointDummyData,
  endpointsDetailDummyData,
  fileDummyData,
  dummySummary,
  dummyProductUserData,
} from './_dummyData';

import { sbomDummy, sbomDetailDummy } from '../settings/_sbomDummy';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const setMetaDataQuery = (option) => {
  if (option && option.indexOf('nbl') > -1) {
    return 'ne:';
  }
  if (option && option.indexOf('bl') > -1) {
    return 'eq:';
  }
  return option;
};

const vulnParams = (data) => {
  const params = {
    id: data.id,
    type: data.type,
    query: {
      limit: data.options.perPage,
      start: (data.options.page - 1) * data.options.perPage,
      sort_by: data.options.sort,
      group1: data.options.group1,
      group2: data.options.group2,
      asvs_level: data.options.businessCriticality,
      branch: data.options.branch,
      committer: data.options.committer,
      cluster: data.options.cluster,
      cvss: data.options.cvss,
      cvss_vector: data.options.cvssVector,
      cwe_name: data.options.cweName,
      cwe_no: data.options.cweId,
      cve: data.options.cveId,
      cloud_platform: data.options.platform,
      dns_name: data.options.dnsName,
      default_branch: data.options.defaultBranch,
      docker_image: data.options.dockerImage,
      display_fields: data.options.displayFields,
      due_date: data.options.dueDate,
      engagement: data.options.engagement,
      environment: data.options.environment,
      epss_percentile: data.options.epssPercentile,
      epss_percentage: data.options.epssPercentage,
      endpoint_id: data.options.endpointId,
      family: data.options.family,
      first_seen: data.options.firstSeenFrom,
      from: data.options.lastStatusUpdate,
      fp: data.options.falsePositive ? `${data.options.falsePositive.split(':')[0]}:${data.options.falsePositive.split(':')[1] === 'yes'}` : null,
      fp_mark_date: data.options.fpMarkDate,
      path: (data.options.filePath ? data.options.filePath : data.options.ip),
      fixable: data.options.fixable ? `${data.options.fixable.split(':')[0]}:${data.options.fixable.split(':')[1] === 'yes'}` : null,
      fixed_version: data.options.fixedVersion,
      fixed_version_exist: data.options.hasFix,
      has_comment: data.options.hasNote ? `${data.options.hasNote.split(':')[0]}:${data.options.hasNote.split(':')[1] === 'yes'}` : undefined,
      has_internet_exposure: data.options.hasInternetExposure,
      fqdn: data.options.fqdn,
      highlighted_vulns: data.options.highlightedVulnerabilities ? `${data.options.highlightedVulnerabilities.split(':')[0]}:${data.options.highlightedVulnerabilities.split(':')[1] === 'yes'}` : null,
      historical_status: data.options.historicalStatus,
      id: data.options.id,
      image: data.options.image,
      issue_assignee: data.options.issueAssignee,
      issue_closed_date: data.options.issueClosedDate,
      issue_created_date: data.options.issueCreatedDate,
      issue_id: data.options.issueId,
      is_master: data.options.isMaster ? `${data.options.isMaster.split(':')[0]}:${data.options.isMaster.split(':')[1] === 'yes'}` : undefined,
      issue_url: data.options.issueUrl,
      issue_status: data.options.issueStatus,
      issue_wip_date: data.options.issueWIPDate,
      infra_group_ids: data.options.infraGroupNames,
      known_exploit: data.options.knownExploit ? `${data.options.knownExploit.split(':')[0]}:${data.options.knownExploit.split(':')[1] === 'yes'}` : null,
      labels: data.options.label,
      label_ids: data.options.labelIds,
      last_seen: data.options.lastSeen,
      meta_data: setMetaDataQuery(data.options.metaData),
      manual: data.options.manual ? `${data.options.manual.split(':')[0]}:${data.options.manual.split(':')[1] === 'yes'}` : null,
      merge_status: data.options.mergeStatus,
      mit: data.options.mitigated ? `${data.options.mitigated.split(':')[0]}:${data.options.mitigated.split(':')[1] === 'yes'}` : null,
      nist: data.options.nist,
      name: data.options.vulnerabilityName,
      name_space: data.options.nameSpace,
      net_bios_name: data.options.netBiosName,
      observed_in_the_wild: data.options.observedInTheWild ? `${data.options.observedInTheWild.split(':')[0]}:${data.options.observedInTheWild.split(':')[1] === 'yes'}` : null,
      os: data.options.os,
      overdue: data.options.overdue ? `${data.options.overdue.split(':')[0]}:${data.options.overdue.split(':')[1] === 'yes'}` : null,
      owasp2017: data.options.owasp2017Top10,
      owasp2021: data.options.owasp2021Top10,
      region: data.options.region,
      port: data.options.port,
      purl: data.options.packageUrl,
      pci: data.options.pci,
      plugin_id: data.options.pluginId,
      plugin_name: data.options.pluginName,
      project_ids: data.options.projectIds,
      projects: data.options.project,
      protocol: data.options.protocol,
      ra: data.options.riskAccepted,
      risk_rating: data.options.riskRating,
      risk_factors: data.options.riskFactor,
      ra_mark_date: data.options.raMarkDate,
      service: data.options.service,
      severity: data.options.severity,
      status: data.options.status,
      team_ids: data.options.teamIds,
      teams: data.options.team,
      text: data.options.textSearch,
      to: data.options.firstSeenTo,
      tool_name: data.options.scanner,
      tool_type: data.options.scannerType,
      tp: data.options.truePositive ? `${data.options.truePositive.split(':')[0]}:${data.options.truePositive.split(':')[1] === 'yes'}` : undefined,
      user_vuln_status: data.options.userVulnStatus,
      was_zero_day: data.options.wasZeroDay ? `${data.options.wasZeroDay.split(':')[0]}:${data.options.wasZeroDay.split(':')[1] === 'yes'}` : undefined,
      workload_name: data.options.workloadName,
      workload_namespace: data.options.workloadNamespace,
      workload_kind: data.options.workloadKind,
      woe: data.options.woe,
      wf: data.options.wontfix ? `${data.options.wontfix.split(':')[0]}:${data.options.wontfix.split(':')[1] === 'yes'}` : null,
    },
  };
  return params;
};

const useProductStore = defineStore({
  id: 'Product',
  state: () => ({
    // ## Product details. ---------------------------------------------------------------------------
    productDetails: dummyData,
    productDetailsPending: false,
    productDetailsDone: false,

    // ## Add product. -------------------------------------------------------------------------------
    addProductData: null,
    addProductPending: false,
    addProductDone: false,

    // ## Update product. ----------------------------------------------------------------------------
    updateProductPending: false,
    updateProductDone: false,

    // ## Remove product. ----------------------------------------------------------------------------
    removeProductPending: false,
    removeProductDone: false,

    // ## All products. ------------------------------------------------------------------------------
    allProducts: dummyData,
    allProductsPerPage: PRODUCT.PERPAGE,
    allProductsTotal: 0,
    allProductsPageSize: 0,
    allProductsPending: false,
    allProductsDone: false,
    allProductsFail: false,
    allProductsFailMsg: null,

    // ## Products list. -----------------------------------------------------------------------------
    productsList: [],
    productsListPending: false,
    productsListDone: false,

    // ## Validate product name. ---------------------------------------------------------------------
    validateProductName: false,
    validateProductNamePending: false,
    validateProductNameDone: false,

    // ## Product vulns table. -----------------------------------------------------------------------
    productVulnsTable: vulnDummyData,
    productVulnsTableSettings: null,
    productVulnsTablePerpage: VULN.PERPAGE,
    productVulnsTableTotal: 0,
    productVulnsTablePending: false,
    productVulnsTableDone: false,
    productVulnsTableFail: false,
    productVulnsTableFailMsg: null,
    productVulnsDynamicViewItems: {},

    // ## Product vulns dynamic view. ------------------------------------------------------------------------
    productVulnsDynamicView: productVulnsDynamicViewDummyData,
    productVulnsDynamicViewTotal: 0,
    productVulnsDynamicViewPending: false,
    productVulnsDynamicViewDone: false,
    productVulnsDynamicViewFail: false,
    productVulnsDynamicViewFailMsg: null,

    // ## Product cloud vulns table. -----------------------------------------------------------------------
    productCloudVulnsTable: vulnDummyData,
    productCloudVulnsTableTotal: 0,
    productCloudVulnsTablePending: false,
    productCloudVulnsTableDone: false,
    productCloudVulnsTableFail: false,
    productCloudVulnsTableFailMsg: null,

    // ## Product scans table. -----------------------------------------------------------------------
    productScansTable: scanDummyData,
    productScansTablePerpage: SCANS.PERPAGE,
    productScansTableTotal: 0,
    productScansTablePending: false,
    productScansTableDone: false,
    productScansTableFail: false,
    productScansTableFailMsg: null,

    // ## Product endpoints list. --------------------------------------------------------------------
    productEndpointsList: endpointDummyData,
    productEndpointsListPerpage: ENDPOINTS.PERPAGE,
    productEndpointsListTotal: 0,
    productEndpointsListPending: false,
    productEndpointsListDone: false,
    productEndpointsListFail: false,
    productEndpointsListFailMsg: null,

    // ## Product endpoints detail. ------------------------------------------------------------------
    productEndpointsDetail: endpointsDetailDummyData,
    productEndpointsDetailPerpage: ENDPOINTS.PERPAGE,
    productEndpointsDetailTotal: 0,
    productEndpointsDetailPending: false,
    productEndpointsDetailDone: false,

    // ## Product files. -----------------------------------------------------------------------------
    productFiles: fileDummyData,
    productFilesPerpage: 10,
    productFilesTotal: 0,
    productFilesPending: false,
    productFilesDone: false,
    productFilesFail: false,
    productFilesFailMsg: null,

    // ## Product SBOM scans. ----------------------------------------------------------------------------
    productSbomTable: sbomDummy,
    productSbomTablePerpage: SBOM.PERPAGE,
    productSbomTableTotal: 0,
    productSbomTablePending: false,
    productSbomTableDone: false,
    productSbomTableFail: false,
    productSbomTableFailMsg: null,

    // ## Product SBOM compoennts. ----------------------------------------------------------------------------
    productSbomComponentTable: sbomDummy,
    productSbomComponentTablePerpage: SBOM.PERPAGE,
    productSbomComponentTableTotal: 0,
    productSbomComponentLastUpdatedDate: null,
    productSbomComponentNextScheduleDate: null,
    productSbomComponentTablePending: false,
    productSbomComponentTableDone: false,
    productSbomComponentTableFail: false,
    productSbomComponentTableFailMsg: null,

    // ## Project summary. ---------------------------------------------------------------------------
    productSummary: dummySummary,
    productSummaryPending: null,
    productSummaryDone: null,

    // ## Product endpoint coverage Metrics. -------------------------------------------------------
    productEndpointCoverageMetrics: {},
    productEndpointCoverageMetricsPending: null,
    productEndpointCoverageMetricsDone: null,

    // ## Product dashboard filter. -----------------------------------------------------------------
    productDashboardFilter: {},

    // ## Product users. ----------------------------------------------------------------------------
    productUsers: dummyProductUserData,
    productUsersPerpage: 15,
    productUsersTotal: 0,
    productUsersPending: false,
    productUsersDone: false,
    productUsersFail: false,
    productUsersFailMsg: null,
  }),
  actions: {
    // ## Get product. -------------------------------------------------------------------------------
    async getProductDetails(data) {
      this.productDetailsPending = true;

      try {
        const res = await ProductService.view(data);
        this.productDetails = res.data.data;
        this.productDetailsDone = !this.productDetailsDone;
        this.productDetailsPending = false;
      } catch (error) {
        this.productDetailsPending = false;
        ErrorHandling(error);
      }
    },

    resetProductDetails() {
      this.productDetails = null;
    },

    // ## Add product. -------------------------------------------------------------------------------
    async addProduct(data) {
      this.addProductPending = true;

      try {
        const res = await ProductService.post(data);
        this.addProductData = res.data.data;
        this.addProductDone = !this.addProductDone;
        this.addProductPending = false;
      } catch (error) {
        this.addProductPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update product. ----------------------------------------------------------------------------
    async updateProduct(data) {
      this.updateProductPending = true;

      try {
        await ProductService.update(data);
        this.updateProductDone = !this.updateProductDone;
        this.updateProductPending = false;
      } catch (error) {
        this.updateProductPending = false;
        ErrorHandling(error);
      }
    },

    // ## Remove product. ----------------------------------------------------------------------------
    async removeProduct(data) {
      this.removeProductPending = true;

      try {
        await ProductService.remove(data);
        this.removeProductDone = !this.removeProductDone;
        this.removeProductPending = false;
      } catch (error) {
        this.removeProductPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get all products. --------------------------------------------------------------------------
    async getAllProducts(data) {
      this.allProductsPending = true;

      try {
        const params = {
          limit: this.allProductsPerPage,
          start: (data.page - 1) * this.allProductsPerPage,
          sort_by: data.sort,
          q: data.product,
          request_usage: data.request_usage,
        };
        const res = await ProductService.get(params);
        this.allProductsTotal = res.data.total;
        this.allProductsPageSize = Math.ceil(this.allProductsTotal / this.allProductsPerPage);
        this.setGetAllProducts(res.data);
        this.allProductsDone = !this.allProductsDone;
        this.allProductsPending = false;
      } catch (error) {
        this.allProducts = dummyData;
        this.allProductsTotal = 0;
        this.allProductsFail = !this.allProductsFail;
        this.allProductsPending = false;
        this.allProductsFailMsg = ErrorHandling(error);
      }
    },

    setGetAllProducts(payload) {
      this.allProducts = [];
      if (payload.length < 1) {
        return;
      }
      this.allProducts = payload.data.map((s) => ({
        id: s.id,
        name: s.name,
        createdBy: s.created_by ? s.created_by : null,
        projectCount: s.projects_count,
        labels: s.labels,
        businessUnitTags: s.business_unit_tags,
        projects: s.projects.map((a) => ({
          id: a.id,
          name: a.name,
          createdAt: dayjs(a.created_at).format('DD MMM YYYY HH:mm'),
          summary: {
            critical: a.summary.critical,
            high: a.summary.high,
            medium: a.summary.medium,
            low: a.summary.low,
          },
          reachable: a.reachable,
          defaultBranch: a.default_branch,
          owner: a.owner,
        })),
      }));
    },

    // ## Get products list. -------------------------------------------------------------------------
    async getProductsList(data) {
      this.productsListPending = true;

      try {
        const res = await ProductService.getProductsList(data);
        this.productsList = res.data.data;
        this.productsListDone = !this.productsListDone;
        this.productsListPending = false;
      } catch (error) {
        this.productsListPending = false;
        ErrorHandling(error);
      }
    },

    // ## Validate product name. ---------------------------------------------------------------------
    async getValidateProductName(data) {
      this.validateProductNamePending = true;

      try {
        const res = await ProductService.validate(data);
        this.validateProductName = res.data.result;
        this.validateProductNameDone = !this.validateProductNameDone;
        this.validateProductNamePending = false;
      } catch (error) {
        this.validateProductNamePending = false;
        ErrorHandling(error);
      }
    },

    // ## Get product vulns. -------------------------------------------------------------------------
    async getProductVulnsTable(data) {
      this.productVulnsTablePending = true;

      try {
        const res = await ProductService.getVulns(vulnParams(data));
        this.setGetProductVulnsTable({
          result: res.data.data,
          data,
        });
        this.productVulnsTableSettings = res.data.custom_list_options;
        this.productVulnsTableDone = !this.productVulnsTableDone;
        this.productVulnsTablePending = false;
      } catch (error) {
        this.productVulnsTable = vulnDummyData;
        this.productVulnsTableFail = !this.productVulnsTableFail;
        this.productVulnsTablePending = false;
        this.productVulnsTableFailMsg = ErrorHandling(error);
      }
    },

    setGetProductVulnsTable(payload) {
      let data = [];
      let vulnsTableData = [];
      if (payload.vulns) {
        vulnsTableData = payload.result.vulns;
      } else {
        vulnsTableData = payload.result;
      }
      data = vulnsTableData.map((s) => ({
        key: s.id,
        id: s.id,
        branch: s.scanparams.branch,
        committer: s.committer_name,
        cvss: s.cvss_v3.toFixed(1),
        cweId: s.cwe.cwe_id,
        cweName: s.cwe.name,
        defaultBranch: s.scanparams.project.default_branch,
        dockerImage: (s.docker_image ? s.docker_image : '-'),
        dueDate: s.sla && dayjs(s.sla.due_date).format('DD MMM YYYY'),
        engagement: s.scanparams.engagement,
        environment: s.scanparams.environment,
        epss: s.ti.epss,
        falsePositive: s.is_fp,
        truePositive: s.is_tp,
        fpMarkDate: dayjs(s.fp_mark_date).format('DD MMM YYYY'),
        firstSeen: dayjs(s.first_seen).format('DD MMM YYYY'),
        hasComment: s.has_comment,
        hasFix: s.fixed_version_exist,
        fqdn: s.fqdn,
        manual: s.scanparams.manual,
        mergeStatus: s.merge_status,
        metaData: s.scanparams.meta_data,
        mitigated: s.is_mitigated,
        mscore: s.ti.m_score,
        name: s.name,
        observedInTheWild: s.ti.observedInTheWild,
        overdue: (s.sla ? s.sla : { overdue: false, rule: 0 }),
        owasp2017Top10: s.cwe.classification.owasp_2017.id ?
          s.cwe.classification.owasp_2017.name.replace('_', ' - ').replaceAll('_', ' ') : '-',
        owasp2021Top10: s.cwe.classification.owasp_2021.id ?
          s.cwe.classification.owasp_2021.name.replace('_', ' - ').replaceAll('_', ' ') : '-',
        knownExploit: s.ti.known_exploit,
        issueAssigned: s.issue_assigned,
        issueClosedDate: dayjs(s.issue_closed_date).format('DD MMM YYYY'),
        issueCreatedDate: dayjs(s.issue_created_date).format('DD MMM YYYY'),
        isMaster: s.is_master,
        issueStatus: s.issue_status,
        issueLink: s.issue_link,
        issueWIPDate: dayjs(s.issue_wip_date).format('DD MMM YYYY'),
        infraGroupNames: s.infra_group_names,
        labels: (s.scanparams.project.labels ? s.scanparams.project.labels.map((a) => ({
          id: a.id,
          name: a.name,
          color: `#${a.color}`,
        })) : []),
        lastSeen: dayjs(s.last_seen).format('DD MMM YYYY'),
        path: (s.path ? s.path : '-'),
        ip: (s.path ? s.path : '-'),
        pci: s.cwe.classification.pci_dss.id ?
          s.cwe.classification.pci_dss.name.replace('_', ' - ').replaceAll('_', ' ') : '-',
        project: s.scanparams.project.name,
        projectId: s.scanparams.project.id,
        raMarkDate: dayjs(s.ra_mark_date).format('DD MMM YYYY'),
        scanParams: s.scanparams,
        scanner: s.scanparams.tool.name,
        prefix: s.scanparams.tool.is_custom ? 'custom' : 'internal',
        scannerType: s.scanparams.tool.type,
        severity: s.severity,
        status: s.status,
        team: s.scanparams.project.owner.team.name,
        wasZeroDay: s.was_zero_day,
        woe: s.woe,
        wontfix: s.is_wf,
      }));
      if (payload.data.itemId || payload.data.itemId === '') {
        this.productVulnsDynamicViewItems[payload.data.itemId] = data;
      } else {
        this.productVulnsTable = [];
        this.productVulnsTable = data;
      }
    },

    // ## Get product vulns. -------------------------------------------------------------------------
    async getProductVulnsTableTotal(data) {
      try {
        const res = await ProductService.getVulnsTotal(vulnParams(data));
        this.productVulnsTableTotal = res.data.total;
      } catch (error) {
        this.productVulnsTableTotal = 0;
        ErrorHandling(error);
      }
    },

    // ## Get product vulns dynamic view. --------------------------------------------------------------------
    async getProductVulnsDynamicView(data) {
      this.productVulnsDynamicViewPending = true;
      this.productVulnsDynamicView = productVulnsDynamicViewDummyData;

      try {
        const res = await ProductService.getProductVulnsDynamicView(vulnParams(data));
        this.productVulnsDynamicViewTotal = res.data.total;
        this.productVulnsTableSettings = res.data.custom_list_options;
        this.setGetProductVulnsDynamicView(res.data.data);
        this.productVulnsDynamicViewDone = !this.productVulnsDynamicViewDone;
        this.productVulnsDynamicViewPending = false;
      } catch (error) {
        this.productVulnsDynamicView = productVulnsDynamicViewDummyData;
        this.productVulnsDynamicViewTotal = 0;
        this.productVulnsDynamicViewFail = !this.productVulnsDynamicViewFail;
        this.productVulnsDynamicViewPending = false;
        this.productVulnsDynamicViewFailMsg = ErrorHandling(error);
      }
    },

    setGetProductVulnsDynamicView(payload) {
      this.productVulnsDynamicView = [];
      this.productVulnsDynamicView = payload.map((s) => ({
        id: btoa(s.name + s.id),
        name: s.name,
        count: s.count,
        nodeCount: s.node_count,
        maxSeverity: s.max_severity,
        summary: s.summary,
        tool: {
          isCustom: s.tool.is_custom,
          manual: s.tool.is_manual,
        },
        vulns: s.vulns.map((a) => ({
          id: btoa(a.id + a.name),
          name: a.name,
          cweId: a.cwe_id,
          count: a.count,
          maxSeverity: a.max_severity,
          summary: a.summary,
          tool: {
            isCustom: s.tool.is_custom,
            manual: s.tool.is_manual,
          },
        })),
      }));
    },

    // ## Get product cloud vulns. -------------------------------------------------------------------------
    async getProductCloudVulnsTable(data) {
      this.productCloudVulnsTablePending = true;
      this.productCloudVulnsTable = vulnDummyData;

      try {
        const params = {
          id: data.id,
          type: data.type,
          prefix: data.prefix,
          query: {
            limit: data.options.perPage,
            start: (data.options.page - 1) * data.options.perPage,
            sort_by: data.options.sort,
            has_cisa_kev_exploit: data.options.cisaKevExploit ? `${data.options.cisaKevExploit.split(':')[0]}:${data.options.cisaKevExploit.split(':')[1] === 'yes'}` : null,
            cloud_platform: data.options.platform,
            is_closed: data.options.closed ? `${data.options.closed.split(':')[0]}:${data.options.closed.split(':')[1] === 'yes'}` : null,
            has_exploit: data.options.exploit ? `${data.options.exploit.split(':')[0]}:${data.options.exploit.split(':')[1] === 'yes'}` : null,
            image_name: data.options.imageName,
            name: data.options.name,
            region: data.options.region,
            severity: data.options.severity,
            open_to_internet: data.options.openToInternet ? `${data.options.openToInternet.split(':')[0]}:${data.options.openToInternet.split(':')[1] === 'yes'}` : null,
            operating_system: data.options.os,
            ip_addresses: data.options.ipAddresses,
            project: data.options.project,
          },
        };
        const res = await ProductService.getVulns(params);
        this.setGetProductCloudtVulnsTable(res.data);
        this.productCloudVulnsTableTotal = res.data.total;
        this.productCloudVulnsTableDone = !this.productCloudVulnsTableDone;
        this.productCloudVulnsTablePending = false;
      } catch (error) {
        this.productCloudVulnsTable = vulnDummyData;
        this.productCloudVulnsTablePending = false;
        this.productCloudVulnsTableFail = !this.productCloudVulnsTableFail;
        this.productCloudVulnsTableFailMsg = ErrorHandling(error);
      }
    },

    setGetProductCloudtVulnsTable(payload) {
      this.productCloudVulnsTable = [];

      if (!payload.cloud_vulnerabilities) {
        return;
      }

      this.productCloudVulnsTable = payload.cloud_vulnerabilities.map((s) => ({
        key: s.id,
        id: s.id,
        cloudId: s.vulnerability.cloud_id,
        name: s.vulnerability.name,
        locationPath: s.vulnerability.location_path,
        imageId: s.vulnerability.image_id,
        imageName: s.vulnerability.image_name,
        status: s.vulnerability.status,
        cveId: s.vulnerability.cve,
        severity: s.vulnerability.severity,
        currentVersion: s.vulnerability.current_version,
        fixedVersion: s.vulnerability.fixed_version,
        remediation: s.vulnerability.remediation,
        hasExploit: s.vulnerability.has_exploit,
        hasCisaKevExploit: s.vulnerability.has_cisa_kev_exploit,
        cveLink: s.vulnerability.cve_link,
        cloudToolUrl: s.vulnerability.cloud_tool_url,
        detectionMethod: s.vulnerability.detection_method,
        assetId: s.vulnerability.asset_id,
        assetName: s.vulnerability.asset_name,
        platform: s.vulnerability.cloud_platform,
        region: s.vulnerability.region,
        subscriptionId: s.vulnerability.subscription_id,
        firstDetected: dayjs(s.vulnerability.first_detected).format('DD MMM YYYY'),
        lastDetected: dayjs(s.vulnerability.last_detected).format('DD MMM YYYY'),
        os: s.vulnerability.operating_system,
        openToInternet: s.vulnerability.open_to_internet,
        ipAddresses: s.vulnerability.ip_addresses,
        projects: s.projects,
      }));
    },

    // ## Get product scans. -------------------------------------------------------------------------
    async getProductScansTable(data) {
      this.productScansTablePending = true;
      this.productScansTable = scanDummyData;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.productScansTablePerpage,
            start: (data.page - 1) * this.productScansTablePerpage,
            sort_by: data.sort,
            analyzing: data.analyzing ? `${data.analyzing.split(':')[0]}:${data.analyzing.split(':')[1] === 'completed'}` : null,
            tool_name: data.scanner,
            branch: data.branch,
            engagement: data.engagement,
            id: data.scanId,
            manual: data.manual,
            discoveredBy: data.discoveredBy,
            meta_data: setMetaDataQuery(data.metaData),
            imported_by: data.importedBy,
            scan_type: data.scanType,
          },
        };
        const res = await ProductService.getScans(params);
        this.productScansTableTotal = res.data.total;
        this.setGetProductScansTable(res.data);
        this.productScansTableDone = !this.productScansTableDone;
        this.productScansTablePending = false;
      } catch (error) {
        this.productScansTable = scanDummyData;
        this.productScansTableTotal = 0;
        this.productScansTableFail = !this.productScansTableFail;
        this.productScansTablePending = false;
        this.productScansTableFailMsg = ErrorHandling(error);
      }
    },

    setGetProductScansTable(payload) {
      this.productScansTable = [];
      if (payload.length < 1) {
        return;
      }
      this.productScansTable = payload.data.map((s) => ({
        key: s.id,
        id: s.id,
        branch: s.scanparams.branch ? s.scanparams.branch : '-',
        metaData: s.scanparams.meta_data,
        scanDate: dayjs(s.scan_date).format('DD MMM YYYY HH:mm'),
        scanner: s.scanparams.tool.name,
        prefix: s.scanparams.tool.is_custom ? 'custom' : 'internal',
        toolType: s.scanparams.tool.type,
        toolDisabled: s.scanparams.tool.disabled,
        scanType: s.scan_type ? s.scan_type.split(',') : null,
        project: s.scanparams.project.name,
        projectId: s.scanparams.project.id,
        scanParams: s.scanparams,
        forkScan: s.scanparams.fork_scan,
        status: 'completed',
        manual: s.scanparams.manual,
        discoveredBy: (s.import_info ? s.import_info.discovered_by : null),
        importedBy:
          (s.import_info && s.import_info.imported_by ? s.import_info.imported_by.username : null),
        dateDiscovered:
          (s.import_info ?
            dayjs(s.import_info.date_discovered).format('DD MMM YYYY HH:mm') : null),
        uploadedDate:
          (s.import_info ? dayjs(s.import_info.uploaded_at).format('DD MMM YYYY HH:mm') : null),
        duration: !s.duration ? '-' : dayjs.unix(s.duration / 1000).utc().format('HH:mm:ss'),
        critical: s.summary.critical ? s.summary.critical : 0,
        high: s.summary.high ? s.summary.high : 0,
        medium: s.summary.medium ? s.summary.medium : 0,
        low: s.summary.low ? s.summary.low : 0,
        info: s.summary.info ? s.summary.info : 0,
        action: s.id,
      }));
    },

    // ## Get product endpoint list. -----------------------------------------------------------------
    async getProductEndpointsList(data) {
      this.productEndpointsListPending = true;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.productEndpointsListPerpage,
            start: (data.options.page - 1) * this.productEndpointsListPerpage,
            sort_by: data.options.sort,
            endpoint: data.options.endpoint,
            severity: data.options.severity,
          },
        };
        const res = await ProductService.getEndpointsList(params);
        this.productEndpointsListTotal = res.data.total;
        this.setGetProductEndpointsList(res.data);
        this.productEndpointsListDone = !this.productEndpointsListDone;
        this.productEndpointsListPending = false;
      } catch (error) {
        this.productEndpointsList = endpointDummyData;
        this.productEndpointsListTotal = 0;
        this.productEndpointsListFail = !this.productEndpointsListFail;
        this.productEndpointsListPending = false;
        this.productEndpointsListFailMsg = ErrorHandling(error);
      }
    },

    setGetProductEndpointsList(payload) {
      this.productEndpointsList = [];
      if (!payload) {
        return;
      }
      this.productEndpointsList = payload.data.map((s) => ({
        id: s.id,
        name: s.endpoint,
        summary: {
          critical: s.sast.findings.critical + s.dast.findings.critical + s.sca.findings.critical,
          high: s.sast.findings.high + s.dast.findings.high + s.sca.findings.high,
          medium: s.sast.findings.medium + s.dast.findings.medium + s.sca.findings.medium,
          low: s.sast.findings.low + s.dast.findings.low + s.sca.findings.low,
          total: s.sast.total + s.dast.total + s.sca.total,
        },
        projects: [
          {
            key: 1,
            sast: s.sast.projects,
            dast: s.dast.projects,
            sca: s.sca.projects,
          },
        ],
        children: [
          {
            name: 'sast',
            summary: {
              critical: s.sast.findings.critical,
              high: s.sast.findings.high,
              low: s.sast.findings.low,
              medium: s.sast.findings.medium,
              total: s.sast.total,
            },
          }, {
            name: 'dast',
            summary: {
              critical: s.dast.findings.critical,
              high: s.dast.findings.high,
              medium: s.dast.findings.medium,
              low: s.dast.findings.low,
              total: s.dast.total,
            },
          }, {
            name: 'sca',
            summary: {
              critical: s.sca.findings.critical,
              high: s.sca.findings.high,
              medium: s.sca.findings.medium,
              low: s.sca.findings.low,
              total: s.sca.total,
            },
          },
        ],
      }));
    },

    // ## Product endpoint details. ------------------------------------------------------------------
    async getProductEndpointsDetail(data) {
      this.productEndpointsDetailPending = true;

      try {
        const params = {
          id: data.id,
          endpoint: data.endpoint,
          query: {
            limit: this.productEndpointsDetailPerpage,
            start: (data.page - 1) * this.productEndpointsDetailPerpage,
          },
        };
        const res = await ProductService.getProductEndpointsDetail(params);
        this.productEndpointsDetailTotal = res.data.total;
        this.setGetProductEndpointsDetail(res.data);
        this.productEndpointsDetailDone = !this.productEndpointsDetailDone;
        this.productEndpointsDetailPending = false;
      } catch (error) {
        this.productEndpointsDetail = endpointsDetailDummyData;
        this.productEndpointsDetailTotal = 0;
        this.productEndpointsDetailPending = false;
        ErrorHandling(error);
      }
    },

    setGetProductEndpointsDetail(payload) {
      this.productEndpointsDetail.endpoint = payload.data.endpoint;
      this.productEndpointsDetail.label = payload.data.label;
      this.productEndpointsDetail.matchingRule = payload.data.matching_rule;
      if (payload.cwe_list < 1) {
        this.productEndpointsDetail.cweList = [];
        return;
      }
      this.productEndpointsDetail.table = [];
      Object.keys(payload.data.table).forEach((s) => {
        if (s !== 'sca') {
          this.productEndpointsDetail.table.push({
            name: s,
            critical: payload.table[s].findings.critical,
            high: payload.table[s].findings.high,
            low: payload.table[s].findings.low,
            medium: payload.table[s].findings.medium,
            total: payload.table[s].total,
          });
        }
      });
      this.productEndpointsDetail.cweList = payload.data.cwe_list;
    },

    // ## Get product files. -------------------------------------------------------------------------
    async getProductFiles(data) {
      this.productFilesPending = true;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.productFilesPerpage,
            start: (data.page - 1) * this.productFilesPerpage,
            sort_by: data.sort,
          },
        };
        const res = await ProductService.getProductFiles(params);
        this.productFilesTotal = res.data.total;
        this.setGetProductFiles(res.data);
        this.productFilesDone = !this.productFilesDone;
        this.productFilesPending = false;
      } catch (error) {
        this.productFiles = fileDummyData;
        this.productFilesTotal = 0;
        this.productFilesFail = !this.productFilesFail;
        this.productFilesPending = false;
        this.productFilesFailMsg = ErrorHandling(error);
      }
    },

    setGetProductFiles(payload) {
      // byteToSize returns file size with size type. For example '100 KB' or '4 MB'
      // eslint-disable-next-line func-names
      const byteToSize = function (bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        // eslint-disable-next-line radix
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        // eslint-disable-next-line no-restricted-properties, prefer-exponentiation-operator
        return `${Math.round(bytes / Math.pow(1024, i), 2)}${sizes[i]}`;
      };
      this.productFiles = [];
      if (!payload) {
        return;
      }
      this.productFiles = payload.data.map((s) => ({
        id: s.id,
        key: s.id,
        fileName: s.name,
        fileType: s.extension,
        fileSize: byteToSize(s.size),
        uploadDate: dayjs(s.upload_date).format('DD MMM YYYY HH:mm'),
        mimeType: s.mime_type,
        projectId: s.project.id,
        projectName: s.project.name,
      }));
    },

    // ## Get product sbom table. --------------------------------------------------------------------
    async getProductSbomTable(data) {
      this.productSbomTablePending = true;
      this.productSbomTable = sbomDummy;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.productSbomTablePerpage,
            start: (data.options.page - 1) * this.productSbomTablePerpage,
            sort_by: data.options.sort,
            branch: data.options.branch,
            license: data.options.license,
            project_id: data.options.projects,
            purl: data.options.packageUrl,
            scan_type: data.options.sbomSource,
            vulnerable: data.options.vulnerable ? `${data.options.vulnerable.split(':')[0]}:${data.options.vulnerable.split(':')[1] === 'yes'}` : null,
          },
        };
        const res = await ProductService.getProductSbomTable(params);
        this.productSbomTableTotal = res.data.total;
        this.setGetProductSbomTable(res.data);
        this.productSbomTableDone = !this.productSbomTableDone;
        this.productSbomTablePending = false;
      } catch (error) {
        this.productSbomTable = sbomDummy;
        this.productSbomTableTotal = 0;
        this.productSbomTableFail = !this.productSbomTableFail;
        this.productSbomTablePending = false;
        this.productSbomTableFailMsg = ErrorHandling(error);
      }
    },

    setGetProductSbomTable(payload) {
      this.productSbomTable = [];
      if (!payload) {
        return;
      }
      this.productSbomTable = payload.data.map((s, i) => ({
        key: i,
        id: i,
        lastUpdatedDate: dayjs(s.updated_at).format('DD MMM YYYY HH:mm'),
        sbomFormat: s.sbom_format,
        branch: s.project.branch,
        project: s.project.name,
        scanType: s.scan_type,
        inspectorTool: s.inspector,
        vulnerableComponents: s.vulnerable_component_count,
        totalComponents: s.component_count,
        projectId: s.project.id,
      }));
    },

    // ## Get product sbom table. --------------------------------------------------------------------
    async getProductSbomComponentTable(data) {
      this.productSbomComponentTablePending = true;
      this.productSbomComponentTable = sbomDetailDummy;
      try {
        const params = {
          id: data.id,
          query: {
            limit: this.productSbomComponentTablePerpage,
            start: (data.options.page - 1) * this.productSbomComponentTablePerpage,
            sort_by: data.options.sort,
            branch: data.options.branch,
            license: data.options.license,
            project_id: data.options.projects,
            risk_factor: data.options.licenseRisk,
            purl: data.options.packageUrl,
            severity: data.options.severity,
            name: data.options.name,
            vulnerable: data.options.vulnerable ? `${data.options.vulnerable.split(':')[0]}:${data.options.vulnerable.split(':')[1] === 'yes'}` : null,
          },
        };
        const res = await ProductService.getProductSbomComponentTable(params);
        this.productSbomComponentTableTotal = res.data.total;
        this.productSbomComponentLastUpdatedDate = dayjs(res.data.last_updated_date).format('DD MMM YYYY HH:mm');
        this.productSbomComponentNextScheduleDate = dayjs(res.data.next_schedule_date).format('DD MMM YYYY HH:mm');
        this.setGetProductSbomComponentTable(res.data.data);
        this.productSbomComponentTableDone = !this.productSbomComponentTableDone;
        this.productSbomComponentTablePending = false;
      } catch (error) {
        this.productSbomComponentTable = sbomDetailDummy;
        this.productSbomComponentTableTotal = 0;
        this.productSbomComponentTableFail = !this.productSbomComponentTableFail;
        this.productSbomComponentTablePending = false;
        this.productSbomComponentTableFailMsg = ErrorHandling(error);
      }
    },

    setGetProductSbomComponentTable(payload) {
      this.productSbomComponentTable = [];
      if (!payload) {
        return;
      }
      this.productSbomComponentTable = payload.map((s, i) => ({
        key: i,
        id: i,
        componentName: s.component_name,
        componentVersion: s.component_version,
        componentType: s.component_type,
        projects: s.projects ? s.projects : [],
        branch: s.branches ? s.branches : [],
        licenses: s.licenses ? s.licenses.map((a) => (a.id === '' ? a.name : a.id)) : [],
        packageUrl: s.purl,
        licenseRisk: s.highest_license_risk_factor.severity,
        vulnerabilities: s.vulnerabilities,
        critical: s.summary.critical ? s.summary.critical : 0,
        high: s.summary.high ? s.summary.high : 0,
        medium: s.summary.medium ? s.summary.medium : 0,
        low: s.summary.low ? s.summary.low : 0,
        info: s.summary.info ? s.summary.info : 0,
      }));
    },

    // ## Get project summary. -----------------------------------------------------------------------
    async getProductSummary(data) {
      this.productSummaryPending = true;

      try {
        const res = await ProductService.getSummary(data);
        this.productSummary = res.data.chart_data;
        this.productSummaryDone = !this.productSummaryDone;
        this.productSummaryPending = false;
      } catch (error) {
        this.productSummaryPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get product endpoint coverage Metrics. ---------------------------------------------------
    async getProductEndpointCoverageMetrics(data) {
      this.productEndpointCoverageMetricsPending = true;

      try {
        const res = await ProductService.getProductEndpointCoverageMetrics(data);
        this.productEndpointCoverageMetrics = res.data.chart_data;
        this.productEndpointCoverageMetricsDone = !this.productEndpointCoverageMetricsDone;
        this.productEndpointCoverageMetricsPending = false;
      } catch (error) {
        this.productEndpointCoverageMetricsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get product dashboard filter. -------------------------------------------------------------
    getProductDashboardFilter() {
      const storage = localStorage['kondukto-product-dashboard'];
      if (storage && JSON.parse(storage)) {
        this.productDashboardFilter = JSON.parse(storage);
      }
    },

    // ## Save product dashboard filter. -------------------------------------------------------------
    saveProductDashboardFilter(payload) {
      const storage = localStorage['kondukto-product-dashboard'];
      let data = {};
      if (storage) {
        data = JSON.parse(storage);
        if (!data[payload.type]) {
          data[payload.type] = {};
        }
        data[payload.type][payload.prefix] = payload.data;
      } else {
        data = {
          [payload.type]: {
            [payload.prefix]: payload.data,
          },
        };
      }
      localStorage.setItem('kondukto-product-dashboard', JSON.stringify(data));
    },

    // ## Remove product dashboard filter. -----------------------------------------------------------
    removeProductDashboardFilter() {
      const storage = localStorage['kondukto-product-dashboard'];
      if (storage && JSON.parse(storage)) {
        const data = JSON.parse(storage);
        const dataKeys = Object.keys(data);
        if (dataKeys.filter((s) => s === 'global').length > 0) {
          dataKeys.forEach((s) => {
            if (s !== 'global') {
              delete data[s];
            }
          });
          localStorage.setItem('kondukto-product-dashboard', JSON.stringify(data));
        } else {
          this.productDashboardFilter = {};
          localStorage.removeItem('kondukto-product-dashboard');
        }
      } else {
        this.productDashboardFilter = {};
        localStorage.removeItem('kondukto-product-dashboard');
      }
    },

    // ## Get product users. ---------------------------------------------------
    async getProductUsers(data) {
      this.productUsersPending = true;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.productUsersPerpage,
            start: (data.options.page - 1) * this.productUsersPerpage,
            sort_by: data.options.sort,
            q: data.options.q,
          },
        };
        const res = await ProductService.getProductUsers(params);
        this.setProductUsers(res.data.result);
        this.productUsersTotal = res.data.total;
        this.productUsersDone = !this.productUsersDone;
        this.productUsersPending = false;
      } catch (error) {
        this.productUsersPending = false;
        ErrorHandling(error);
      }
    },

    setProductUsers(payload) {
      if (!payload) {
        return;
      }
      this.productUsers = payload.map((s) => ({
        id: s.id,
        username: s.username,
        email: s.email,
        accessType: s.access_type.replaceAll('_', ''),
        excluded: s.excluded,
        role: s.role_name,
        roleType: s.role_type,
        privilege: s.role,
      }));
    },
  },
});

export default useProductStore;
