import { defineStore } from 'pinia';

import FilterService from '@/services/Filter';

import ErrorHandling from '@/mixins/ErrorHandling';

import { PROJECT } from '@/config/';

const useFilterStore = defineStore({
  id: 'Filter',
  state: () => ({
    // ## Project filter. ----------------------------------------------------------------------------
    projectFilterList: null,
    projectFilterListDone: false,
    projectFilterListPending: false,
    projectFilterListFail: false,

    // ## Label filter. ----------------------------------------------------------------------------
    labelFilterList: null,
    labelFilterListDone: false,
    labelFilterListPending: false,
    labelFilterListFail: false,

    // ## Committer filter. --------------------------------------------------------------------------
    committerFilterList: null,
    committerFilterListDone: false,
    committerFilterListPending: false,
    committerFilterListFail: false,

    // ## Team filter. --------------------------------------------------------------------------
    teamFilterList: null,
    teamFilterListDone: false,
    teamFilterListPending: false,
    teamFilterListFail: false,

    // ## Product filter. --------------------------------------------------------------------------
    productFilterList: null,
    productFilterListDone: false,
    productFilterListPending: false,
    productFilterListFail: false,

    // ## Scanner filter. ---------------------------------------------------------------------------
    scannerFilterList: null,
    scannerFilterListDone: false,
    scannerFilterListPending: false,
    scannerFilterListFail: false,

    // ## CWE filter. --------------------------------------------------------------------------------
    cweFilter: [],
    cweFilterPending: false,
    cweFilterDone: false,

    // ## CWE search. --------------------------------------------------------------------------------
    cweSearch: [],
    cweSearchPending: false,
    cweSearchDone: false,

    // ## Endpoint search. ---------------------------------------------------------------------------
    endpointSearch: [],
    endpointSearchPending: false,
    endpointSearchDone: false,

    // ## Criteria search. ---------------------------------------------------------------------------
    criteriaSearch: [],
    criteriaSearchDefault: {},
    criteriaSearchTeam: {},
    criteriaSearchPending: false,
    criteriaSearchDone: false,

    // ## Cloud assets filter. ---------------------------------------------------------------------------
    cloudAssetsFilterPending: false,
    cloudAssetsFilterFail: false,

    // ## Auditlog filter. ---------------------------------------------------------------------------
    auditLogFilter: [],
    auditLogFilterPending: false,
    auditLogFilterDone: false,
  }),
  actions: {
    // ## Get project filter. ------------------------------------------------------------------------
    async getProjectFilterList(data) {
      this.projectFilterListPending = true;

      try {
        const res = await FilterService.getProjectFilterList(data);
        this.setGetProjectFilterList(res.data.data);
        this.projectFilterListDone = !this.projectFilterListDone;
        this.projectFilterListPending = false;
        return res.data;
      } catch (error) {
        this.projectFilterListPending = false;
        this.projectFilterListFail = !this.projectFilterListFail;
        ErrorHandling(error);
      }
    },

    setGetProjectFilterList(payload) {
      this.projectFilterList = payload.map((s) => ({
        label: s.name,
        value: s.id,
      }));
    },

    // ## Get label filter. ------------------------------------------------------------------------
    async getLabelFilterList(data) {
      this.labelFilterListPending = true;

      try {
        const res = await FilterService.getLabelFilterList(data);
        this.setGetLabelFilterList(res.data.data);
        this.labelFilterListDone = !this.labelFilterListDone;
        this.labelFilterListPending = false;
      } catch (error) {
        this.labelFilterListPending = false;
        this.labelFilterListFail = !this.labelFilterListFail;
        ErrorHandling(error);
      }
    },

    setGetLabelFilterList(payload) {
      this.labelFilterList = payload.map((s) => ({
        label: s.name,
        value: s.name,
      }));
    },

    // ## Get committer filter. ----------------------------------------------------------------------
    async getCommitterFilterList(data) {
      this.committerFilterListPending = true;

      try {
        const res = await FilterService.getCommitterFilterList(data);
        this.setGetCommitterFilterList(res.data.committers);
        this.committerFilterListDone = !this.committerFilterListDone;
        this.committerFilterListPending = false;
      } catch (error) {
        this.committerFilterListPending = false;
        this.committerFilterListFail = !this.committerFilterListFail;
        ErrorHandling(error);
      }
    },

    setGetCommitterFilterList(payload) {
      this.committerFilterList = payload.map((s) => ({
        label: s,
        value: s,
      }));
    },

    // ## Get team filter. ----------------------------------------------------------------------
    async getTeamFilterList(data) {
      this.teamFilterListPending = true;

      try {
        const res = await FilterService.getTeamFilterList(data);
        this.setGetTeamFilterList(res.data.data);
        this.teamFilterListDone = !this.teamFilterListDone;
        this.teamFilterListPending = false;
        return res.data;
      } catch (error) {
        this.teamFilterListPending = false;
        this.teamFilterListFail = !this.teamFilterListFail;
        ErrorHandling(error);
      }
    },

    setGetTeamFilterList(payload) {
      this.teamFilterList = payload.map((s) => ({
        label: s.name,
        value: s.name,
      }));
    },

    // ## Get product filter. ----------------------------------------------------------------------
    async getProductFilterList(data) {
      this.productFilterListPending = true;

      try {
        const res = await FilterService.getProductFilterList(data);
        this.setGetProductFilterList(res.data.data);
        this.productFilterListDone = !this.productFilterListDone;
        this.productFilterListPending = false;
        return res.data;
      } catch (error) {
        this.productFilterListPending = false;
        this.productFilterListFail = !this.productFilterListFail;
        ErrorHandling(error);
      }
    },

    setGetProductFilterList(payload) {
      this.productFilterList = payload.map((s) => ({
        id: s.id,
        label: s.name,
        value: s.name,
      }));
    },

    // ## Get scanner filter. ------------------------------------------------------------------------
    async getScannerFilterList(data) {
      this.scannerFilterListPending = true;

      try {
        const res = await FilterService.getScannerFilterList(data);
        this.scannerFilterList = res.data.data.map((s) => ({
          manual: s.manual,
          name: s.name,
          type: s.type,
        }));
        this.scannerFilterListDone = !this.scannerFilterListDone;
        this.scannerFilterListPending = false;
        return this.scannerFilterList;
      } catch (error) {
        this.scannerFilterListPending = false;
        this.scannerFilterListFail = !this.scannerFilterListFail;
        ErrorHandling(error);
      }
    },

    // ## CWE Filter. --------------------------------------------------------------------------------
    async getCweFilter(data) {
      this.cweFilterPending = true;

      try {
        const params = {
          ...(data && data.owasp_standard && { owasp_standard: `eq:${data.owasp_standard}` }),
          ...(data && data.owasp && { owasp: `in:${data.owasp}` }),
          ...(data && data.cwe && { cwe: `in:${data.cwe}` }),
          ...(data && data.stride && { stride: `in:${data.stride}` }),
          ...(data && data.pci && { pci: `in:${data.pci}` }),
        };
        const res = await FilterService.cweFilter(params);
        this.setGetCweFilter(res.data);
        this.cweFilterDone = !this.cweFilterDone;
        this.cweFilterPending = false;
      } catch (error) {
        this.cweFilterPending = false;
        ErrorHandling(error);
      }
    },

    setGetCweFilter(payload) {
      this.cweFilter = {
        cwe: Object.entries(payload.cwe).map((s) => ({
          label: s[1],
          value: s[0],
        })),
        owasp: payload.owasp.map((s) => ({
          label: s.replace('_', ' - ').replaceAll('_', ' '),
          value: s.split('_')[0],
        })),
        pci: payload.pci.map((s) => ({
          label: s.replace('_', ' - ').replaceAll('_', ' '),
          value: s.split('_')[0],
        })),
        stride: payload.stride.map((s) => ({
          label: PROJECT.STRIDE.find((a) => a.value === s).label,
          value: s,
        })),
      };
    },

    // ## CWE search. --------------------------------------------------------------------------------
    async getCweSearch(data) {
      this.cweSearchPending = true;

      try {
        const res = await FilterService.getCweSearch(data);
        this.cweSearch = res.data;
        this.cweSearchDone = !this.cweSearchDone;
        this.cweSearchPending = false;
      } catch (error) {
        this.cweSearchPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get endpoint search. -----------------------------------------------------------------------
    async getEndpointSearch(data) {
      this.endpointSearchPending = true;

      try {
        const res = await FilterService.getEndpointSearch(data);
        this.endpointSearch = res.data.data;
        this.endpointSearchDone = !this.endpointSearchDone;
        this.endpointSearchPending = false;
      } catch (error) {
        this.endpointSearchPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get criteria search. -----------------------------------------------------------------------
    async getCriteriaSearch(data) {
      this.criteriaSearchPending = true;

      try {
        const res = await FilterService.getCriteriaSearch(data);
        this.setGetCriteriaSearch(res.data.data);
        this.criteriaSearchDone = !this.criteriaSearchDone;
        this.criteriaSearchPending = false;
      } catch (error) {
        this.criteriaSearchPending = false;
        ErrorHandling(error);
      }
    },

    setGetCriteriaSearch(payload) {
      this.criteriaSearch = (payload.criteria ? payload.criteria : []);
      this.criteriaSearchDefault = payload.default;
      this.criteriaSearchTeam = payload.team;
    },

    // ## Cloud assets filter. -----------------------------------------------------------------------
    async cloudAssetsFilter(params) {
      this.cloudAssetsFilterPending = true;

      try {
        const res = await FilterService.cloudAssetsFilter(params);
        this.cloudAssetsFilterPending = false;
        return res.data.resource;
      } catch (error) {
        this.cloudAssetsFilterPending = false;
        this.cloudAssetsFilterFail = !this.cloudAssetsFilterFail;
        ErrorHandling(error);
      }
    },

    // ## Get audit logs filter. -----------------------------------------------------------------------
    async getAuditLogFilter(data) {
      this.auditLogFilterPending = true;

      try {
        const res = await FilterService.getAuditLogFilter(data);
        this.setGetAuditLogFilter(res.data.data);
        this.auditLogFilterDone = !this.auditLogFilterDone;
        this.auditLogFilterPending = false;
      } catch (error) {
        this.auditLogFilterPending = false;
        ErrorHandling(error);
      }
    },

    setGetAuditLogFilter(payload) {
      this.auditLogFilter = [];

      if (!payload) {
        return;
      }
      this.auditLogFilter = payload;
    },
  },
});

export default useFilterStore;
