const PreComponent = '<router-view/>';

export default {
  path: 'products',
  name: 'products',
  component: { template: PreComponent },
  children: [
    {
      path: 'list',
      name: 'product-list',
      component: () => import('@/views/pages/member/Product/List.vue'),
      meta: {
        title: 'Products',
        activeMenuKeys: ['products'],
        allowedRole: [1, 2, 3, 5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'product-list',
            query: {
              page: 1,
            },
          };
        }
      },
    }, {
      path: ':id',
      name: 'product-details',
      component: () => import('@/views/layouts/Products/ProductViewLayout.vue'),
      children: [
        {
          path: 'details',
          name: 'product-details',
          component: () => import('@/views/pages/member/Product/Details.vue'),
          children: [
            {
              path: 'appsec',
              name: 'product-appsec-details',
              component: () => import('@/views/pages/member/Product/Details.vue'),
              meta: {
                title: 'Details',
                activeMenuKeys: ['products', 'product-details'],
                allowedRole: [1, 2, 3, 5],
              },
            }, {
              path: 'infra',
              name: 'product-infra-details',
              component: () => import('@/views/pages/member/Product/Details.vue'),
              meta: {
                title: 'Details',
                activeMenuKeys: ['products', 'product-details'],
                allowedRole: [1, 2, 3, 5],
              },
            }, /* {
              path: 'management',
              name: 'product-management-details',
              component: () => import('@/views/pages/member/Product/Details.vue'),
              meta: {
                title: 'Details',
                activeMenuKeys: ['products', 'product-details'],
                businessType: ['zeiss'],
                allowedRole: [5],
              },
            }, */
          ],
        }, {
          path: 'scans',
          name: 'product-scans',
          component: () => import('@/views/pages/member/Product/Scans.vue'),
          meta: {
            title: 'Scans',
            activeMenuKeys: ['products', 'product-scans'],
            allowedRole: [1, 3, 5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'product-scans',
                params: { id: to.params.id },
                query: { page: 1 },
              };
            }
          },
        }, {
          path: 'imports',
          name: 'product-imports',
          component: () => import('@/views/pages/member/Product/Imports.vue'),
          meta: {
            title: 'Imports',
            activeMenuKeys: ['products', 'product-imports'],
            allowedRole: [1, 3, 5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'product-imports',
                params: { id: to.params.id },
                query: { page: 1 },
              };
            }
          },
        }, {
          path: 'vulns',
          name: 'product-vulns',
          component: () => import('@/views/layouts/Products/ProductVulnsViewLayout.vue'),
          children: [
            {
              path: 'appsec',
              name: 'product-appsec-vulns',
              component: () => import('@/views/pages/member/Product/Vulns/AppSec.vue'),
              meta: {
                title: 'AppSec - All',
                activeMenuKeys: ['products', 'product-vulns', 'appsec'],
                allowedRole: [1, 2, 3, 5],
              },
              beforeEnter: (to) => {
                if (!to.query.page || !to.query.perPage) {
                  return {
                    name: 'product-appsec-vulns',
                    params: { id: to.params.id },
                    query: { page: 1, perPage: 15 },
                  };
                }
              },
            }, {
              path: 'appsec/:group1/:group2',
              name: 'product-appsec-dynamic-view',
              component: () => import('@/views/pages/member/Product/Vulns/DynamicView.vue'),
              meta: {
                title: 'AppSec - All',
                activeMenuKeys: ['projects', 'product-vulns', 'appsec'],
                allowedRole: [1, 2, 3, 5],
              },
              beforeEnter: (to) => {
                if (!to.query.page || !to.query.perPage) {
                  return {
                    name: 'product-appsec-dynamic-view',
                    params: { id: to.params.id },
                    query: { page: 1, perPage: 15 },
                  };
                }
              },
            }, {
              path: 'clould/:type',
              name: 'product-cloud-vulns',
              component: () => import('@/views/pages/member/Product/Vulns/Cloud.vue'),
              meta: {
                title: 'Cloud - All',
                activeMenuKeys: ['products', 'products-vulns', 'cloud'],
                allowedRole: [0, 1, 2, 3, 5, 10],
              },
              beforeEnter: (to) => {
                if (!to.query.page || !to.query.perPage) {
                  return {
                    name: 'products-cloud-vulns',
                    params: { id: to.params.id },
                    query: { page: 1, perPage: 15 },
                  };
                }
              },
            }, {
              path: 'infra',
              name: 'product-infra-vulns',
              component: () => import('@/views/pages/member/Product/Vulns/Infra.vue'),
              meta: {
                title: 'Infrastructure - All',
                activeMenuKeys: ['products', 'product-vulns', 'infra'],
                allowedRole: [1, 2, 3, 5],
              },
              beforeEnter: (to) => {
                if (!to.query.page || !to.query.perPage) {
                  return {
                    name: 'product-infra-vulns',
                    params: { id: to.params.id },
                    query: { page: 1, perPage: 15 },
                  };
                }
              },
            }, {
              path: 'infra/:group1/:group2',
              name: 'product-infra-dynamic-view',
              component: () => import('@/views/pages/member/Product/Vulns/DynamicView.vue'),
              meta: {
                title: 'Infrastructure - All',
                activeMenuKeys: ['products', 'product-vulns', 'infra'],
                allowedRole: [1, 2, 3, 5],
              },
              beforeEnter: (to) => {
                if (!to.query.page || !to.query.perPage) {
                  return {
                    name: 'product-infra-ip-vulns',
                    params: { id: to.params.id },
                    query: { page: 1, perPage: 15 },
                  };
                }
              },
            },
          ],
        }, {
          path: 'files',
          name: 'product-files',
          component: () => import('@/views/pages/member/Product/Files.vue'),
          meta: {
            title: 'Files',
            activeMenuKeys: ['products', 'product-files'],
            allowedRole: [1, 5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'project-files',
                params: { id: to.params.id },
                query: { page: 1 },
              };
            }
          },
        }, {
          path: 'sbom',
          name: 'product-sbom',
          component: () => import('@/views/layouts/Products/ProductSbomViewLayout.vue'),
          redirect: {
            name: 'product-sbom-scans',
          },
          children: [
            {
              path: 'scans',
              name: 'product-sbom-scans',
              component: () => import('@/views/pages/member/Product/Sbom/Scans.vue'),
              meta: {
                title: 'SBOM',
                activeMenuKeys: ['products', 'product-sbom'],
                allowedRole: [0, 1, 2, 3, 5],
              },
              beforeEnter: (to) => {
                if (!to.query.page) {
                  return {
                    name: 'product-sbom',
                    params: { id: to.params.id },
                    query: { page: 1 },
                  };
                }
              },
            }, {
              path: 'components',
              name: 'product-sbom-components',
              component: () => import('@/views/pages/member/Product/Sbom/Components.vue'),
              meta: {
                title: 'SBOM',
                activeMenuKeys: ['products', 'product-sbom'],
                allowedRole: [0, 1, 2, 3, 5],
              },
              beforeEnter: (to) => {
                if (!to.query.page) {
                  return {
                    name: 'product-sbom',
                    params: { id: to.params.id },
                    query: { page: 1 },
                  };
                }
              },
            },
          ],
        }, {
          path: 'endpoints',
          name: 'product-endpoints',
          component: () => import('@/views/pages/member/Product/Endpoint/List.vue'),
          meta: {
            title: 'Endpoints',
            activeMenuKeys: ['products', 'product-endpoint'],
            allowedRole: [5],
          },
        }, {
          path: 'endpoints/:endpoint/detail',
          name: 'product-endpoints-detail',
          component: () => import('@/views/pages/member/Product/Endpoint/Details.vue'),
          meta: {
            title: 'Endpoints Detail',
            activeMenuKeys: ['products', 'product-endpoint'],
            allowedRole: [5],
          },
        }, {
          path: 'user-management',
          name: 'product-user-management',
          component: () => import('@/views/pages/member/Product/UserManagement.vue'),
          meta: {
            title: 'User Management',
            activeMenuKeys: ['products', 'product-user-management'],
            allowedRole: [2, 3, 5],
          },
        },
      ],
    },
  ],
};
