import { defineStore } from 'pinia';

import ScanService from '@/services/Scan';

import ErrorHandling from '@/mixins/ErrorHandling';

import { SCANS } from '@/config/';

import dayjs from 'dayjs';

import dummyData from './_dummyData';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const setMetaDataQuery = (option) => {
  if (option && option.indexOf('nbl') > -1) {
    return 'ne:';
  }
  if (option && option.indexOf('bl') > -1) {
    return 'eq:';
  }
  return option;
};

const useScanStore = defineStore({
  id: 'Scan',
  state: () => ({
    // ## All scans. ---------------------------------------------------------------------------------
    allScans: [],
    allScansPending: false,
    allScansDone: false,
    allowProjectLevelInfraScans: false,

    // ## Scan Detail. -------------------------------------------------------------------------------
    scanDetail: {},
    scanDetailPending: false,
    scanDetailDone: false,

    // ## Scan params. -------------------------------------------------------------------------------
    scanParams: [],
    scanParamsPending: false,
    scanParamsDone: false,

    // ## Start scan params. -------------------------------------------------------------------------
    startScanParamsData: [],
    startScanParamsPending: false,
    startScanParamsDone: false,
    startScanParamsFail: false,
    startScanParamsFailMsg: null,

    // ## Calendar scans. ----------------------------------------------------------------------------
    allScansCalendar: [],
    allScansCalendarPending: false,
    allScansCalendarDone: false,

    // ## All scans table. ---------------------------------------------------------------------------
    allScansTable: dummyData,
    allScansTablePerpage: SCANS.PERPAGE,
    allScansTableTotal: 0,
    allScansTablePending: false,
    allScansTableDone: false,
    allScansTableFail: false,
    allScansTableFailMsg: null,

    // ## All scans queue table. ---------------------------------------------------------------------
    allScansQueueTable: dummyData,
    allScansQueueTablePerpage: SCANS.PERPAGE,
    allScansQueueTableDelay: SCANS.REFRESH_DELAY,
    allScansQueueTableTotal: 0,
    allScansQueueTablePending: false,
    allScansQueueTableDone: false,
    allScansQueueTableFail: false,
    allScansQueueTableFailMsg: null,

    // ## All scans failed table. --------------------------------------------------------------------
    allScansFailedTable: dummyData,
    allScansFailedTablePerpage: SCANS.PERPAGE,
    allScansFailedTableTotal: 0,
    allScansFailedTablePending: false,
    allScansFailedTableDone: false,
    allScansFailedTableFail: false,
    allScansFailedTableFailMsg: null,

    // ## Update scan. -------------------------------------------------------------------------------
    updateScanPending: false,
    updateScanDone: false,
    updateScanFail: false,

    // ## Add scan. ----------------------------------------------------------------------------------
    addScanData: null,
    addScanPending: false,
    addScanDone: false,
    addScanFail: false,

    // ## Remove scan. -------------------------------------------------------------------------------
    removeScanPending: false,
    removeScanDone: false,

    // ## Rescan. ------------------------------------------------------------------------------------
    reScanPending: false,
    reScanDone: false,
    reScanFail: false,
    reScanFailMsg: null,

    // ## Cancel Scan. -------------------------------------------------------------------------------
    cancelScanPending: false,
    cancelScanDone: false,
    cancelScanFail: false,
    cancelScanFailMsg: null,

    // ## Importable tools. --------------------------------------------------------------------------
    importableTools: null,
    importableToolsPending: false,
    importableToolsDone: false,

    // ## Create nessus pro scan. --------------------------------------------------------------------
    createNessusProScanData: null,
    createNessusProScanPending: false,
    createNessusProScanDone: false,

    // ## Create qualys was scan. --------------------------------------------------------------------
    createQualysWasScanData: null,
    createQualysWasScanPending: false,
    createQualysWasScanDone: false,

    // ## Create sonatypenl scan. --------------------------------------------------------------------
    createSonatypenlScanData: null,
    createSonatypenlScanPending: false,
    createSonatypenlScanDone: false,
  }),
  actions: {
    // ## Get all scans. -----------------------------------------------------------------------------
    async getAllScans(data) {
      this.allScansPending = true;

      try {
        const res = await ScanService.get(data);
        if (res.data.data.infra_management_config) {
          this.allowProjectLevelInfraScans = res.data.data.infra_management_config.allow_project_level_infra_scans;
        }
        this.setGetAllScans(res.data.data.scanparams);
        this.allScansDone = !this.allScansDone;
        this.allScansPending = false;
      } catch (error) {
        this.allScansPending = false;
        ErrorHandling(error);
      }
    },

    setGetAllScans(payload) {
      this.allScans = [];
      if (!payload) {
        return;
      }
      const sast = payload.filter((s) => s.tool.type === 'sast');
      const mast = payload.filter((s) => s.tool.type === 'mast');
      const dast = payload.filter((s) => s.tool.type === 'dast');
      const iast = payload.filter((s) => s.tool.type === 'iast');
      const sca = payload.filter((s) => s.tool.type === 'sca');
      const cspm = payload.filter((s) => s.tool.type === 'cspm');
      const cs = payload.filter((s) => s.tool.type === 'cs');
      const iac = payload.filter((s) => s.tool.type === 'iac');
      const infra = payload.filter((s) => s.tool.type === 'infra');
      const bugbounty = payload.filter((s) => s.tool.type === 'bugbounty');
      const pentest = payload.filter((s) => s.tool.type === 'pentest');
      this.allScans =
        [...sast, ...mast, ...dast, ...iast, ...sca, ...cspm, ...cs, ...iac, ...infra, ...bugbounty, ...pentest];
    },

    // ## Get all scans. -----------------------------------------------------------------------------
    async getScanDetail(data) {
      this.scanDetailPending = true;

      try {
        const res = await ScanService.getScanDetail(data);
        this.scanDetail = res.data.data;
        this.scanDetailDone = !this.scanDetailDone;
        this.scanDetailPending = false;
      } catch (error) {
        this.scanDetailPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get scan params. ---------------------------------------------------------------------------
    async getScanParams(data) {
      this.scanParamsPending = true;

      try {
        const res = await ScanService.getScanParams(data);
        this.scanParams = res.data.data.scanparams.filter((s) => s.tool.name !== 'sbomradar');
        this.scanParamsDone = !this.scanParamsDone;
        this.scanParamsPending = false;
      } catch (error) {
        this.scanParamsPending = false;
        ErrorHandling(error);
      }
    },

    resetScanParams() {
      this.scanParams = [];
    },

    // ## Start scan params. -------------------------------------------------------------------------
    async startScanParams(data) {
      this.startScanParamsPending = true;

      try {
        const res = await ScanService.startScanParams(data);
        this.startScanParamsData = res.data.data;
        this.startScanParamsDone = !this.startScanParamsDone;
        this.startScanParamsPending = false;
      } catch (error) {
        this.startScanParamsPending = false;
        this.startScanParamsFail = !this.startScanParamsFail;
        this.startScanParamsFailMsg = ErrorHandling(error);
      }
    },

    // ## Get all scans calendar. --------------------------------------------------------------------
    async getAllScansCalendar(data) {
      this.allScansCalendarPending = true;

      try {
        const params = {
          date: data.date,
          time_zone: data.timeZone,
        };
        const res = await ScanService.getCalendar(params);
        this.allScansCalendar = res.data.data;
        this.allScansCalendarDone = !this.allScansCalendarDone;
        this.allScansCalendarPending = false;
      } catch (error) {
        this.allScansCalendarPending = false;
        ErrorHandling(error);
      }
    },

    resetAllScansCalendar() {
      this.allScansCalendar = [];
      this.allScansCalendarPending = false;
      this.allScansCalendarDone = false;
    },

    // ## Get all scans table. -----------------------------------------------------------------------
    async getAllScansTable(data) {
      this.allScansTablePending = true;
      this.allScansTable = dummyData;

      try {
        const params = {
          limit: this.allScansTablePerpage,
          start: (data.page - 1) * this.allScansTablePerpage,
          sort_by: data.sort,
          analyzing: data.analyzing ? `${data.analyzing.split(':')[0]}:${data.analyzing.split(':')[1] === 'completed'}` : null,
          branch: data.branch,
          discovered_by: data.discoveredBy,
          engagement: data.engagement,
          manual: data.manual,
          meta_data: setMetaDataQuery(data.metaData),
          project: data.project,
          profile_name: data.profileName,
          scan_type: data.scanType,
          teams: data.team,
          imported_by: data.importedBy,
          tool_name: data.scanner,
        };
        const res = await ScanService.getAll(params);
        this.allScansTableTotal = res.data.total;
        this.setGetAllScansTable(res.data.data);
        this.allScansTableDone = !this.allScansTableDone;
        this.allScansTablePending = false;
      } catch (error) {
        this.allScansTable = dummyData;
        this.allScansTableTotal = 0;
        this.allScansTablePending = false;
        this.allScansTableFail = !this.allScansTableFail;
        this.allScansTableFailMsg = ErrorHandling(error);
      }
    },

    setGetAllScansTable(payload) {
      this.allScansTable = [];
      if (!payload) {
        return;
      }
      this.allScansTable = payload.map((s) => ({
        key: s.id,
        branch: s.scanparams.branch ? s.scanparams.branch : '-',
        id: s.id,
        scanDate: dayjs(s.scan_date).format('DD MMM YYYY HH:mm'),
        scanner: s.scanparams.tool.name,
        prefix: s.scanparams.tool.is_custom ? 'custom' : 'internal',
        toolType: s.scanparams.tool.type,
        toolDisabled: s.scanparams.tool.disabled,
        scanType: s.scan_type ? s.scan_type.split(',') : null,
        project: s.scanparams.project.name,
        projectId: s.scanparams.project.id,
        scanParams: s.scanparams,
        duration: !s.duration ? '-' : dayjs.unix(s.duration / 1000).utc().format('HH:mm:ss'),
        engagement: s.import_info ? s.import_info.engagement : null,
        team: s.scanparams.project.owner.team.name,
        metaData: s.scanparams.meta_data,
        status: 'completed',
        forkScan: s.scanparams.fork_scan,
        manual: s.scanparams.manual,
        analyzing: s.analyzing,
        discoveredBy: (s.import_info ? s.import_info.discovered_by : null),
        importedBy:
          (s.import_info && s.import_info.imported_by ? s.import_info.imported_by.username : null),
        dateDiscovered:
          (s.import_info ?
            dayjs(s.import_info.date_discovered).format('DD MMM YYYY HH:mm') : null),
        uploadedDate:
          (s.import_info ? dayjs(s.import_info.uploaded_at).format('DD MMM YYYY HH:mm') : null),
        critical: s.summary.critical ? s.summary.critical : 0,
        high: s.summary.high ? s.summary.high : 0,
        medium: s.summary.medium ? s.summary.medium : 0,
        low: s.summary.low ? s.summary.low : 0,
        info: s.summary.info ? s.summary.info : 0,
        action: s.id,
      }));
    },

    // ## Get all scans queue table. -----------------------------------------------------------------
    async getAllScansQueueTable(data) {
      this.allScansQueueTablePending = true;

      try {
        const params = {
          limit: this.allScansQueueTablePerpage,
          start: (data.page - 1) * this.allScansQueueTablePerpage,
          sort_by: data.sort,
          branch: data.branch,
          meta_data: setMetaDataQuery(data.metaData),
          project: data.project,
          profile_name: data.profileName,
          status: data.scanStatus,
          teams: data.team,
          tool_name: data.scanner,
        };
        const res = await ScanService.getAllQueue(params);
        this.allScansQueueTableTotal = res.data.total;
        this.setGetAllScansQueueTable(res.data.data);
        this.allScansQueueTableDone = !this.allScansQueueTableDone;
        this.allScansQueueTablePending = false;
      } catch (error) {
        this.allScansQueueTable = dummyData;
        this.allScansQueueTableTotal = 0;
        this.allScansQueueTablePending = false;
        this.allScansQueueTableFail = !this.allScansQueueTableFail;
        this.allScansQueueTableFailMsg = ErrorHandling(error);
      }
    },

    setGetAllScansQueueTable(payload) {
      this.allScansQueueTable = [];
      payload.forEach((s) => {
        this.allScansQueueTable.push({
          id: s.id,
          branch: s.scanparams.branch,
          duration: !s.duration ? '-' : dayjs.unix(s.duration / 1000).utc().format('HH:mm:ss'),
          forkScan: s.scanparams.fork_scan,
          manual: s.scanparams.manual,
          metaData: s.scanparams.meta_data,
          project: s.project.name,
          projectId: s.project.id,
          progress: s.progress,
          scanParams: s.scanparams,
          scanner: s.scanparams.tool.name,
          prefix: s.scanparams.tool.is_custom ? 'custom' : 'internal',
          scanType: s.scan_type ? s.scan_type.split(',') : null,
          toolType: s.scanparams.tool.type,
          status: s.stage,
          team: s.project.owner.team.name,
          queueDate: dayjs(s.started_at).format('DD MMM YYYY HH:mm'),
        });
      });
    },

    // ## Get all scans failed table. ----------------------------------------------------------------
    async getAllScansFailedTable(data) {
      this.allScansFailedTablePending = true;
      this.allScansFailedTable = dummyData;

      try {
        const params = {
          limit: this.allScansFailedTablePerpage,
          start: (data.page - 1) * this.allScansFailedTablePerpage,
          sort_by: data.sort,
          branch: data.branch,
          meta_data: setMetaDataQuery(data.metaData),
          project: data.project,
          profile_name: data.profileName,
          teams: data.team,
          tool_name: data.scanner,
        };
        const res = await ScanService.getAllFailed(params);
        this.allScansFailedTableTotal = res.data.total;
        this.setGetAllScansFailedTable(res.data.data);
        this.allScansFailedTableDone = !this.allScansFailedTableDone;
        this.allScansFailedTablePending = false;
      } catch (error) {
        this.allScansFailedTable = dummyData;
        this.allScansFailedTableTotal = 0;
        this.allScansFailedTablePending = false;
        this.allScansFailedTableFail = !this.allScansFailedTableFail;
        this.allScansFailedTableFailMsg = ErrorHandling(error);
      }
    },

    setGetAllScansFailedTable(payload) {
      this.allScansFailedTable = [];
      payload.forEach((s) => {
        this.allScansFailedTable.push({
          key: s.id,
          id: s.id,
          startedDate: dayjs(s.started_at).format('DD MMM YYYY HH:mm'),
          failedDate: dayjs(s.finished_at).format('DD MMM YYYY HH:mm'),
          scanner: s.scanparams.tool.name,
          prefix: s.scanparams.tool.is_custom ? 'custom' : 'internal',
          scanType: s.scan_type ? s.scan_type.split(',') : null,
          toolDisabled: s.scanparams.tool.disabled,
          project: s.project.name,
          projectId: s.project.id,
          branch: s.scanparams.branch ? s.scanparams.branch : '-',
          status: s.status,
          message: s.message === '' ? 'N/A' : s.message,
          manual: s.scanparams.manual,
          team: s.project.owner.team.name,
          metaData: s.scanparams.meta_data,
          forkScan: s.scanparams.fork_scan,
          scanParams: s.scanparams,
        });
      });
    },

    // ## Update scan. -------------------------------------------------------------------------------
    async updateScan(data) {
      this.updateScanPending = true;

      try {
        const res = await ScanService.update(data);
        this.addScanData = res.data;
        this.updateScanDone = !this.updateScanDone;
        this.updateScanPending = false;
      } catch (error) {
        this.updateScanPending = false;
        this.updateScanFail = !this.updateScanFail;
        ErrorHandling(error);
      }
    },

    // ## Add scan. ----------------------------------------------------------------------------------
    async addScan(data) {
      this.addScanPending = true;

      try {
        const res = await ScanService.post(data);
        this.addScanData = res.data;
        this.addScanDone = !this.addScanDone;
        this.addScanPending = false;
      } catch (error) {
        this.addScanPending = false;
        this.addScanFail = !this.addScanFail;
        ErrorHandling(error);
      }
    },

    // ## Remove scan. -------------------------------------------------------------------------------
    async removeScan(data) {
      this.removeScanPending = true;

      try {
        await ScanService.remove(data);
        this.removeScanDone = !this.removeScanDone;
        this.removeScanPending = false;
      } catch (error) {
        this.removeScanPending = false;
        ErrorHandling(error);
      }
    },

    // ## Rescan. ------------------------------------------------------------------------------------
    async reScanAction(data) {
      this.reScanPending = true;

      try {
        await ScanService.reScan(data);
        this.reScanDone = !this.reScanDone;
        this.reScanPending = false;
      } catch (error) {
        this.reScanPending = false;
        this.reScanFail = !this.reScanFail;
        ErrorHandling(error);
      }
    },

    // ## Cancel Scan. -------------------------------------------------------------------------------
    async cancelScan(data) {
      this.cancelScanPending = true;

      try {
        await ScanService.cancelScan(data);
        this.cancelScanDone = !this.cancelScanDone;
        this.cancelScanPending = false;
      } catch (error) {
        this.cancelScanPending = false;
        this.cancelScanFail = !this.cancelScanFail;
        this.cancelScanFailMsg = ErrorHandling(error);
      }
    },

    // ## Get importable tools. ----------------------------------------------------------------------
    async getImportableTools() {
      this.importableToolsPending = true;

      try {
        const res = await ScanService.getImportableTools();
        this.setGetImportableTools(res.data.data);
        this.importableToolsDone = !this.importableToolsDone;
        this.importableToolsPending = false;
      } catch (error) {
        this.importableToolsPending = false;
        ErrorHandling(error);
      }
    },

    setGetImportableTools(payload) {
      this.importableTools = payload.map((s) => ({
        name: s.name,
        prefix: s.is_custom ? 'custom' : 'internal',
        type: s.type,
        disabled: s.disabled,
        id: s.id,
      }));
    },

    // ## Create nessus pro scan. --------------------------------------------------------------------
    async createNessusProScan(data) {
      this.createNessusProScanPending = true;

      try {
        const res = await ScanService.createNessusProScan(data);
        this.createNessusProScanData = res.data.data;
        this.createNessusProScanDone = !this.createNessusProScanDone;
        this.createNessusProScanPending = false;
      } catch (error) {
        this.createNessusProScanPending = false;
        ErrorHandling(error);
      }
    },

    // ## Create qualys was scan. --------------------------------------------------------------------
    async createQualysWasScan(data) {
      this.createQualysWasScanPending = true;

      try {
        const res = await ScanService.createQualysWasScan(data);
        this.createQualysWasScanData = res.data.data;
        this.createQualysWasScanDone = !this.createQualysWasScanDone;
        this.createQualysWasScanPending = false;
      } catch (error) {
        this.createQualysWasScanPending = false;
        ErrorHandling(error);
      }
    },

    // ## Create sonatypenl scan. ------------------------------------------------------------------
    async createSonatypenlScan(data) {
      this.createSonatypenlScanPending = true;

      try {
        const res = await ScanService.createSonatypenlScan(data);
        this.createSonatypenlScanData = res.data.data;
        this.createSonatypenlScanDone = !this.createSonatypenlScanDone;
        this.createSonatypenlScanPending = false;
      } catch (error) {
        this.createSonatypenlScanPending = false;
        ErrorHandling(error);
      }
    },
  },
});

export default useScanStore;
