import { defineStore } from 'pinia';

import VulnService from '@/services/Vulnerabilities';

import ErrorHandling from '@/mixins/ErrorHandling';

import { VULN } from '@/config/';

import dayjs from 'dayjs';

import {
  dummyData,
  vulnScansDummy,
  allVulnsDynamicViewDummyData,
  vulnDetailsDummy,
  vulnCollaborationDummy,
} from './_dummyData';

const vulnParams = (data) => {
  const setMetaDataQuery = (option) => {
    if (option && option.indexOf('nbl') > -1) {
      return 'ne:';
    }
    if (option && option.indexOf('bl') > -1) {
      return 'eq:';
    }
    return option;
  };

  const params = {
    id: data.id,
    type: data.type,
    prefix: data.prefix,
    query: {
      limit: data.options.perPage,
      start: (data.options.page - 1) * data.options.perPage,
      sort_by: data.options.sort,
      group1: data.options.group1,
      group2: data.options.group2,
      asvs_level: data.options.businessCriticality,
      branch: data.options.branch,
      business_unit_tag_ids: data.options.businessUnits,
      committer: data.options.committer,
      cluster: data.options.cluster,
      cloud_platform: data.options.platform,
      cvss: data.options.cvss,
      cvss_vector: data.options.cvssVector,
      cwe_name: data.options.cweName,
      cwe_no: data.options.cweId,
      cve: data.options.cveId,
      dns_name: data.options.dnsName,
      default_branch: data.options.defaultBranch,
      docker_image: data.options.dockerImage,
      display_fields: data.options.displayFields,
      due_date: data.options.dueDate,
      engagement: data.options.engagement,
      endpoint_id: data.options.endpointId,
      environment: data.options.environment,
      epss_percentile: data.options.epssPercentile,
      epss_percentage: data.options.epssPercentage,
      family: data.options.family,
      first_seen: data.options.firstSeenFrom,
      from: data.options.lastStatusUpdate,
      fp: data.options.falsePositive ? `${data.options.falsePositive.split(':')[0]}:${data.options.falsePositive.split(':')[1] === 'yes'}` : undefined,
      fixable: data.options.fixable ? `${data.options.fixable.split(':')[0]}:${data.options.fixable.split(':')[1] === 'yes'}` : undefined,
      fixed_version: data.options.fixedVersion,
      fixed_version_exist: data.options.hasFix,
      fp_mark_date: data.options.fpMarkDate,
      fqdn: data.options.fqdn,
      has_comment: data.options.hasNote ? `${data.options.hasNote.split(':')[0]}:${data.options.hasNote.split(':')[1] === 'yes'}` : undefined,
      has_internet_exposure: data.options.hasInternetExposure,
      path: data.options.filePath || data.options.ip || data.options.path,
      highlighted_vulns: data.options.highlightedVulnerabilities ? `${data.options.highlightedVulnerabilities.split(':')[0]}:${data.options.highlightedVulnerabilities.split(':')[1] === 'yes'}` : null,
      historical_status: data.options.historicalStatus,
      ip_cidr: data.options.ipCidr,
      internal_ip: data.options.internalIP,
      id: data.options.id,
      image: data.options.image,
      issue_assignee: data.options.issueAssignee,
      issue_closed_date: data.options.issueClosedDate,
      issue_created_date: data.options.issueCreatedDate,
      issue_id: data.options.issueId,
      is_master: data.options.isMaster ? `${data.options.isMaster.split(':')[0]}:${data.options.isMaster.split(':')[1] === 'yes'}` : undefined,
      issue_url: data.options.issueUrl,
      issue_status: data.options.issueStatus,
      issue_wip_date: data.options.issueWIPDate,
      infra_group_ids: data.options.infraGroupNames,
      known_exploit: data.options.knownExploit ? `${data.options.knownExploit.split(':')[0]}:${data.options.knownExploit.split(':')[1] === 'yes'}` : undefined,
      labels: data.options.label,
      label_ids: data.options.labelIds,
      last_seen: data.options.lastSeen,
      name: data.options.vulnerabilityName || data.options.name,
      name_space: data.options.nameSpace,
      net_bios_name: data.options.netBiosName,
      observed_in_the_wild: data.options.observedInTheWild ? `${data.options.observedInTheWild.split(':')[0]}:${data.options.observedInTheWild.split(':')[1] === 'yes'}` : undefined,
      os: data.options.os,
      owasp2017: data.options.owasp2017Top10,
      owasp2021: data.options.owasp2021Top10,
      purl: data.options.packageUrl,
      pci: data.options.pci,
      plugin_id: data.options.pluginId,
      plugin_name: data.options.pluginName,
      port: data.options.port,
      protocol: data.options.protocol,
      products: data.options.product,
      project_ids: data.options.projectIds,
      projects: data.options.project,
      meta_data: setMetaDataQuery(data.options.metaData),
      region: data.options.region,
      ra: data.options.riskAccepted,
      risk_rating: data.options.riskRating,
      ra_mark_date: data.options.raMarkDate,
      risk_factors: data.options.riskFactor,
      manual: data.options.manual ? `${data.options.manual.split(':')[0]}:${data.options.manual.split(':')[1] === 'yes'}` : undefined,
      merge_status: data.options.mergeStatus,
      mit: data.options.mitigated ? `${data.options.mitigated.split(':')[0]}:${data.options.mitigated.split(':')[1] === 'yes'}` : undefined,
      nist: data.options.nist,
      overdue: data.options.overdue ? `${data.options.overdue.split(':')[0]}:${data.options.overdue.split(':')[1] === 'yes'}` : undefined,
      severity: data.options.severity,
      service: data.options.service,
      status: data.options.status,
      tags: data.options.tags,
      team_ids: data.options.teamIds,
      text: data.options.textSearch,
      teams: data.options.team,
      to: data.options.firstSeenTo,
      tool_name: data.options.scanner,
      tool_type: data.options.scannerType,
      tp: data.options.truePositive ? `${data.options.truePositive.split(':')[0]}:${data.options.truePositive.split(':')[1] === 'yes'}` : undefined,
      user_vuln_status: data.options.userVulnStatus,
      was_zero_day: data.options.wasZeroDay ? `${data.options.wasZeroDay.split(':')[0]}:${data.options.wasZeroDay.split(':')[1] === 'yes'}` : undefined,
      workload_name: data.options.workloadName,
      workload_namespace: data.options.workloadNamespace,
      workload_kind: data.options.workloadKind,
      woe: data.options.woe,
      wf: data.options.wontfix ? `${data.options.wontfix.split(':')[0]}:${data.options.wontfix.split(':')[1] === 'yes'}` : undefined,
    },
  };
  return params;
};
const useVulnsStore = defineStore({
  id: 'Vuns',
  state: () => ({
    // ## All vulns table. ---------------------------------------------------------------------------
    allVulnsTable: dummyData,
    allVulnsTableSettings: null,
    allVulnsTableTotal: 0,
    allVulnsTablePending: false,
    allVulnsTableDone: false,
    allVulnsTableFail: false,
    allVulnsTableFailMsg: null,
    allVulnsScanData: vulnScansDummy,
    allVulnsScanDataTotal: 0,
    allVulnsScanDummyData: vulnScansDummy,
    allVulnsDynamicViewItems: {},

    // ## Export vulns. -------------------------------------------------------------------------------
    exportVulnsData: {},
    exportVulnsPending: false,
    exportVulnsDone: false,
    exportVulnsFail: false,
    exportVulnsFailMsg: null,

    // ## Export vulns status. -------------------------------------------------------------------------------
    exportVulnsStatusData: {
      prosess: 0,
    },
    exportVulnsStatusPending: false,
    exportVulnsStatusDone: false,
    exportVulnsStatusFail: false,

    // ## Download export vulns status. -------------------------------------------------------------------------------
    downloadExportVulnsData: {},
    downloadExportVulnsPending: false,
    downloadExportVulnsDone: false,

    // ## All vulns dynamic view. ----------------------------------------------------------------------------
    allVulnsDynamicView: allVulnsDynamicViewDummyData,
    allVulnsDynamicViewTotal: 0,
    allVulnsDynamicViewPending: false,
    allVulnsDynamicViewDone: false,
    allVulnsDynamicViewFail: false,
    allVulnsDynamicViewFailMsg: null,

    // ## All cloud vulns table. -----------------------------------------------------------------------
    allCloudVulnsTable: dummyData,
    allCloudVulnsTableTotal: 0,
    allCloudVulnsTablePending: false,
    allCloudVulnsTableDone: false,
    allCloudVulnsTableFail: false,
    allCloudVulnsTableFailMsg: null,

    // ## Vulns count. -------------------------------------------------------------------------------
    vulnsCount: {
      appsec_count: 0,
      infra_count: 0,
      count: 0,
      total: 0,
    },
    vulnsCountPending: false,
    vulnsCountDone: false,

    // ## Master vulns table. ------------------------------------------------------------------------
    masterVulnsTable: dummyData,
    masterVulnsTablePerpage: VULN.PERPAGE,
    masterVulnsTableTotal: 0,
    masterVulnsTableMergeTotal: 0,
    masterVulnsTablePending: false,
    masterVulnsTableDone: false,
    masterVulnsTableFail: false,
    masterVulnsTableFailMsg: null,

    // ## Duplicates vulns table. --------------------------------------------------------------------
    duplicatesVulnsTable: dummyData,
    duplicatesVulnsTablePending: false,
    duplicatesVulnsTableDone: false,
    duplicatesVulnsTableFail: false,
    duplicatesVulnsTableFailMsg: null,

    // ## Vulns false possitive. ---------------------------------------------------------------------
    vulnAssignIssueData: null,
    vulnAssignIssuePending: false,
    vulnAssignIssueDone: false,
    vulnAssignIssueFail: false,
    vulnAssignIssueModalData: {},
    vulnAssignIssueModalVisible: false,

    // ## Vulns similar issue. -----------------------------------------------------------------------
    vulnSimilarIssueData: null,
    vulnSimilarIssuePending: false,
    vulnSimilarIssueDone: false,

    // ## Vulns assign similar issue. ----------------------------------------------------------------
    vulnAssignSimilarIssueData: null,
    vulnAssignSimilarIssuePending: false,
    vulnAssignSimilarIssueDone: false,
    vulnAssignSimilarIssueFail: false,

    // ## Vulns issue search. -----------------------------------------------------------------------
    vulnIssueSearchData: null,
    vulnIssueSearchPending: false,
    vulnIssueSearchDone: false,
    vulnIssueSearchFail: false,

    vulnIssueLinkData: null,
    vulnIssueLinkPending: false,
    vulnIssueLinkDone: false,
    vulnIssueLinkFail: false,

    // ## Vulns false possitive. ---------------------------------------------------------------------
    vulnFalsePositiveData: null,
    vulnFalsePositivePending: false,
    vulnFalsePositiveDone: false,
    vulnFalsePositiveFail: false,
    vulnFalsePositiveModalVisible: false,

    cancelVulnFalsePositivePending: false,
    cancelVulnFalsePositiveDone: false,
    cancelVulnFalsePositiveFail: false,

    // ## Vulns true possitive. ---------------------------------------------------------------------
    vulnTruePositiveData: null,
    vulnTruePositivePending: false,
    vulnTruePositiveDone: false,
    vulnTruePositiveFail: false,

    vulnTruePositiveModalData: {},
    vulnTruePositiveModalVisible: false,

    // ## Update risk accepted. ----------------------------------------------------------------------
    vulnRiskAcceptedData: null,
    vulnRiskAcceptedPending: false,
    vulnRiskAcceptedDone: false,
    vulnRiskAcceptedFail: false,
    vulnRiskAcceptedModalVisible: false,
    vulnRiskAcceptedModalData: {},

    cancelVulnRiskAcceptedPending: false,
    cancelVulnRiskAcceptedDone: false,
    cancelVulnRiskAcceptedFail: false,

    // ## Update risk accepted. ----------------------------------------------------------------------
    vulnRiskAcceptedDetail: null,
    vulnRiskAcceptedDetailPending: false,
    vulnRiskAcceptedDetailDone: false,

    // ## Approve risk accepted. ---------------------------------------------------------------------
    approveVulnRiskAcceptedData: null,
    approveVulnRiskAcceptedPending: false,
    approveVulnRiskAcceptedDone: false,

    // ## Reject risk accepted. ---------------------------------------------------------------------
    rejectVulnRiskAcceptedData: null,
    rejectVulnRiskAcceptedPending: false,
    rejectVulnRiskAcceptedDone: false,

    // ## Update vuln expired date. ------------------------------------------------------------------
    updateVulnExpiredDatePending: false,
    updateVulnExpiredDateDone: false,

    // ## Close vulns. -------------------------------------------------------------------------------
    vulnClosePending: false,
    vulnCloseDone: false,
    vulnCloseFail: false,
    vulnCloseModalVisible: false,
    vulnCloseModalData: {},

    // ## Multiple screenshot modal. -----------------------------------------------------------------
    vulnMultipleScreenshotModalVisible: false,
    vulnMultipleScreenshotModalData: {},

    // ## Update severity modal. -----------------------------------------------------------------
    vulnUpdateSeverityModalVisible: false,
    vulnUpdateSeverityModalData: {},

    // ## Vulns detail. ------------------------------------------------------------------------------
    vulnDetails: vulnDetailsDummy,
    vulnDetailsPending: false,
    vulnDetailsDone: false,

    // ## Vulns issue details. -----------------------------------------------------------------------
    vulnIssueDetails: [],
    vulnIssueDetailsPending: false,
    vulnIssueDetailsDone: false,

    // ## Upload screenshot. -------------------------------------------------------------------------
    uploadScreenshotPending: false,
    uploadScreenshotDone: false,
    uploadScreenshotFail: false,
    uploadScreenshotFailMsg: null,

    // ## Delete screenshot. -------------------------------------------------------------------------
    deleteScreenshotPending: false,
    deleteScreenshotDone: false,

    // ## Update vulns severity. ---------------------------------------------------------------------
    updateVulnsPending: false,
    updateVulnsDone: false,

    // ## Update vulns severity. ---------------------------------------------------------------------
    updateVulnsSeverityPending: false,
    updateVulnsSeverityDone: false,
    updateVulnsSeverityFail: false,

    // ## Update vulns cvss. -------------------------------------------------------------------------
    updateVulnsCvssPending: false,
    updateVulnsCvssDone: false,

    // ## Add vuln comment. ------------------------------------------------------------------------
    addVulnCommentPending: false,
    addVulnCommentDone: false,
    addVulnCommentFail: false,
    addVulnCommentFailMsg: null,

    // ## Vuln training details. ---------------------------------------------------------------------
    vulnTrainingDetails: [],
    vulnTrainingDetailsPending: false,
    vulnTrainingDetailsDone: false,

    vulnsTableColumns: [],

    vulnDetailId: null,
    vulnDetailVisible: false,

    selectedVulnRows: [],

    // ## Vuln recommendation details. ---------------------------------------------------------------
    vulnRecommendationDetails: {
      message: '',
    },
    vulnRecommendationDetailsError: null,
    vulnRecommendationDetailsPending: false,
    vulnRecommendationDetailsDone: false,

    // ## Vuln collaboration. ------------------------------------------------------------------------
    vulnCollaboration: vulnCollaborationDummy,
    vulnCollaborationPending: false,
    vulnCollaborationDone: false,
    vulnCollaborationFail: false,
    vulnCollaborationFailMsg: null,

    // ## Vuln issue manager info. ------------------------------------------------------------------------
    vulnIssueManagerInfo: [],
    vulnIssueManagerInfoPending: false,
    vulnIssueManagerInfoDone: false,
    vulnIssueManagerInfoFail: false,
    vulnIssueManagerInfoFailMsg: null,
  }),
  actions: {
    // ## Get all vulns table. -----------------------------------------------------------------------
    async getAllVulnsTable(data) {
      this.allVulnsTablePending = true;
      this.allVulnsTable = dummyData;

      try {
        const res = await VulnService.getAll(vulnParams(data));
        this.setGetAllVulnsTable({
          result: res.data.data,
          data,
          total: res.data,
        });
        this.allVulnsTableSettings = res.data.custom_list_options;
        this.allVulnsTableDone = !this.allVulnsTableDone;
        this.allVulnsTablePending = false;
      } catch (error) {
        this.allVulnsTable = dummyData;
        this.allVulnsTablePending = false;
        this.allVulnsTableFail = !this.allVulnsTableFail;
        this.allVulnsTableFailMsg = ErrorHandling(error);
      }
    },

    setGetAllVulnsTable(payload) {
      let data = [];
      let vulnsTableData = [];
      if (payload.data.id) {
        this.allVulnsScanData = payload.result.scan;
        vulnsTableData = payload.result.vulns;
        this.allVulnsScanDataTotal = payload.total.total;
      } else {
        vulnsTableData = payload.result;
      }
      data = vulnsTableData.map((s) => ({
        key: s.id,
        id: s.id,
        branch: s.scanparams.branch,
        committer: s.committer_name,
        cvss: s.cvss_v3.toFixed(1),
        cweId: s.cwe.cwe_id,
        cweName: s.cwe.name,
        defaultBranch: s.scanparams.project.default_branch,
        dockerImage: (s.docker_image ? s.docker_image : '-'),
        dueDate: s.sla && dayjs(s.sla.due_date).format('DD MMM YYYY'),
        environment: s.scanparams.environment,
        epss: s.ti.epss,
        falsePositive: s.is_fp,
        truePositive: s.is_tp,
        fpMarkDate: dayjs(s.fp_mark_date).format('DD MMM YYYY'),
        firstSeen: dayjs(s.first_seen).format('DD MMM YYYY'),
        hasComment: s.has_comment,
        hasFix: s.fixed_version_exist,
        fqdn: s.fqdn,
        manual: s.scanparams.manual,
        mergeStatus: s.merge_status,
        metaData: s.scanparams.meta_data,
        mitigated: s.is_mitigated,
        mscore: s.ti.m_score,
        name: s.name,
        observedInTheWild: s.ti.observedInTheWild,
        overdue: (s.sla ? s.sla : { overdue: false, rule: 0 }),
        owasp2017Top10: s.cwe.classification.owasp_2017.id ?
          s.cwe.classification.owasp_2017.name.replace('_', ' - ').replaceAll('_', ' ') : '-',
        owasp2021Top10: s.cwe.classification.owasp_2021.id ?
          s.cwe.classification.owasp_2021.name.replace('_', ' - ').replaceAll('_', ' ') : '-',
        knownExploit: s.ti.known_exploit,
        issueAssigned: s.issue_assigned,
        issueClosedDate: dayjs(s.issue_closed_date).format('DD MMM YYYY'),
        issueCreatedDate: dayjs(s.issue_created_date).format('DD MMM YYYY'),
        isMaster: s.is_master,
        issueStatus: s.issue_status,
        issueLink: s.issue_link,
        issueWIPDate: dayjs(s.issue_wip_date).format('DD MMM YYYY'),
        infraGroupNames: s.infra_group_names,
        labels: (s.scanparams.project.labels ? s.scanparams.project.labels.map((a) => ({
          id: a.id,
          name: a.name,
          color: `#${a.color}`,
        })) : []),
        lastSeen: dayjs(s.last_seen).format('DD MMM YYYY'),
        path: (s.path ? s.path : '-'),
        ip: (s.path ? s.path : '-'),
        pci: s.cwe.classification.pci_dss.id ?
          s.cwe.classification.pci_dss.name.replace('_', ' - ').replaceAll('_', ' ') : '-',
        project: s.scanparams.project.name,
        projectId: s.scanparams.project.id,
        raMarkDate: dayjs(s.ra_mark_date).format('DD MMM YYYY'),
        scanner: s.scanparams.tool.name,
        prefix: s.scanparams.tool.is_custom ? 'custom' : 'internal',
        scannerType: s.scanparams.tool.type,
        severity: s.severity,
        status: s.status,
        team: s.scanparams.project.owner.team.name,
        woe: s.woe,
        wontfix: s.is_wf,
      }));
      if (payload.data.itemId || payload.data.itemId === '') {
        this.allVulnsDynamicViewItems[payload.data.itemId] = data;
      } else {
        this.allVulnsTable = [];
        this.allVulnsTable = data;
      }
    },

    // ## Get all vulns table total. -----------------------------------------------------------------------
    async getAllVulnsTableTotal(data) {
      try {
        const res = await VulnService.getVulnsTotal(vulnParams(data));
        this.allVulnsTableTotal = res.data.total;
      } catch (error) {
        this.allVulnsTableTotal = 0;
        ErrorHandling(error);
      }
    },

    // ## Export vulns. ----------------------------------------------------------------------------------
    async exportVulns(data) {
      this.exportVulnsPending = true;

      try {
        const params = {
          id: data.id,
          type: data.type,
          prefix: data.prefix,
          query: {
            report_type: data.reportType,
            report_options: {
              include_fp: data.reportOptions.includeFP,
              include_tp: data.reportOptions.includeTP,
              include_ra: data.reportOptions.includeRA,
              include_comments: data.reportOptions.includeComments,
            },
            scan_ids: data.scanIds,
            filter: {
              ...vulnParams(data.filter).query,
            },
            table_view: data.tableView,
          },
        };
        delete params.query.filter.limit;
        delete params.query.filter.start;
        const res = await VulnService.exportVulns(params);
        this.exportVulnsData = res.data.data;
        this.exportVulnsDone = !this.exportVulnsDone;
        this.exportVulnsPending = false;
      } catch (error) {
        this.exportVulnsPending = false;
        this.exportVulnsFail = !this.exportVulnsFail;
        this.exportVulnsFailMsg = ErrorHandling(error);
      }
    },

    async exportVulnsStatus(params) {
      this.exportVulnsStatusPending = true;

      try {
        const res = await VulnService.exportVulnsStatus(params);
        this.exportVulnsStatusData = res.data.data;
        this.exportVulnsStatusDone = !this.exportVulnsStatusDone;
        this.exportVulnsStatusPending = false;
      } catch (error) {
        this.exportVulnsStatusPending = false;
        this.exportVulnsStatusFail = !this.exportVulnsStatusFail;
        ErrorHandling(error);
      }
    },

    async downloadExportVulns(params) {
      this.downloadExportVulnsPending = true;

      try {
        const res = await VulnService.downloadExportVulns(params);
        this.downloadExportVulnsData = res.data;
        this.downloadExportVulnsDone = !this.downloadExportVulnsDone;
        this.downloadExportVulnsPending = false;
      } catch (error) {
        this.downloadExportVulnsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get all vulns dynamic view. ------------------------------------------------------------------------
    async getAllVulnsDynamicView(data) {
      this.allVulnsDynamicViewPending = true;
      this.allVulnsDynamicView = allVulnsDynamicViewDummyData;

      try {
        const res = await VulnService.getAllVulnsDynamicView(vulnParams(data));
        this.allVulnsDynamicViewTotal = res.data.total;
        this.allVulnsTableSettings = res.data.custom_list_options;
        this.setGetAllVulnsDynamicView({
          result: res.data.data,
          data,
        });
        this.allVulnsDynamicViewDone = !this.allVulnsDynamicViewDone;
        this.allVulnsDynamicViewPending = false;
      } catch (error) {
        this.allVulnsDynamicView = allVulnsDynamicViewDummyData;
        this.allVulnsDynamicViewTotal = 0;
        this.allVulnsDynamicViewPending = false;
        this.allVulnsDynamicViewFail = !this.allVulnsDynamicViewFail;
        this.allVulnsDynamicViewFailMsg = ErrorHandling(error);
      }
    },

    setGetAllVulnsDynamicView(payload) {
      this.allVulnsDynamicView = payload.result.map((s) => ({
        id: btoa(s.name + s.id),
        name: s.name,
        count: s.count,
        nodeCount: s.node_count,
        maxSeverity: s.max_severity,
        summary: s.summary,
        tool: {
          isCustom: s.tool.is_custom,
          manual: s.tool.is_manual,
        },
        vulns: s.vulns.map((a) => ({
          id: btoa(a.id + a.name),
          name: a.name,
          cweId: a.cwe_id,
          count: a.count,
          maxSeverity: a.max_severity,
          summary: a.summary,
          tool: {
            isCustom: s.tool.is_custom,
            manual: s.tool.is_manual,
          },
        })),
      }));
    },

    // ## Get all cloud vulns. -------------------------------------------------------------------------
    async getAllCloudVulnsTable(data) {
      this.allCloudVulnsTablePending = true;
      this.allCloudVulnsTable = dummyData;

      try {
        const params = {
          type: data.type,
          prefix: data.prefix,
          query: {
            limit: data.options.perPage,
            start: (data.options.page - 1) * data.options.perPage,
            sort_by: data.options.sort,
            has_cisa_kev_exploit: data.options.cisaKevExploit ? `${data.options.cisaKevExploit.split(':')[0]}:${data.options.cisaKevExploit.split(':')[1] === 'yes'}` : null,
            cloud_platform: data.options.platform,
            is_closed: data.options.closed ? `${data.options.closed.split(':')[0]}:${data.options.closed.split(':')[1] === 'yes'}` : null,
            has_exploit: data.options.exploit ? `${data.options.exploit.split(':')[0]}:${data.options.exploit.split(':')[1] === 'yes'}` : null,
            image_name: data.options.imageName,
            name: data.options.name,
            region: data.options.region,
            severity: data.options.severity,
            open_to_internet: data.options.openToInternet ? `${data.options.openToInternet.split(':')[0]}:${data.options.openToInternet.split(':')[1] === 'yes'}` : null,
            operating_system: data.options.os,
            ip_addresses: data.options.ipAddresses,
            project: data.options.project,
          },
        };
        const res = await VulnService.getAll(params);
        this.setGetAllCloudtVulnsTable(res.data);
        this.allCloudVulnsTableTotal = res.data.total;
        this.allCloudVulnsTableDone = !this.allCloudVulnsTableDone;
        this.allCloudVulnsTablePending = false;
      } catch (error) {
        this.allCloudVulnsTable = dummyData;
        this.allCloudVulnsTablePending = false;
        this.allCloudVulnsTableFail = !this.allCloudVulnsTableFail;
        this.allCloudVulnsTableFailMsg = ErrorHandling(error);
      }
    },

    setGetAllCloudtVulnsTable(payload) {
      this.allCloudVulnsTable = [];

      if (!payload.cloud_vulnerabilities) {
        return;
      }

      this.allCloudVulnsTable = payload.cloud_vulnerabilities.map((s) => ({
        key: s.id,
        id: s.id,
        cloudId: s.vulnerability.cloud_id,
        name: s.vulnerability.name,
        locationPath: s.vulnerability.location_path,
        imageId: s.vulnerability.image_id,
        imageName: s.vulnerability.image_name,
        status: s.vulnerability.status,
        cveId: s.vulnerability.cve,
        severity: s.vulnerability.severity,
        currentVersion: s.vulnerability.current_version,
        fixedVersion: s.vulnerability.fixed_version,
        remediation: s.vulnerability.remediation,
        hasExploit: s.vulnerability.has_exploit,
        hasCisaKevExploit: s.vulnerability.has_cisa_kev_exploit,
        cveLink: s.vulnerability.cve_link,
        cloudToolUrl: s.vulnerability.cloud_tool_url,
        detectionMethod: s.vulnerability.detection_method,
        assetId: s.vulnerability.asset_id,
        assetName: s.vulnerability.asset_name,
        platform: s.vulnerability.cloud_platform,
        region: s.vulnerability.region,
        subscriptionId: s.vulnerability.subscription_id,
        firstDetected: dayjs(s.vulnerability.first_detected).format('DD MMM YYYY'),
        lastDetected: dayjs(s.vulnerability.last_detected).format('DD MMM YYYY'),
        os: s.vulnerability.operating_system,
        openToInternet: s.vulnerability.open_to_internet,
        ipAddresses: s.vulnerability.ip_addresses,
        projects: s.projects,
      }));
    },

    // ## Get vulns count. ---------------------------------------------------------------------------
    async getVulnsCount(data) {
      this.vulnsCountPending = true;

      try {
        const res = await VulnService.getVulnsCount(vulnParams(data));
        this.vulnsCount = res.data;
        this.vulnsCountDone = !this.vulnsCountDone;
        this.vulnsCountPending = false;
      } catch (error) {
        this.vulnsCountPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get master vulns table. --------------------------------------------------------------------
    async getMasterVulnsTable(data) {
      this.masterVulnsTablePending = true;

      try {
        const params = {
          limit: this.masterVulnsTablePerpage,
          start: (data.page - 1) * this.masterVulnsTablePerpage,
          sort_by: data.sort,
          project_ids: data.projectId,
          id: data.id,
          type: data.type,
          branch: data.branch,
          manual: data.manual ? `${data.manual.split(':')[0]}:${data.manual.split(':')[1] === 'yes'}` : null,
          path: data.filePath,
          tool_name: data.scanner,
          projects: data.project,
          severity: data.severity,
        };
        const res = await VulnService.getMasterVulnsTable(params);
        this.masterVulnsTableTotal = res.data.total;
        this.masterVulnsTableMergeTotal = res.data.merge_total;
        this.setGetMasterVulnsTable(res.data);
        this.masterVulnsTableDone = !this.masterVulnsTableDone;
        this.masterVulnsTablePending = false;
      } catch (error) {
        this.masterVulnsTable = dummyData;
        this.masterVulnsTableTotal = 0;
        this.masterVulnsTablePending = false;
        this.masterVulnsTableFail = !this.masterVulnsTableFail;
        this.masterVulnsTableFailMsg = ErrorHandling(error);
      }
    },

    setGetMasterVulnsTable(payload) {
      this.masterVulnsTable = [];
      this.masterVulnsTable = payload.data.map((s) => ({
        key: s.id,
        id: s.id,
        branch: s.scanparams.branch,
        committer: s.committer_name,
        cweId: s.cwe.cwe_id,
        cweName: s.cwe.name,
        falsePositive: s.is_fp,
        labels: (s.scanparams.project.labels ? s.scanparams.project.labels.map((a) => ({
          id: a.id,
          name: a.name,
          color: `#${a.color}`,
        })) : []),
        manual: s.scanparams.manual,
        mergeCount: s.merge_count - 1,
        name: s.name,
        path: s.path,
        project: s.scanparams.project.name,
        issueAssigned: s.issue_assigned,
        issueStatus: s.issue_status,
        scannerType: s.scanparams.tool.type,
        scanner: s.scanparams.tool.name,
        prefix: s.scanparams.tool.is_custom ? 'custom' : 'internal',
        severity: s.severity,
        status: s.status,
        woe: s.woe,
      }));
    },

    // ## Get duplicates vulns table. ----------------------------------------------------------------
    async getDuplicatesVulnsTable(data) {
      this.duplicatesVulnsTablePending = true;

      try {
        const res = await VulnService.getDuplicatesVulnsTable(data);
        this.setGetDuplicatesVulnsTable(res.data.data);
        this.duplicatesVulnsTableDone = !this.duplicatesVulnsTableDone;
        this.duplicatesVulnsTablePending = false;
      } catch (error) {
        this.duplicatesVulnsTable = dummyData;
        this.duplicatesVulnsTablePending = false;
        this.duplicatesVulnsTableFail = !this.duplicatesVulnsTableFail;
        this.duplicatesVulnsTableFailMsg = ErrorHandling(error);
      }
    },

    setGetDuplicatesVulnsTable(payload) {
      this.duplicatesVulnsTable = [];
      this.duplicatesVulnsTable = payload.map((s) => ({
        key: s.id,
        id: s.id,
        branch: s.scanparams.branch,
        committer: s.committer_name,
        cweId: s.cwe.cwe_id,
        cweName: s.cwe.name,
        falsePositive: s.is_fp,
        labels: (s.scanparams.project.labels ? s.scanparams.project.labels.map((a) => ({
          id: a.id,
          name: a.name,
          color: `#${a.color}`,
        })) : []),
        manual: s.scanparams.manual,
        mergeCount: s.merge_count,
        name: s.name,
        issueAssigned: s.issue_assigned,
        issueStatus: s.issue_status,
        path: s.path,
        project: s.scanparams.project.name,
        scannerType: s.scanparams.tool.type,
        scanner: s.scanparams.tool.name,
        prefix: s.scanparams.tool.is_custom ? 'custom' : 'internal',
        severity: s.severity,
        status: s.status,
        woe: s.woe,
      }));
    },

    resetDuplicatesVulnsTable() {
      if (this.masterVulnsTable.length > 0) {
        this.duplicatesVulnsTable = dummyData;
      } else {
        this.duplicatesVulnsTable = [];
      }
    },

    // ## Vuln assign issue. ---------------------------------------------------------------------
    async vulnAssignIssue(data) {
      this.vulnAssignIssuePending = true;

      try {
        const res = await VulnService.vulnAssignIssue(data);
        this.vulnAssignIssueData = res.data;
        this.vulnAssignIssueDone = !this.vulnAssignIssueDone;
        this.vulnAssignIssuePending = false;
      } catch (error) {
        this.vulnAssignIssueFail = !this.vulnAssignIssueFail;
        this.vulnAssignIssuePending = false;
        ErrorHandling(error);
      }
    },

    showVulnAssignIssueModal(data) {
      this.vulnAssignIssueModalData = data;
      this.vulnAssignIssueModalVisible = true;
    },

    closeVulnAssignIssueModal() {
      this.vulnAssignIssueModalData = {};
      this.vulnAssignIssueModalVisible = false;
    },

    // ## Vuln get similar issue. -------------------------------------------------------------------
    async getVulnSimilarIssue(data) {
      this.vulnSimilarIssuePending = true;

      try {
        const res = await VulnService.getVulnSimilarIssue(data);
        this.vulnSimilarIssueData = res.data;
        this.vulnSimilarIssueDone = !this.vulnSimilarIssueDone;
        this.vulnSimilarIssuePending = false;
      } catch (error) {
        this.vulnSimilarIssuePending = false;
        ErrorHandling(error);
      }
    },

    // ## Vuln assign similar issue. -----------------------------------------------------------------
    async vulnAssignSimilarIssue(data) {
      this.vulnAssignSimilarIssuePending = true;

      try {
        const res = await VulnService.vulnAssignSimilarIssue(data);
        this.vulnAssignSimilarIssueData = res.data;
        this.vulnAssignSimilarIssueDone = !this.vulnAssignSimilarIssueDone;
        this.vulnAssignSimilarIssuePending = false;
      } catch (error) {
        this.vulnAssignSimilarIssueFail = !this.vulnAssignSimilarIssueFail;
        this.vulnAssignSimilarIssuePending = false;
        ErrorHandling(error);
      }
    },

    // ## Vuln issue search. ------------------------------------------------------------------------
    async vulnIssueSearch(data) {
      this.vulnIssueSearchPending = true;

      try {
        const res = await VulnService.vulnIssueSearch(data);
        this.vulnIssueSearchData = res.data;
        this.vulnIssueSearchDone = !this.vulnIssueSearchDone;
        this.vulnIssueSearchPending = false;
      } catch (error) {
        this.vulnIssueSearchData = null;
        this.vulnIssueSearchFail = !this.vulnIssueSearchFail;
        this.vulnIssueSearchPending = false;
        ErrorHandling(error);
      }
    },

    async vulnIssueLink(data) {
      this.vulnIssueLinkPending = true;

      try {
        const res = await VulnService.vulnIssueLink(data);
        this.vulnIssueLinkData = res.data;
        this.vulnIssueLinkDone = !this.vulnIssueLinkDone;
        this.vulnIssueLinkPending = false;
      } catch (error) {
        this.vulnIssueLinkFail = !this.vulnIssueLinkFail;
        this.vulnIssueLinkPending = false;
        ErrorHandling(error);
      }
    },

    resetVulnIssueSearch() {
      this.vulnIssueSearchData = null;
    },

    // ## Vulns false possitive. ---------------------------------------------------------------------
    async vulnFalsePositive(data) {
      this.vulnFalsePositivePending = true;

      try {
        const res = await VulnService.patchVuln(data);
        this.vulnFalsePositiveData = res.data;
        this.vulnFalsePositiveDone = !this.vulnFalsePositiveDone;
        this.vulnFalsePositivePending = false;
      } catch (error) {
        this.vulnFalsePositiveFail = !this.vulnFalsePositiveFail;
        this.vulnFalsePositivePending = false;
        ErrorHandling(error);
      }
    },

    async cancelVulnFalsePositive(data) {
      this.cancelVulnFalsePositivePending = true;

      try {
        await VulnService.cancelVulnFalsePositive(data);
        this.cancelVulnFalsePositiveDone = !this.cancelVulnFalsePositiveDone;
        this.cancelVulnFalsePositivePending = false;
      } catch (error) {
        this.cancelVulnFalsePositiveFail = !this.cancelVulnFalsePositiveFail;
        this.cancelVulnFalsePositivePending = false;
        ErrorHandling(error);
      }
    },

    resetVulnFalsePositive() {
      this.vulnFalsePositiveData = null;
    },

    showVulnFalsePositiveModal(data) {
      this.vulnFalsePositiveModalData = data;
      this.vulnFalsePositiveModalVisible = true;
    },

    closeVulnFalsePositiveModal() {
      this.vulnFalsePositiveModalData = {};
      this.vulnFalsePositiveModalVisible = false;
    },

    // ## Vulns true possitive. ---------------------------------------------------------------------
    async vulnTruePositive(data) {
      this.vulnTruePositivePending = true;

      try {
        const res = await VulnService.vulnTruePositive(data);
        this.vulnTruePositiveData = res.data;
        this.vulnTruePositiveDone = !this.vulnTruePositiveDone;
        this.vulnTruePositivePending = false;
      } catch (error) {
        this.vulnTruePositiveFail = !this.vulnTruePositiveFail;
        this.vulnTruePositivePending = false;
        ErrorHandling(error);
      }
    },

    showVulnTruePositiveModal(data) {
      this.vulnTruePositiveModalData = data;
      this.vulnTruePositiveModalVisible = true;
    },

    closeVulnTruePositiveModal() {
      this.vulnTruePositiveModalData = {};
      this.vulnTruePositiveModalVisible = false;
    },

    resetVulnTruePositive() {
      this.vulnTruePositiveData = null;
    },

    // ## Update risk accepted. ----------------------------------------------------------------------
    async vulnRiskAccepted(data) {
      this.vulnRiskAcceptedPending = true;

      try {
        const res = await VulnService.vulnRiskAccepted(data);
        this.vulnRiskAcceptedData = res.data;
        this.vulnRiskAcceptedDone = !this.vulnRiskAcceptedDone;
        this.vulnRiskAcceptedPending = false;
      } catch (error) {
        this.vulnRiskAcceptedPending = false;
        ErrorHandling(error);
      }
    },

    async cancelVulnRiskAccepted(data) {
      this.cancelVulnRiskAcceptedPending = true;

      try {
        await VulnService.cancelVulnRiskAccepted(data);
        this.cancelVulnRiskAcceptedDone = !this.cancelVulnRiskAcceptedDone;
        this.cancelVulnRiskAcceptedPending = false;
      } catch (error) {
        this.cancelVulnRiskAcceptedPending = false;
        this.cancelVulnRiskAcceptedFail = !this.cancelVulnRiskAcceptedFail;
        ErrorHandling(error);
      }
    },

    resetVulnRiskAccepted() {
      this.vulnRiskAcceptedData = null;
    },

    showVulnRiskAcceptedModal(data) {
      this.vulnRiskAcceptedModalData = data;
      this.vulnRiskAcceptedModalVisible = true;
    },

    closeVulnRiskAcceptedModal() {
      this.vulnRiskAcceptedModalVisible = false;
    },

    // ## Get vulne risk accepted detail. ------------------------------------------------------------
    async getVulnRiskAcceptedDetail(data) {
      this.vulnRiskAcceptedDetailPending = true;

      try {
        const res = await VulnService.getVulnRiskAcceptedDetail(data);
        this.setGetVulnRiskAcceptedDetail(res.data);
        this.vulnRiskAcceptedDetailDone = !this.vulnRiskAcceptedDetailDone;
        this.vulnRiskAcceptedDetailPending = false;
      } catch (error) {
        this.vulnRiskAcceptedDetailPending = false;
        ErrorHandling(error);
      }
    },

    setGetVulnRiskAcceptedDetail(payload) {
      this.vulnRiskAcceptedDetail = {};
      this.vulnRiskAcceptedDetail = {
        id: payload.data.id,
        expired: {
          date: dayjs(payload.data.expiry_date).format('DD MMM YYYY HH:mm'),
          status: payload.data.no_expiration,
        },
        requested: {
          name: payload.data.owner.user.username,
          privilege: payload.data.owner.user.role.privilege,
          status: payload.data.request_status,
          date: dayjs(payload.data.requested_at).format('DD MMM YYYY HH:mm'),
          text: payload.data.request_text,
        },
        replied: {
          name: payload.data.replied_by.username,
          text: (payload.data.reply_text !== '' ? payload.data.reply_text : null),
          date: dayjs(payload.data.replied_at).format('DD MMM YYYY HH:mm'),
        },
        type: payload.data.request_type,
        vIsMitigated: payload.data.vuln_detail.is_mitigated,
        vIsWf: payload.data.vuln_detail.is_wf,
      };
    },

    resetVulnRiskAcceptedDetail() {
      this.vulnRiskAcceptedDetail = null;
    },

    // ## Approve vulne risk accepted. ---------------------------------------------------------------
    async approveVulnRiskAccepted(data) {
      this.approveVulnRiskAcceptedPending = true;

      try {
        const res = await VulnService.approveVulnRiskAccepted(data);
        this.approveVulnRiskAcceptedData = res.data;
        this.approveVulnRiskAcceptedDone = !this.approveVulnRiskAcceptedDone;
        this.approveVulnRiskAcceptedPending = false;
      } catch (error) {
        this.approveVulnRiskAcceptedPending = false;
        ErrorHandling(error);
      }
    },

    // ## Reject vulne risk accepted detail. --------------------------------------------------------
    async rejectVulnRiskAccepted(data) {
      this.rejectVulnRiskAcceptedPending = true;

      try {
        const res = await VulnService.rejectVulnRiskAccepted(data);
        this.rejectVulnRiskAcceptedData = res.data;
        this.rejectVulnRiskAcceptedDone = !this.rejectVulnRiskAcceptedDone;
        this.rejectVulnRiskAcceptedPending = false;
      } catch (error) {
        this.rejectVulnRiskAcceptedPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update vuln expired date. ------------------------------------------------------------------
    async updateVulnExpiredDate(data) {
      this.updateVulnExpiredDatePending = true;

      try {
        await VulnService.updateVulnExpiredDate(data);
        this.updateVulnExpiredDateDone = !this.updateVulnExpiredDateDone;
        this.updateVulnExpiredDatePending = false;
      } catch (error) {
        this.updateVulnExpiredDatePending = false;
        ErrorHandling(error);
      }
    },

    // ## Close vulns. ----------------------------------------------------------------------------
    async vulnClose(data) {
      this.vulnClosePending = true;

      try {
        await VulnService.vulnClose(data);
        this.vulnCloseDone = !this.vulnCloseDone;
        this.vulnClosePending = false;
      } catch (error) {
        this.vulnClosePending = false;
        ErrorHandling(error);
      }
    },

    showVulnCloseModal(data) {
      this.vulnCloseModalData = data;
      this.vulnCloseModalVisible = true;
    },

    closeVulnCloseModal() {
      this.vulnCloseModalData = {};
      this.vulnCloseModalVisible = false;
    },

    // ## Multiple screenshot modal. -----------------------------------------------------------------
    showVulnMultipleScreenshotModal(data) {
      this.vulnMultipleScreenshotModalData = data;
      this.vulnMultipleScreenshotModalVisible = true;
    },

    closeVulnMultipleScreenshotModal() {
      this.vulnMultipleScreenshotModalData = {};
      this.vulnMultipleScreenshotModalVisible = false;
    },

    // ## Update severity modal. -----------------------------------------------------------------
    showVulnUpdateSeverityModal(data) {
      this.vulnUpdateSeverityModalData = data;
      this.vulnUpdateSeverityModalVisible = true;
    },

    closeVulnUpdateSeverityModal() {
      this.vulnUpdateSeverityModalData = {};
      this.vulnUpdateSeverityModalVisible = false;
    },

    // ## Vuln Details. ------------------------------------------------------------------------------
    async getVulnDetails(data) {
      this.vulnDetailsPending = true;

      try {
        const res = await VulnService.getVulnDetails(data);
        this.setGetVulnDetails(res.data.data);
        this.vulnDetailsDone = !this.vulnDetailsDone;
        this.vulnDetailsPending = false;
      } catch (error) {
        this.vulnDetailsPending = false;
        ErrorHandling(error);
      }
    },

    setGetVulnDetails(payload) {
      this.vulnDetails = {};

      const formatOwasp2017 = () => {
        if (
          payload.detail.cwe.classification.owasp_2017 &&
          !payload.detail.cwe.classification.owasp_2017.name
        ) {
          return '-';
        }
        return payload.detail.cwe.classification.owasp_2017.name.replace('_', ' - ').replaceAll('_', ' ');
      };

      const formatOwasp2021 = () => {
        if (
          !payload.detail.cwe.classification.owasp_2021 ||
          (payload.detail.cwe.classification.owasp_2021 &&
          !payload.detail.cwe.classification.owasp_2021.name)
        ) {
          return '-';
        }
        return payload.detail.cwe.classification.owasp_2021.name.replace('_', ' - ').replaceAll('_', ' ');
      };

      const formatPci = () => {
        if (
          payload.detail.cwe.classification.pci_dss &&
          !payload.detail.cwe.classification.pci_dss.name
        ) {
          return '-';
        }
        return payload.detail.cwe.classification.pci_dss.name.replace('_', ' - ').replaceAll('_', ' ');
      };

      const formatNist = () => {
        if (
          payload.detail.cwe.classification.nist &&
          !payload.detail.cwe.classification.nist.name
        ) {
          return '-';
        }
        return payload.detail.cwe.classification.nist.name.replace('_', ' - ').replaceAll('_', ' ');
      };

      const setCommiter = () => {
        const data = {};
        if (payload.detail.sast.ok) {
          if (payload.detail.sast.commit_detail.commit_link) {
            data.link = payload.detail.sast.commit_detail.commit_link;
          }
          if (payload.detail.sast.commit_detail.commit_no) {
            data.committerNo = payload.detail.sast.commit_detail.commit_no;
          }
          if (payload.detail.sast.commit_detail.committer) {
            data.committer = payload.detail.sast.commit_detail.committer;
          }
          if (payload.detail.sast.commit_detail.committer_name) {
            data.name = payload.detail.sast.commit_detail.committer_name;
          }
          if (payload.detail.sast.commit_detail.snippet) {
            data.snippet = payload.detail.sast.commit_detail.snippet;
          }
        } else if (payload.detail.iac.ok) {
          if (payload.detail.iac.commit_detail.commit_link) {
            data.link = payload.detail.iac.commit_detail.commit_link;
          }
          if (payload.detail.iac.commit_detail.commit_no) {
            data.committerNo = payload.detail.iac.commit_detail.commit_no;
          }
          if (payload.detail.iac.commit_detail.committer) {
            data.committer = payload.detail.iac.commit_detail.committer;
          }
          if (payload.detail.iac.commit_detail.committer_name) {
            data.name = payload.detail.iac.commit_detail.committer_name;
          }
          if (payload.detail.iac.commit_detail.snippet) {
            data.snippet = payload.detail.iac.commit_detail.snippet;
          }
        }
        return data;
      };

      const setCodeFlow = () => {
        let data = [];
        if (payload.detail.sast.ok) {
          if (payload.detail.sast.flow && payload.detail.sast.flow.nodes) {
            data = payload.detail.sast.flow.nodes.map((s) => ({
              name: s.Filename.split('/').slice(-1)[0],
              file: s.Filename,
              line: s.Line,
              message: s.Message,
              code: s.Code,
            }));
          }
        }
        return data;
      };

      this.vulnDetails = {
        id: payload.vulnerability.id,
        name: payload.vulnerability.name,
        almFileLink: payload.detail.alm_file_link,
        branch: payload.vulnerability.scanparams.branch,
        cvss: payload.vulnerability.cvss_v3.toFixed(1),
        cvssVector: (payload.detail.cvss && payload.detail.cvss.vector !== '' ? payload.detail.cvss.vector : null),
        changelogs: payload.detail.change_logs,
        collaboration: payload.vulnerability.collaboration_enabled,
        createdUserId: payload.vulnerability.scanparams.created_by ?
          payload.vulnerability.scanparams.created_by.id : null,
        description: payload.detail.description,
        recommendation: payload.detail.recommendation ? payload.detail.recommendation : null,
        aiRecommendation: payload.vulnerability.ai_recommendation,
        fp: {
          status: payload.vulnerability.is_fp,
          request: payload.detail.fp_request_status,
        },
        tp: {
          status: payload.vulnerability.is_tp,
        },
        firstSeen: dayjs(payload.vulnerability.first_seen).format('DD MMM YYYY HH:mm'),
        lastSeen: dayjs(payload.vulnerability.last_seen).format('DD MMM YYYY HH:mm'),
        link: payload.detail.link,
        manual: payload.vulnerability.scanparams.manual,
        metaData: payload.vulnerability.scanparams.meta_data,
        issue: payload.detail.issue,
        isMitigated: payload.vulnerability.is_mitigated,
        isWf: payload.vulnerability.is_wf,
        infraGroupNames: payload.vulnerability.infra_group_names,
        project: payload.vulnerability.scanparams.project,
        path: payload.vulnerability.path,
        screenshots: payload.detail.screenshots,
        severity: payload.vulnerability.severity,
        status: payload.vulnerability.status,
        statusChanges: payload.vulnerability.status_changes.map((s) => ({
          name: s.name,
          end: (s.end ? dayjs(s.end).format('DD MMM YYYY HH:mm') : null),
          start: dayjs(s.start).format('DD MMM YYYY HH:mm'),
          ...(s.reason && { reason: s.reason.charAt(0).toUpperCase() + s.reason.slice(1) }),
        })),
        riskAccepted: {
          wontfix: payload.vulnerability.is_wf,
          mitigated: payload.vulnerability.is_mitigated,
          status: payload.detail.ra_request_status,
        },
        tool: {
          name: payload.vulnerability.scanparams.tool.name,
          type: payload.vulnerability.scanparams.tool.type,
        },
        prefix: payload.vulnerability.scanparams.tool.is_custom ? 'custom' : 'internal',
        scannerType: payload.vulnerability.scanparams.tool.type,
        tags: payload.detail.tags ? payload.detail.tags : null,
        ti: payload.vulnerability.ti,
        standarts: {
          cwe: payload.detail.cwe,
          owasp2017: formatOwasp2017(),
          owasp2021: formatOwasp2021(),
          pci: formatPci(),
          nist: formatNist(),
          stride: [
            {
              strideS: payload.detail.cwe.stride.s,
              strideT: payload.detail.cwe.stride.t,
              strideR: payload.detail.cwe.stride.r,
              strideI: payload.detail.cwe.stride.i,
              strideD: payload.detail.cwe.stride.d,
              strideE: payload.detail.cwe.stride.e,
            },
          ],
        },
      };
      if (payload.detail.comment) {
        this.vulnDetails.comment = {
          text: payload.detail.comment.text,
          lastEdited: dayjs(payload.detail.comment.last_edited).format('DD MMM YYYY'),
        };
      }
      if (payload.detail.sast.ok) {
        this.vulnDetails.sast = {
          codeFlow: setCodeFlow(),
          committer: setCommiter(),
          code: payload.detail.sast.code,
          codeLines: payload.detail.sast.code_lines,
          language: payload.detail.sast.language,
          line: payload.detail.sast.line_number,
          fileName: payload.detail.sast.file_name,
          matches: payload.detail.sast.matches,
        };
      } else if (payload.detail.dast.ok) {
        this.vulnDetails.dast = {
          method: payload.detail.dast.method,
          request: payload.detail.dast.http_request,
          response: payload.detail.dast.http_response,
          target: payload.detail.dast.target,
          references: payload.detail.dast.references,
          params: payload.detail.dast.param,
          plugin: payload.detail.dast.plugin,
          confidence: payload.detail.dast.confidence,
        };
      } else if (payload.detail.pentest && payload.detail.pentest.ok) {
        this.vulnDetails.pentest = {
          method: payload.detail.pentest.method,
          request: payload.detail.pentest.http_request,
          response: payload.detail.pentest.http_response,
          target: payload.detail.pentest.target,
          references: payload.detail.pentest.references,
          params: payload.detail.pentest.param,
          plugin: payload.detail.pentest.plugin,
        };
      } else if (payload.detail.sca.ok) {
        this.vulnDetails.sca = {
          fileName: payload.detail.sca.file_name,
          packages: payload.detail.sca.packages,
          license: payload.detail.sca.license,
          references: payload.detail.sca.references,
        };
      } else if (payload.detail.cs.ok) {
        this.vulnDetails.cs = {
          cve: payload.detail.cs.cve,
          package: payload.detail.cs.packages ? payload.detail.cs.packages[0].name : null,
          target: payload.detail.cs.target,
          ...(payload.detail.cs.workload && {
            workload: {
              cluster: payload.detail.cs.workload.cluster,
              kind: payload.detail.cs.workload.kind,
              name: payload.detail.cs.workload.name,
              namespace: payload.detail.cs.workload.namespace,
            },
          }),
          ...(payload.detail.cs.workload && { riskFaktors: payload.detail.cs.workload.risk_faktors }),
          installed: payload.detail.cs.packages ?
            payload.detail.cs.packages[0].installed_version : null,
          fixed: payload.detail.cs.packages ? payload.detail.cs.packages[0].fixed_version : null,
          references: payload.detail.cs.references ? payload.detail.cs.references : null,
        };
      } else if (payload.detail.iac.ok) {
        this.vulnDetails.iac = {
          committer: setCommiter(),
          fileName: payload.detail.iac.file_name,
          references: payload.detail.iac.references,
          line: payload.detail.iac.line_number,
          codeLines: payload.detail.iac.lines,
          code: payload.detail.iac.code,
        };
      } else if (payload.detail.infra.ok) {
        this.vulnDetails.infra = payload.detail.infra;
      } else if (payload.detail.bug_bounty.ok) {
        this.vulnDetails.bugBounty = {
          ok: payload.detail.bug_bounty.ok,
          submitter: payload.detail.bug_bounty.submitter,
          submittedAt: dayjs(payload.detail.bug_bounty.submitted_at).format('DD MMM YYYY'),
          target: payload.detail.bug_bounty.target,
          bountyValue: payload.detail.bug_bounty.bounty_value,
          bountyCurrency: payload.detail.bug_bounty.bounty_currency,
        };
      } else if (payload.detail.iast.ok) {
        this.vulnDetails.iast = {
          ok: payload.detail.iast.ok,
          codeLocation: payload.detail.iast.code_location,
          httpHeaders: payload.detail.iast.http_headers,
          httpParams: payload.detail.iast.http_params,
          path: payload.detail.iast.path,
          remediation: payload.detail.iast.remediation,
          stackTrace: payload.detail.iast.stack_trace,
          summary: payload.detail.iast.summary,
          verified: payload.detail.iast.verified,
          nameSpace: payload.detail.iast.name_space,
        };
      } else if (payload.detail.cspm.ok) {
        this.vulnDetails.cspm = payload.detail.cspm;
      } else if (payload.detail.mast.ok) {
        this.vulnDetails.mast = payload.detail.mast;
      }
    },

    // ## Vuln Issue Details. ------------------------------------------------------------------------
    async getVulnIssueDetails(data) {
      this.vulnIssueDetailsPending = true;

      try {
        const res = await VulnService.getVulnIssueDetails(data);
        this.vulnIssueDetails = (res.data.data || []);
        this.vulnIssueDetailsDone = !this.vulnIssueDetailsDone;
        this.vulnIssueDetailsPending = false;
      } catch (error) {
        this.vulnIssueDetailsPending = false;
        this.duplicatesVulnsTableFailMsg = ErrorHandling(error);
      }
    },

    // ## Upload screenshot. -------------------------------------------------------------------------
    async uploadScreenshot(data) {
      this.uploadScreenshotPending = true;

      try {
        await VulnService.uploadScreenshot(data);
        this.uploadScreenshotDone = !this.uploadScreenshotDone;
        this.uploadScreenshotPending = false;
      } catch (error) {
        this.uploadScreenshotFail = !this.uploadScreenshotFail;
        this.uploadScreenshotPending = false;
        this.uploadScreenshotFailMsg = ErrorHandling(error);
      }
    },

    // ## Delete screenshot. -------------------------------------------------------------------------
    async deleteScreenshot(data) {
      this.deleteScreenshotPending = true;

      try {
        await VulnService.deleteScreenshot(data);
        this.deleteScreenshotDone = !this.deleteScreenshotDone;
        this.deleteScreenshotPending = false;
      } catch (error) {
        this.deleteScreenshotPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update vulns severity. ---------------------------------------------------------------------
    async updateVulns(data) {
      this.updateVulnsPending = true;

      try {
        await VulnService.updateVulns(data);
        this.updateVulnsDone = !this.updateVulnsDone;
        this.updateVulnsPending = false;
      } catch (error) {
        this.updateVulnsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update vulns severity. ---------------------------------------------------------------------
    async updateVulnsSeverity(data) {
      this.updateVulnsSeverityPending = true;

      try {
        await VulnService.updateVulnsSeverity(data);
        this.updateVulnsSeverityDone = !this.updateVulnsSeverityDone;
        this.updateVulnsSeverityPending = false;
      } catch (error) {
        this.updateVulnsSeverityPending = false;
        this.updateVulnsSeverityFail = !this.updateVulnsSeverityFail;
        ErrorHandling(error);
      }
    },

    // ## Update vulns cvss. -------------------------------------------------------------------------
    async updateVulnsCvss(data) {
      this.updateVulnsCvssPending = true;

      try {
        await VulnService.updateVulnsCvss(data);
        this.updateVulnsCvssDone = !this.updateVulnsCvssDone;
        this.updateVulnsCvssPending = false;
      } catch (error) {
        this.updateVulnsCvssPending = false;
        ErrorHandling(error);
      }
    },

    // ## Add vuln comment. --------------------------------------------------------------------------
    async addVulnComment(data) {
      this.addVulnCommentPending = true;

      try {
        await VulnService.addVulnComment(data);
        this.addVulnCommentDone = !this.addVulnCommentDone;
        this.addVulnCommentPending = false;
      } catch (error) {
        this.addVulnCommentFail = !this.addVulnCommentFail;
        this.addVulnCommentPending = false;
        this.addVulnCommentFailMsg = ErrorHandling(error);
      }
    },

    // ## Get vuln training details. -----------------------------------------------------------------
    async getVulnTrainingDetails(data) {
      this.vulnTrainingDetailsPending = true;
      this.vulnTrainingDetails = [];

      try {
        const res = await VulnService.getVulnTrainingDetails(data);
        this.vulnTrainingDetails = res.data.data;
        this.vulnTrainingDetailsDone = !this.vulnTrainingDetailsDone;
        this.vulnTrainingDetailsPending = false;
      } catch (error) {
        this.vulnTrainingDetailsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get vulns table columns. ------------------------------------------------------------------
    getVulnsTableColumns(data) {
      this.vulnsTableColumns = [];
      if (
        !localStorage['kondukto-table-column'] ||
        (localStorage['kondukto-table-column'] &&
        !JSON.parse(localStorage['kondukto-table-column'])[data])
      ) {
        return;
      }
      this.vulnsTableColumns = JSON.parse(localStorage['kondukto-table-column'])[data];
    },

    // ## Save vuln table columns. -----------------------------------------------------------------
    saveVulnsTableColumns(payload) {
      const savedColumn = (localStorage['kondukto-table-column'] ?
        JSON.parse(localStorage['kondukto-table-column']) : null);
      const data = {
        ...savedColumn,
        [payload.name]: payload.data,
      };
      localStorage.setItem('kondukto-table-column', JSON.stringify(data));
    },

    showVulnDetail(data) {
      this.vulnDetailId = data.id;
      this.vulnDetailVisible = data.status;
    },

    closeVulnDetail() {
      this.vulnDetailId = null;
      this.vulnDetailVisible = false;
    },

    selectVulnRows(data) {
      if (this.selectedVulnRows.filter((s) => s.id === data.vuln.id).length > 0 && data.type !== 'all') {
        this.selectedVulnRows = this.selectedVulnRows.filter((s) => s.id !== data.vuln.id);
      } else {
        this.selectedVulnRows.push(data.vuln);
      }
    },

    resetSelectVulnRows() {
      this.selectedVulnRows = [];
    },

    // ## Get vuln recommendation details. -----------------------------------------------------------
    async getVulnRecommendationDetails(data) {
      this.vulnRecommendationDetailsPending = true;

      try {
        const res = await VulnService.getVulnRecommendationDetails(data);
        this.vulnRecommendationDetails = res.data.data;
        this.vulnRecommendationDetailsError = null;
        this.vulnRecommendationDetailsDone = !this.vulnRecommendationDetailsDone;
        this.vulnRecommendationDetailsPending = false;
      } catch (error) {
        this.vulnRecommendationDetailsPending = false;
        this.vulnRecommendationDetails = null;
        this.vulnRecommendationDetailsError = ErrorHandling(error);
      }
    },

    // ## Get vuln collaboration. ------------------------------------------------------------------
    async getVulnCollaboration(data) {
      this.vulnCollaborationPending = true;
      this.vulnCollaboration = vulnCollaborationDummy;

      try {
        const res = await VulnService.getVulnCollaboration(data);
        this.setGetVulnCollaboration(res.data.data, data);
        this.vulnCollaborationDone = !this.vulnCollaborationDone;
        this.vulnCollaborationPending = false;
      } catch (error) {
        this.vulnCollaborationPending = false;
        this.vulnCollaborationFail = !this.vulnCollaborationFail;
        this.vulnCollaborationError = ErrorHandling(error);
      }
    },

    setGetVulnCollaboration(payload, data) {
      let result = [];
      this.vulnCollaboration = [];
      if (data.type === 'issue' && data.search === 'cwe') {
        result = payload.issue_collaborations.issue_collaboration_by_cwe;
      } else if (data.type === 'issue' && data.search === 'cve') {
        result = payload.issue_collaborations.issue_collaborations_by_cve;
      } else if (data.type === 'vulnerability' && data.search === 'cwe') {
        result = payload.closed_vulnerability_collaborations.closed_vulnerability_collaborations_by_cwe;
      } else if (data.type === 'vulnerability' && data.search === 'cve') {
        result = payload.closed_vulnerability_collaborations.closed_vulnerability_collaborations_by_cve;
      }
      this.vulnCollaboration = result.map((s) => ({
        assignee: s.responsible,
        firstSeen: s.first_seen ? dayjs(s.first_seen).format('DD MMM YYYY') : '-',
        lastSeen: s.last_seen ? dayjs(s.last_seen).format('DD MMM YYYY') : '-',
        issueLink: s.issue_link,
      }));
    },

    // ## Get vuln issue manager info. ------------------------------------------------------------------
    async getVulnManagerIssueInfo(data) {
      this.vulnIssueManagerInfoPending = true;

      try {
        const res = await VulnService.getVulnManagerIssueInfo(data);
        this.vulnIssueManagerInfo = res.data;
        this.vulnIssueManagerInfoDone = !this.vulnCollaborationDone;
        this.vulnIssueManagerInfoPending = false;
      } catch (error) {
        this.vulnIssueManagerInfoPending = false;
        this.vulnIssueManagerInfoFail = !this.vulnIssueManagerInfoFail;
        this.vulnIssueManagerInfoFailMsg = ErrorHandling(error);
      }
    },
  },
});

export default useVulnsStore;
